import React, { Fragment, useState, useEffect } from 'react';
import Menu from './menu';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import WhatsappButton from './WhatsappButton';
import emailjs from 'emailjs-com';
const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
      });
      useEffect(() => {
        document.title = 'Contact Us | Zerobug Academy';
        return () => {
            document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy';
        };
    }, []);
  
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy'  style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy'  className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>

                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                               </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" style={{ color: "darkorange" }} >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}

            {/*     <!-- Header Start --> */}
            <div className="container-fluid  page-contact " >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center " style={{ minHeight: "300px" }}>
                        <h1 className="display-4 text-white text-uppercase">Contact Us</h1>

                    </div>
                </div>
            </div>
            {/*  <!-- Header End --> */}
            <WhatsappButton />

            <div className='container'>

                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className=" ">
                        <div className='container-fluid' id='course-content'>
                            <div class=" features-courseProject">
                                <div class="row" >
                                    <div class="col-lg-9">
                                        <h3 class="float-left" style={{ textAlign: "center", marginBottom: "30px" }}>
                                            Conact Info</h3>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 mb-4">
                                                <div className=" position-relative overflow-hidden  ">

                                                    <div className='proj-portal-content1 '>
                                                        <div className='key-images'>
                                                            <i className='fa fa-phone' style={{ fontSize: "30px", backgroundColor: '#FF8C00', borderRadius: "50%", padding: '15px', color: 'black' }}></i>{/* <h4>Phone</h4> */}
                                                        </div>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <p>+91-9750061584 / 9791040581 / 9524222501</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-4">
                                                <div className=" position-relative overflow-hidden  ">

                                                    <div className='proj-portal-content1 '>
                                                        <div className='key-images'>
                                                            <i className='fa fa-envelope' style={{ fontSize: "30px", backgroundColor: '#FF8C00', borderRadius: "50%", padding: '15px', color: 'black' }}></i>{/* <h4>Phone</h4> */}
                                                        </div>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <Link to=''>zerobugacademy.info@gmail.com</Link>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-4">
                                                <div className=" position-relative overflow-hidden  ">

                                                    <div className='proj-portal-content1 '>
                                                        <div className='key-images'>
                                                            <i className='fa fa-home' style={{ fontSize: "30px", backgroundColor: '#FF8C00', borderRadius: "50%", padding: '15px', color: 'black' }}></i>{/* <h4>Phone</h4> */}
                                                        </div>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <Link to='https://www.zerobugacademy.com/'>www.zerobugacademy.com</Link>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 mb-4">
                                                <div className=" position-relative overflow-hidden  ">

                                                    <div className='proj-portal-content1 '>
                                                        <div className='key-images'>
                                                            <i className='fa  fa-map-marker-alt' style={{ fontSize: "30px", backgroundColor: '#FF8C00', borderRadius: "50%", padding: '15px', color: 'black' }}></i>{/* <h4>Phone</h4> */}
                                                        </div>
                                                        <div style={{ marginTop: "10px" }}>
                                                            <p>Zerobug Academy, No-218, Velachery Main Rd, Selvam Nagar, Pallikaranai, Chennai, Tamil Nadu 600100.</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">

                            <div className="col-lg-8">

                                <div className="mb-5">
                                    <iframe style={{ width: "100%", height: "500px", border: "0" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5899491313517!2d80.20106867422095!3d12.934055715702003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d9ccff0fb1d%3A0xa81b2da4f464ddc4!2sPython%20Django%20Training%20Institute!5e0!3m2!1sen!2sin!4v1707597412329!5m2!1sen!2sin" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

                                </div>
                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">REQUEST A CALL BACK</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>

                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                            <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- Detail End --> */}

            </div>
            <Footer />
        </Fragment>
    )
}

export default Contact;