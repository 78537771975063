// TestimonialSlider.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './TestimonialSlider.css';

const TestimonialSlider = () => {
  const testimonials = [
    {
      id: 1,
      /* text: '',
      author: '', */
      image: 'assets/reviews/Screenshot (93).png',
    },
    {
      id: 2,
   /*    text: '',
      author: '', */
      image: 'assets/reviews/Screenshot (94).png',
      
    },
    {
      id: 3,
      image:'assets/reviews/Screenshot (95).png'
    },
    {
      id: 4,
      image:'assets/reviews/Screenshot (96).png'
    },
    {
      id: 5,
      image:'assets/reviews/Screenshot (97).png'
    },
    {
      id: 6,
      image:'assets/reviews/Screenshot (98).png'
    },
    {
      id: 7,
      image:'assets/reviews/Screenshot (99).png'
    },
  
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth > 992 ? 2 : 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-slider-container">
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-item">
            <img src={testimonial.image} alt={`Testimonial ${testimonial.id}`} className="testimonial-image" loading="lazy"/>
            
           {/*  <p>{testimonial.text}</p>
            <p className="author">{testimonial.author}</p> */}
              
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
