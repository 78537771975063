import React, { useState, useEffect } from 'react';
import './CountIncreaseDesign.css';

const CountIncreaseDesign = () => {
  const [hoursCount, setHoursCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hoursCount < 94) {
        setHoursCount(hoursCount + 1);
      }
      if (projectsCount < 12) {
        setProjectsCount(projectsCount + 1);
      }
      if (usersCount < 100) {
        setUsersCount(usersCount + 1);
      }
      if (cartCount < 32) {
        setCartCount(cartCount + 1);
      }
    }, 300); // Increase counts every 100 milliseconds

    return () => clearInterval(interval);
  }, [hoursCount, projectsCount, usersCount, cartCount]); // Dependency array includes state variables

  return (
    <div className="count-increase-design">
      <div className="icon-wrapper">
        <img src='./assets/banners/service-icon-01.png' alt='IT training'/>
        <h6 className='new-count'>Succeeded Students</h6>
        <span className='count1'>{hoursCount}%</span>
      </div>
      <div className="icon-wrapper">
        <img src='./assets/banners/service-icon-02.png' alt='IT training'/>
        <h6 className='new-count'>Our Teachers</h6>
        <span className='count1'>{projectsCount}</span>
      </div>
      <div className="icon-wrapper">
        <img src='./assets/banners/service-icon-03.png' alt='IT training'/>
        <h6  className='new-count'>New Students</h6>
        <span className='count1'>{usersCount}+</span>
      </div>
      <div className="icon-wrapper">
        <img src='./assets/banners/service-icon-01.png' alt='IT training'/>
        <h6 className='new-count'>Awards Count</h6>
        <span className='count1'>{cartCount}</span>
      </div>
    </div>
  );
};

export default CountIncreaseDesign;
