import React, { Fragment, useEffect } from 'react'
import Menu from './menu';
import TawkTo from './TawkTo';
import { Link } from 'react-router-dom';

import PopupForm from './PopupForm'
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';


const Python = () => {
    useEffect(() => {
        document.title = 'Blog | Zerobug Academy';
        return () => {
          document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy'; 
        };
      }, []);
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse" style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy'  style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy'  className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>

                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" style={{ color: "darkorange" }}>Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}

            {/*     <!-- Header Start --> */}
            <div className="container-fluid  page-blog"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "300px" }}>
                        <h3 className="display-4 text-white text-uppercase">Blog</h3>
                       
                    </div>
                </div>
            </div>
            {/*  <!-- Header End --> */}
            {/* courseNav */}
          <WhatsappButton/>
          <PopupForm />
          <TawkTo/>
            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">
                    <div className="container  ">
                        <div className="row">

                            <div class="col-lg-12 instruc-wrap1" id="OurInstructors">

                                <div class="mb-5">

                                    <div class="media mb-4">
                                     
                                        <div class="media-body">
                                            <h1 className='heading-left  h111'>Top 10 Highest Paying Jobs in 2024</h1>
                                            <p >introduction As the industries are evolving dynamically,
                                                Professionals in various roles play vital parts in driving innovation, growth, efficiency, and security. These professionals gain the highest payrolls that offer rewarding career paths. It is important to acquire specialized skills for …</p>
                                            <Link to='/top-paying-jobs-2024' class="btn  btn-secondary-read">Read More</Link>
                                        </div>
                                    </div>
                                    <div class="media mb-4">
                                       
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>Elevating Your Career: The Impact of AWS Certification Training in 2024</h4>
                                            <p>Introduction To begin,<strong>AWS</strong> is the most in-demand skill and a leading cloud service provider in the IT industry. It offers a range of certifications for different roles and skill levels.
                                                Pursuing AWS Certification enhances your credibility and employability across …</p>
                                            <Link to='/elevate-aws-certification-training-in-chennai' class="btn  btn-secondary-read">Read More</Link>

                                        </div>
                                    </div>

                                    <div class="media mb-4">
                                   
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>Career Upgrade 2024: Elevate Your Path with AWS Solutions Architect</h4>
                                            <p>introduction To start, <strong>Amazon Web Services </strong> is the leading cloud computing platform that provides crucial job roles. Cloud technology evolves rapidly with the latest AWS services, features. AWS Solutions Architect is the rewarding job role which leads to an elevated …</p>
                                            <Link to="/aws-certification-training-in-chennai" class="btn btn-secondary-read">Read More</Link>

                                        </div>
                                    </div>
                                    <div class="media mb-4">
                                       
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>Java 9 Features</h4>
                                            <p>Java 9 is the most anticipated and is a noteworthy component arrival of <strong>Java programming</strong> dialect. This is an initial instructional exercise that discloses the fundamental to-cutting edge highlights of Java 9 and their utilization in a basic and instinctive way.</p>
                                            <Link to='/java-certification-training-impact' class="btn btn-secondary-read">Read More</Link>

                                        </div>
                                    </div>
                                    
                                      <div class="media mt-4">
                                    
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>N Number of Significant Ways To Optimize Your Google Adwords Account</h4>
                                            <p>N Number of Significant Ways to Optimize your <strong>Google Adwords</strong> Account Online Marketing is all about generating traffic to your website. You may create an amazing website and run many A/B testing experiments all day long, but still, your site is no use without visitors/traffic. 
                                                One of the successful ways to drive visitors to your site can be done through Google Adwords.</p>
                                            <Link  to='/google-adwords-certification-training-impact' class="btn  btn-secondary-read ">Read More</Link>
                                        </div>
                                    </div>
                                    <div class="media mb-4">
                                        
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>6 Reasons Why Web Designing Is Not A Dying Profession</h4>
                                            <p>Reasons why Web Designing is not a Dying Profession <strong>Web Design</strong> has a tremendous enhancement in the past two years. Designers continue to work to be relevant in this world. Nowadays you would've seen ads running on paid website builders.
                                                With all these web design is considered to be a brink of extinction.<strong>Web Designing Course in Pallikkaranai,Chennai.</strong></p>
                                            <Link to='/webdesign-certification-training-impact' class="btn btn-secondary-read">Read More</Link>

                                        </div>
                                    </div>
                                    <div class="media mt-4">
                                       
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>PHP 7.0 Updates</h4>
                                            <p> <strong>PHP 7.0 Update’sIntroduction to PHP The current version of PHP is PHP 7.0.</strong> PHP was created in 1994. PHP 7.0 was released in 2015. PHP is used to script the server-side. PHP stands for Hypertext Preprocessor. PHP is used to create dynamic websites.
                                                Before you learn PHP you should have basic knowledge of <strong>HTML, CSS, and JavaScript</strong>. </p>
                                            <Link to='/php-certification-training-impact' class="btn btn-secondary-read ">Read More</Link>
                                        </div>
                                    </div>
                                    <div class="media mt-4">
                                      
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>Cloud Computing Trends To Be Prepared In The Year 2024 </h4>
                                            <p>As we are about to enter into the year 2024 , IT executives are looking towards the attention of the use of technology to attain their business goals. <strong>Cloud Computing Training in Pallikkaranai-Chennai</strong> has proved to be the best trending technology of all time. Cloud has proved significant changes to companies in 2017. Here in this blog, we have mentioned <strong>Cloud Computing</strong> Trends to be prepared in the year 2017.
                                                Cloud has provided a way to retrieve and store an amount of information.</p>
                                            <Link to='/cloud-computing-certification-training-impact' class="btn btn-secondary-read ">Read More</Link>
                                        </div>
                                    </div>
                                    <div class="media mt-4">
                                     
                                        <div class="media-body">
                                            <h4 className='heading-left  h111'>Latest 7 Code And Framework To Be Followed In 2024 </h4>
                                            <p>Latest 6 Code and framework to be followed in 2018 is going to be the biggest year for developers for creating the landmark in many programming ecosystems. The entry of<strong> AngularJS 2 moved Node.js</strong> towards neutrality. Many developers felt JavaScript as fatigue, but others considered fatigued complaints as fatigue.
                                                 Due to modularity controversy, <strong>Java 9 version</strong> was delayed. But</p>
                                            <Link to='/latest-frameworks-certification-training-impact' class="btn btn-secondary-read ">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <Footer />
        </Fragment>
    )
}

export default Python;