// src/components/WhatsAppButton.js
import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';


const WhatsappButton = () => {
  return (
    <div>
      {/* Your other React components/content here */}
      <FloatingWhatsApp
        phoneNumber='+918946004709'
        accountName='ZeroBug Academy'
        avatar='../assets/img/logo (21).png'
        placeholder='Type a message..'
     
     
        darkMode={false} // Set to true for dark mode
      />
    </div>
  );
};

export default WhatsappButton;
