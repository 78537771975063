import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <Fragment>
       
   {/* <!-- Footer Start --> */}
    <div className="container-fluid  text-white py-5 px-sm-3 px-lg-5" style={{background:"#101046",marginTop:'30px'}}>
        <div className="row pt-5">
            <div className="col-lg-7 col-md-12">
                <div className="row">
                    <div className="col-md-6 mb-5 footer-parag"  >
                        <h5 className="text-primary text-uppercase mb-4" style={{letterSpacing: "5px"}}>Get In Touch</h5>
                        <p><i className="fa fa-map-marker-alt mr-2"></i>Zerobug Academy, No-218, Velachery Main Rd, Selvam Nagar, Pallikaranai, Chennai, Tamil Nadu 600100</p>
                        <p><i className="fa fa-phone-alt mr-2"></i>+91-8946004709,9750061584</p>
                        <p><i className="fa fa-envelope mr-2"></i>zerobugacademy.info@gmail.com</p>
                        <div className="d-flex justify-content-start mt-4">
                           <Link  className="btn btn-outline-light btn-square mr-2" to='https://twitter.com/Zerobug_Academy' ><i className="fab fa-twitter"></i> </Link>
                           <Link  className="btn btn-outline-light btn-square mr-2" to='https://www.facebook.com/zerobugacademytraininginstitute/' ><i className="fab fa-facebook-f"></i> </Link>
                           <Link  className="btn btn-outline-light btn-square mr-2" to='https://www.linkedin.com/company/zerobug-academy/about/' ><i className="fab fa-linkedin-in"></i> </Link>
                           <Link  className="btn btn-outline-light btn-square" to='https://www.instagram.com/zerobug_academy?igsh=Y2dpcXZ2Zm8zMm83' ><i className="fab fa-instagram"></i> </Link>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5 footer-parag">
                        <h5 className="text-primary text-uppercase footer-list mb-4" style={{letterSpacing: "5px"}}>Our Courses</h5>
                        <div className="d-flex flex-column justify-content-start" style={{textAlign:'center'}}>
                            <Link className="text-white footer-list mb-2" to='/best-python-training-in-chennai' ><i className="fa fa-angle-right mr-2"></i>Python Training</Link>
                            <Link  className="text-white footer-list  mb-2" to='/best-selenium-testing-training-in-chennai' ><i className="fa fa-angle-right mr-2"></i>Selenium Testing</Link>
                            <Link  className="text-white footer-list mb-2" to='/best-angular-training-in-chennai' ><i className="fa fa-angle-right mr-2"></i>Angular Training</Link>
                           <Link  className="text-white footer-list mb-2" to='/best-react-training-in-chennai' ><i className="fa fa-angle-right mr-2"></i>React Training </Link>
                        
                          <Link  className="text-white footer-list mb-2" to='/web-development-training-in-chennai' ><i className="fa fa-angle-right mr-2"></i>Web Application</Link>
                          <Link  className="text-white footer-list  mb-2" to='/best-django-training-in-chennai '><i className="fa fa-angle-right mr-2"></i>Django Training</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-5 col-md-12 mb-5 footer-parag">
            <h5 className="text-primary text-uppercase footer-list mb-4" style={{letterSpacing: "5px"}}>Quick Link</h5>
            <div className="d-flex flex-column justify-content-start" style={{textAlign:'center'}}>
                            <Link className="text-white footer-list mb-2" to='/ ' ><i className="fa fa-angle-right mr-2"></i>Home</Link>
                           <Link  className="text-white footer-list mb-2" to='/about' ><i className="fa fa-angle-right mr-2"></i>About Us</Link>
                           
                           <Link  className="text-white footer-list mb-2" to='/best-seo-marketing-training-in-chennai' ><i className="fa fa-angle-right mr-2"></i>SEO</Link>
                           <Link  className="text-white footer-list mb-2" to='/blog' ><i className="fa fa-angle-right mr-2"></i>Blog </Link>
                           <Link  className="text-white footer-list  mb-2" to='/contact' ><i className="fa fa-angle-right mr-2"></i>Contact Us</Link>
                         
                        </div>
             
            </div>
        </div>
        <div className='container-fluid   footer-content-pragraph'>
            <h4 style={{textAlign:"justify"}}  className='text-primary '>Are You Located In Any Of These Areas</h4>
            <p>Adyar, Adambakkam, Anna Salai, Ambattur, Ashok Nagar, Aminjikarai, Anna Nagar, Besant Nagar, Chromepet, Choolaimedu, Guindy, Egmore, K.K. Nagar, Kodambakkam, Ekkattuthangal, Kilpauk, Medavakkam, Nandanam, Nungambakkam, Madipakkam, Teynampet, Nanganallur, Mylapore, Pallavaram, OMR, Porur, Pallikaranai, Saidapet, St.Thomas Mount, Perungudi, T.Nagar, Sholinganallur, Triplicane, Thoraipakkam, Tambaram, Vadapalani, Villivakkam, Thiruvanmiyur, West Mambalam, Velachery and Virugambakkam.</p>
        </div>
        <div className='container-fluid   footer-content-pragraph '>
            <h4 style={{textAlign:"justify"}}  className='text-primary '>Zerobug Academy Best Training Courses</h4>
            <p>BEST HADOOP BIG DATA TRAINING INSTITTUTE IN CHENNAI, BEST PHP TRAINING INSTITUTE IN CHENNAI, BEST ADVANCE JAVA TRAINING INSTITUTE IN CHENNAI, BEST DOT NET TRAINING INSTITUTE IN CHENNAI, BEST DIGITAL MARKETING TRAINING INSTITUTE IN CHENNAI, BEST ANGUILAR JS TRAINING CHENNAI , BEST PHYTON TRAINING INSTITUTE IN CHENNAI, BEST AWS TRAINING INSTITUTE IN CHENNAI, Manual Testing, Selenium Testing, L2-L3 Protocol Testing, ETL Testing Training, HTML Training, CSS Training, Angular JS Training IN CHENNAI /// </p>
        </div>
    </div>
    <div className="container-fluid  text-white border-top py-4 px-sm-3 px-md-5" style={{borderColor: "rgba(256, 256, 256, .1) !important", background:'#1C2955'}}>
        <div className="row">
            <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
                <p className="m-0 text-white">&copy; <Link  to='/' >ZerobugAcademy</Link>. All Rights Reserved . Designed by <Link  to="https://kumbhatech.com/"> Kumbha Technology  </Link>
                </p>
            </div>
           
        </div>
    </div>
  {/*   <!-- Footer End --> */}


    </Fragment>
  );
};

export default Footer;