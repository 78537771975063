
import React, { useState, useEffect } from 'react';
import './Slider.css'; // Import your styles if needed
import { Link } from 'react-router-dom';


function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 6);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 6) % 6);
  };

  const updateSlider = () => {
    const sliderContent = document.querySelector(".slider-content");
    const cardWidth = document.querySelector(".review-card").offsetWidth;
    sliderContent.style.transform = `translateX(${-currentIndex * cardWidth}px)`;
  };

  useEffect(() => {
    updateSlider();
  }, [currentIndex]);

  return (
    <div className="slider-section">
      <button className="prev" onClick={prevSlide}>
        ❮
      </button>
      <div className="slider-container">
        <div className="slider-content">
        
          <div class="review-card slide-bg">
            <div className="">
              <div className="profile-image">
                <img
                  src="assets/img/icons8-python-48.png"
                  alt="Python course"
                  className='slider-images'
                />
              </div>
              
            </div>
            <div className='prop'>
            <h5 className="name-cus">Python Training</h5>
            <p  className=" slier-pragraph">
            Code Your Future! </p>
            </div>
            
            <Link to='/best-python-training-in-chennai' className='slider-button'>View Details</Link>
          </div>
         
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image">
                <img
                  src="assets/img/react.png"
                  alt="React course"
                  className='slider-images'
                />
              </div>
             
            </div>
            <div className="prop">
            <h5 className="name-cus">React Training</h5>
            <p  className=" slier-pragraph">
            Elevate Your Tech Game!
            </p>
            </div>
            <Link to='/best-react-training-in-chennai'  className='slider-button'>View Details</Link>
          </div>
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image">
                <img
                  src="assets/img/Angular.icon.png"
                  alt="Angular"
                  className='slider-images'
                />
              </div>
             
            </div>
            <div className="prop">
            <h5 className="name-cus">Angular Training</h5>
            <p  className=" slier-pragraph">
            Guarding the Digital Frontier!
            </p>
            </div>
            <Link to='/best-angular-training-in-chennai' className='slider-button'>View Details</Link>
          </div>
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image">
                <img
                  src="assets/img/djngo.png"
                  alt="Django course"
                  className='slider-images'
                />
              </div>
             
            </div>
            <div className="prop">
            <h5 class="name-cus">Django Training</h5>
            <p  className=" slier-pragraph">
            Uncover Insights, Drive Decisions
            </p>
            </div>
            
            <Link to="/best-django-training-in-chennai" className='slider-button'>View Details</Link>
          </div>
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image">
                <img
                  src="assets/img/Digital-Marketing-PNG.png"
                  alt="digital marketing"
                  className='slider-images'
                />
              </div>
              
            </div>
            <div className="prop">
            <h5 class="name-cus">Digital Marketing</h5>
           
            <p  className=" slier-pragraph">
            Navigate the Online Landscape with Finesse!
            </p>
            </div>
            <Link to="/best-seo-marketing-training-in-chennai" className='slider-button'>View Details</Link>
          </div>
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image">
                <img
                  src="assets/banners/selenium.jpg"
                  alt="selenium testing "
                 className='slider-images'
                />
              </div>
             
            </div>
            <div className="prop">
            <h5 class="name-cus">Selenium Testing</h5>
            <p  className=" slier-pragraph">
            Develop yourself as an expert automation. 
              
            </p>
            </div>
            <Link to="/best-selenium-testing-training-in-chennai" className='slider-button'>View Details</Link>
          </div>
         
         
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image">
                <img
                  src="assets/img/icons8-python-48.png"
                  alt="Python course"
                  className='slider-images'
                />
              </div>
              
            </div>
            <div className="prop"> <h5 class="name-cus">Python Training</h5>
            <p className=" slier-pragraph" >
            Code Your Future! </p>
            </div>
            <Link to='/best-python-training-in-chennai' className='slider-button'>View Details</Link>
          </div>
         
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image ">
                <img
                  src="assets/img/react.png"
                  alt="React course"
                  className='slider-images'
                />
              </div>
              
            </div>
            <div className="prop">
            <h5 className="name-cus">React Training</h5>
            <p className=" slier-pragraph">
            Elevate Your Tech Game!
            </p>
            </div>
           
            <Link to='/best-react-training-in-chennai'  className='slider-button'>View Details</Link>
          </div>
          <div className="review-card slide-bg">
            <div >
              <div className="profile-image">
                <img
                  src="assets/img/Angular.icon.png"
                  alt="Angular course"
                  className='slider-images'
                />
              </div>
              <div className="heading-image">
                <div className="heading">
                 
                </div>
               
              </div>
            </div>
            <div className="prop">
            <h5 class="name-cus">Angular Training</h5>
           
            <p className=" slier-pragraph">
            Guarding the Digital Frontier!
            </p>
            </div>
            <Link to='/best-angular-training-in-chennai' className='slider-button'>View Details</Link>
          </div> 
        
         
        </div>
      </div>
      <button className="next" onClick={nextSlide}>
        ❯
      </button>
    </div>
  );
}

export default Slider;
