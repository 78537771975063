import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import Menu from './menu';
import Carousel from './CarouselComponent';
import Slider from './Slider';
import WhatsAppButton from './WhatsappButton';
import Footer from './Footer';
import PopupForm from './PopupForm';
import TestimonialSlider from './TestimonialSlider';
import TawkTo from './TawkTo';
import emailjs from 'emailjs-com';
import HomeList from './homeList';
import CountIncreaseDesign from './CountIncreaseDesign';
const Home = () => {

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }

        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name.');
            return;
        }

        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }

        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
        // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    const navRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (navRef.current) {
                if (window.scrollY > navRef.current.offsetTop) {
                    navRef.current.classList.add('sticky'); // Add 'sticky' class when scrolled
                } else {
                    navRef.current.classList.remove('sticky'); // Remove 'sticky' class when not scrolled
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <Fragment>
            <Menu />
            <div className="container-fluid">
                <div className="row border-top  px-xl-5 " style={{ padding: "15px 0" }} ref={navRef}>
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse" style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >
                        <nav >
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" className='activex'>Home</Link></li>

                                <li>
                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                        <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>
                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                        <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

            {/* /*  <!-- Carousel Start -->*/}
            <div className="container-fluid p-0  ">
                <Carousel />
            </div>
            <div className="container-fluid  p-0 mb-5 ">
                <div class="container ">
                    <div className="partner-slider">
                        <div className="partner-slide">
                            <img src="./assets/banners/DHL.png" alt="Partner 1"loading="lazy" />
                        </div>
                        <div className="partner-slide">
                            <img src="./assets/banners/HCLTECH.NS.png" alt="Our Student Placed in MNC" loading="lazy" />
                        </div>
                        <div className="partner-slide">
                            <img src="./assets/banners/INFY_BIG.png" alt="Our Student Placed in MNC" loading="lazy" />
                        </div>
                        <div className="partner-slide">
                            <img src="./assets/banners/zoho-1-logo-png-transparent.png" loading="lazy" alt="Our Student Placed in MNC" />
                        </div>
                        <div className="partner-slide">
                            <img src="./assets/banners/tvs-credit.png" alt="Our Student Placed in MNC"loading="lazy" />
                        </div>
                        <div className="partner-slide">
                            <img src="./assets/banners/larsen-and-toubro.jpg" alt="Our Student Placed in MNC" loading="lazy"/>
                        </div>

                        <div className="partner-slide">
                            <img src="./assets/banners/TCS.NS.png" alt="Our Student Placed in MNC" loading="lazy" />
                        </div>
                        <div className="partner-slide">
                            <img src="./assets/banners/DHL.png" alt="Our Student Placed in MNC"loading="lazy" />
                        </div>
                        <div className="partner-slide">
                            <img src="./assets/banners/HCLTECH.NS.png" alt="Our Student Placed in MNC" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid home-page-bg p-0 '>
                <div className="about-section py-5">
                    <div className="about-image">
                        <img src="./assets/banners/HomeBannerImages.png" alt="Best IT Training Institute in Chennai" loading="lazy" />
                        <figcaption className='visually-hidden '>
                            <a href="https://www.freepik.com/free-photo/photo-surprised-european-woman-points-with-index-finger-upwards-being-good-mood_10582863.htm#page=3&position=0&from_view=author&uuid=bb9d218c-109a-4ae8-b508-395ba4aceb2f">Image by wayhomestudio on Freepik</a>
                        </figcaption>
                    </div>
                    <div className="about-content  ">
                        <h1 className=" text-uppercase h2 mb-3">Best IT Training Institute in Chennai</h1>
                        <h5 className="font-weight-bold">Welcome to Zerobug Academy-Your Launchpad to Tech Excellence in Chennai!</h5>
                        <h6 className="font-weight-bold">Unlock the World of Possibilities with Zerobug Academy!</h6>
                        <p className="mb-4 justify-text ">Are you seeking a rewarding career in the ever-evolving world of technology? Look no further than Zerobug Academy, the <strong> premier software training institute in Chennai.</strong> We offer a diverse range of industry-relevant courses, from <strong>Java and Dot Net to AWS, Selenium Testing, and Digital Marketing,</strong> empowering you with the skills and knowledge to thrive in today's competitive job market. At Zerobug Academy, we understand that every learner has unique needs and aspirations. Whether you're a recent graduate seeking employment, a professional looking for a career change, or simply wanting to enhance your skillset, we have a program designed to help you achieve your goals.</p>
                    </div>

                </div>
            </div>




            <div className="col-lg-12 instruc-wrap1 py-5 " id="OurInstructors1" >
                <div className=" cnt marginTop-20 " >
                    <h2>Bridging the Gap Between Education and Industry</h2>
                    < hr className='bottom-border' style={{ marginBottom: "10px" }} />
                    <p className='justify-text'>
                        We are committed to providing exceptional training that transcends traditional academic settings. Our team of seasoned IT professionals meticulously crafts and updates our curriculum to align with the latest industry demands. Forget rote learning and theoretical concepts – our interactive and practice-oriented approach emphasizes real-world scenarios and practical knowledge, ensuring you graduate job-ready with the confidence to tackle any challenge.
                    </p>
                </div>
            </div>

            <div className="container-fluid ">
                <div className="container  pb-3">

                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="cat-item position-relative overflow-hidden  couse-title">
                                <h4 className='satisfy'>40000 <i class='bx bx-plus'><i className='bx  bxs-graduation'></i></i> </h4>
                                <h6 className='font-weight-bold'>SATISFIED LEARNERS</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="cat-item position-relative overflow-hidden   couse-title ">
                                <h4 className='satisfy' >40000 <i class='bx bx-plus'><i className='bx bx-desktop'></i></i></h4>
                                <h6 className='font-weight-bold'>REALTIME PROJECTS </h6>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 ">
                            <div className="cat-item position-relative overflow-hidden  couse-title">
                                <h4 className='satisfy'> 400 <i class='bx bx-plus'> <i className='bx bxs-book-add'></i></i></h4>
                                <h6 className='font-weight-bold' >COURSES </h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="cat-item position-relative overflow-hidden   couse-title">
                                <h4 className='satisfy'> 100 <i className='bx bx-plus'> <i class='bx bxs-universal-access'></i></i></h4>
                                <h6 className='font-weight-bold'>CERTIFIED TRAINERS </h6>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <WhatsAppButton />
            <PopupForm />
            <TawkTo />
            {/*next gategory  */}
            <div className='container-fluid '>
                <div class=" features-courseProject">
                    <div class="row">

                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-6">
                                    <div class="proj-portal1">
                                        <div class="proj-portal-heading">
                                            <h5 className='font-weight-bold'>CARRER TRANSITION</h5>	 </div>
                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="proj-portal1">
                                        <div class="proj-portal-heading">
                                            <h5 className='font-weight-bold'>NON-IT TO IT </h5>	 </div>
                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="proj-portal1">
                                        <div class="proj-portal-heading">
                                            <h5 className='font-weight-bold'>PLACEMENT GUARANTEE</h5>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*slidercard*/}
            <div className="container-fluid margin-60 ">
                <div className="text-center mb-5">

                    <h2 >Our Top Courses</h2>
                    < hr className='bottom-border' />
                    <p className="padding">Ready to elevate your skills? Check out our top courses that are making waves</p>
                    <hr />
                    <Slider />
                    <hr />
                    <p>
                        Join Zerobug Academy - Where Learning Meets Excellence. Your journey to success begins here!
                    </p>
                    <br />
                </div>

            </div>
            {/*  <!-- Category Start --> */}

            <div class='container-fluid home-need-bg p-0'>
                <div class="new-section py-5">
                    <div class="new-image">
                        <img decoding='async' src='./assets/banners/Home-course-image.png' alt='Zerobug academy' loading='lazy' />
                        <figcaption className='visually-hidden'><a href="https://www.freepik.com/free-photo/portrait-cheerful-young-african-man_7380559.htm#fromView=search&page=2&position=41&uuid=99f26018-52be-4ca2-998d-0fb0cc8e79a1">Image by drobotdean on Freepik</a></figcaption>
                    </div>
                    <div class="new-content text-left">
                        <h2 >Welcome to Zerobug Academy - Where Excellence Meets Innovation!</h2>
                        <p class="justify-text">Zerobug Academy, the <strong>leading IT training institute in Velachery, Chennai,</strong>  where excellence meets innovation. Our institute is synonymous with quality education, practical learning, and career success. What sets us apart is our unwavering commitment to providing top-notch training that prepares our students for the dynamic IT landscape.</p>
                        <p class="justify-text">At Zerobug Academy, we offer a comprehensive range of courses that cover the latest technologies and industry best practices. Whether you're a beginner looking to start your IT journey or a seasoned professional aiming to upgrade your skills, our expert trainers and well-structured curriculum ensure that you receive the highest quality education.</p>
                        <div>
                            <CountIncreaseDesign />
                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Registration End --> */}

            <div className="container-fluid margin-60  margin-mainus-30">
                <div className="container pt-5 ">
                    <div className="text-center ">

                        <h2>Why ZeroBug?</h2>
                        < hr className='bottom-border' />
                        <br />
                        <p>At <strong>Zerobug Academy</strong>, we don't just teach, we transform careers! Here's why you should make us your learning partner</p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section ">
                            <div className=" position-relative overflow-hidden ">
                                <img src="assets/img/realtime-copy-4.jpg" alt='best Software Training' loading="lazy"/>
                                <h6 className='bolder Why-zerobug-section-mg' >Industry-Leading Instructors</h6>
                                <p>Learn from seasoned professionals with hands-on experience in the industry, ensuring you get real-world insights and knowledge.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section">
                            <div className=" position-relative overflow-hidden   ">
                                <img src='assets/img/clockflat.jpg' alt='best Software Training' loading="lazy"/>
                                <h6 className='bolder Why-zerobug-section-mg'>Cutting-Edge Curriculum</h6>
                                <p>Our courses are crafted to keep you ahead in the dynamic tech landscape.
                                    Stay relevant with the latest tools, technologies, and industry trends.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section">
                            <div className=" position-relative overflow-hidden ">
                                <img src='assets/img/realtime.jpg' alt='best Software Training' loading="lazy"/>
                                <h6 className='bolder Why-zerobug-section-mg'>Hands-On Learning </h6>
                                <p>Theory is good, but practice makes perfect! Get your hands dirty with practical, project-based learning that sets you up for success.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section">
                            <div className=" position-relative overflow-hidden   ">
                                <img src='assets/img/realtime-copy.jpg' alt='best Software Training' loading="lazy"/>
                                <h6 className='bolder Why-zerobug-section-mg'>Certification for Excellence </h6>
                                <p>Earn certifications recognized by industry leaders. Boost your resume and stand out in a competitive job market.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section">
                            <div className="position-relative overflow-hidden   ">
                                <img src='assets/img/lab.jpg' alt='best Software Training' loading="lazy"/>
                                <h6 className='bolder Why-zerobug-section-mg'>State-of-the-Art Labs </h6>
                                <p>Immerse yourself in our high-tech labs designed for an interactive and immersive learning experience. Your journey is as important as your destination!.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section">
                            <div className=" position-relative overflow-hidden   ">
                                <img src='assets/img/fees-with-high.jpg' alt='best Software Training' loading="lazy"/>
                                <h6 className='bolder Why-zerobug-section-mg'>Affordable Fees with High quality</h6>
                                <p>We own You knowledge at a very affordable Fee Structure.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section">
                            <div className=" position-relative overflow-hidden   ">
                                <img src='assets/img/24-7-support-1024x1024.png' loading="lazy" alt='best Software Training' style={{ width: '100px' }} />
                                <h6 className='bolder Why-zerobug-section-mg'>24/7 Support</h6>
                                <p>We have provide 24/7 expert support .</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 Why-zerobug-section">
                            <div className=" position-relative overflow-hidden   ">
                                <img src='assets/img/realtime-copy-3.jpg' alt='best Software Training'loading="lazy" />
                                <h6 className='bolder Why-zerobug-section-mg'>35K+ Happy customers</h6>
                                <p>We have 35000+ Happy customers with their satisfied reviews.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid py-5" style={{ background: "linear-gradient(225deg,darkorange 30%,  goldenrod 75%)" }}>
                <div className="container "  >
                    <div className="row d-flex flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7 col-12 ">
                            <h1 className="h3 light-300 text-white">Discover what our satisfied Student have to say about our Teaching</h1>
                        </div>
                        <div className="col-lg-5 col-12 ">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Google.svg" alt="Google" className="img-fluid" loading="lazy"/>
                                    <div><Link to='https://www.google.com/search?q=zerobug+academy&sca_esv=6b1c5e95a3d0b06b&sca_upv=1&source=hp&ei=s4ohZrSjMPiQseMPrKy_yAc&gs_ssp=eJzj4tVP1zc0TDNKLyozM88zYLRSNagwTjQ1Mk2xTE5OSzNISzJMsTKoSLQwTDJKSTRJMzEzSUlJNvHir0otyk8qTVdITE5MSc2tBADrPRYM&oq=zerobug&gs_lp=EhFtb2JpbGUtZ3dzLXdpei1ocCIHemVyb2J1ZyoCCAQyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAuGIAEGMcBGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAEMgUQABiABEi4TFDhCVjKNHAAeACQAQCYAfgOoAHnPqoBBTctMi4zuAEByAEA-AEBmAIFoAKaQKgCAMICCBAAGIAEGLEDwgIREC4YgAQYsQMY0QMYgwEYxwHCAg4QLhiABBixAxjRAxjHAcICCBAuGIAEGLEDwgIOEC4YgAQYxwEYjgUYrwGYAwCSBwM3LTWgB_Ez&sclient=mobile-gws-wiz-hp#ip=1&wptab=si:AKbGX_oXOTjHK3vNPxrwAU4tsC2W_rsdJDrrSHpqUAOdbOh1q9uNYK9vtbmmnS7KXd3yboYf2bOhwMeMmdWxIVwYVpfw0ijnlRKr1nOHCwOPFAW8qUORl1WdTqK3rX_iT9y05M3y7DbX' target='_blank' className='text-white'>Google Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Youtube.svg" style={{ width: '50px' }} alt="YouTube" className="img-fluid" loading="lazy"/>
                                    <div><Link to='#' className='text-white'>Youtube Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Facebook.svg" alt="Facebook" className="img-fluid" loading="lazy"/>
                                    <div><Link to='https://www.facebook.com/zerobugacademytraininginstitute/' target='_blank' className='text-white'>Facebook Reviews</Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5"  >
                <div className="container pt-5 pb-3">
                    <div className="text-center mb-5">

                        <h2>Our Highlights</h2>
                        < hr className='bottom-border' />
                        <p style={{ paddingTop: '10px' }}>Explore the features that make Zerobug Academy shine</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-4 ">
                            <div className=" position-relative overflow-hidden ">
                                <img src="assets/img/realtime-copy-4.jpg" alt='best Software Training' loading="lazy"/>
                                <h6 className='Why-zerobug-section-mg bolder'>Extensive Course Catalog</h6>
                                <p>From Programming to Data Science, we've got it all covered. Choose from a wide array of courses tailored to meet your career goals.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-4  ">
                            <div className=" position-relative overflow-hidden   ">
                                <img src='assets/img/workshop.png' alt='best Software Training' style={{ width: '80px', marginTop: '20px' }} loading="lazy"/>
                                <h6 className='Why-zerobug-section-mg bolder'>Engaging Community</h6>
                                <p>Connect, collaborate, and grow! Join a vibrant community of learners and professionals. Share experiences, insights, and opportunities.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className=" position-relative overflow-hidden   ">
                                <img src='assets/img/clockflat.jpg' alt='best Software Training' loading="lazy"/>
                                <h6 className='Why-zerobug-section-mg bolder'>Online and On-site Learning</h6>
                                <p>Flexibility at its best! Attend classes either in our modern classrooms or from the comfort of your home. Your learning, your choice.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="position-relative overflow-hidden   ">
                                <img src='assets/img/jobasst.jpg' alt='Placement Assistance' loading="lazy"/>
                                <h6 className='Why-zerobug-section-mg bolder'>Placement Assistance</h6>
                                <p>Your success is our success! Benefit from our dedicated placement support and guidance to kickstart your dream career.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="container-fluid home-need-bg ">
                <div className="container">
                    <div className="row margin-30" >
                        <div className="col-lg-9">
                            <h2 class="float-left  heading-left text-center" >
                                Explore Career Opportunities </h2>
                        </div>
                        <div className="col-lg-8">
                            <p>At Zerobug Academy, we believe in not just imparting knowledge but shaping careers. Dive into a world of boundless opportunities as you journey through our meticulously designed courses. Our commitment goes beyond teaching; we're here to open doors to promising career paths. With the skills and certifications earned at Zerobug, you'll be well-equipped to excel in the following domains:</p>
                            <div className="mb-5">
                                <HomeList />
                            </div>


                        </div>

                        <div class="col-lg-4 mt-5 mt-lg-0">

                            <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                <div className="card-header demo-form text-center p-4 text-white">
                                    <h5 className="m-0">Requst Free Demo</h5>
                                </div>
                                <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke", border: '1px solid ' }}>


                                    <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control border-0 p-4"
                                                placeholder="Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control border-0 p-4"
                                                placeholder="Phone No"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control border-0 p-4"
                                                placeholder="Your email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <select
                                                className="custom-select border-0 px-4"
                                                style={{ height: "47px" }}
                                                name="course"
                                                value={formData.course}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select a course</option>
                                                <option value="python">Python</option>
                                                <option value="django">Django </option>
                                                <option value="reactjs">ReactJS</option>
                                                <option value="angular">AngularJS</option>
                                                <option value="selenium">Selenium </option>
                                                {/* Add other options here */}
                                            </select>
                                        </div>
                                        <div>
                                            <button className="btn  btn-block border-0 py-3 demo-form text-white" type="submit">Submit</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'  >
                <div class="col-lg-12 instruc-wrap" id="OurInstructors">
                    <h2 className=''>Join a Community of Achievers</h2>
                    < hr className='bottom-border' />
                    <div class="proj-portal cnt">
                        <p>At <strong> Zerobug Academy,</strong> it's not just about individual learning; it's about joining a community of like-minded individuals and professionals. Share experiences, insights, and opportunities within our engaging community. Benefit not only from our expert instructors but also from the collective knowledge of your peers.</p>
                        <ul class="tick" id='carrer'>
                            <li> <strong>Flexible Learning, Concrete Results: </strong>
                                Whether you prefer the camaraderie of our modern classrooms or the flexibility of learning from home, Zerobug Academy offers a blend of on-site and online learning options. Elevate your skills at your own pace, backed by our commitment to excellence.
                            </li>
                            <li> <strong>Start Your Journey to Success: </strong>
                                Enroll in our top courses and embark on a journey where learning meets excellence. Zerobug Academy is not just a learning platform; it's your launchpad to tech excellence in <strong>Chennai</strong> and beyond. Your journey to success begins here!
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            {/* <!-- Our Classroom/Online Training--> */}
            <div className="container-fluid  home-need-bg">
                <div className="container pt-5 pb-3">
                    <div className="text-center mb-5">
                        <h2 className="" >Our Classroom/Online Training</h2>
                        < hr className='bottom-border' />

                        <p className="padding">" ZeroBug offers the <strong> best Trending Training using classroom and online Training </strong>
                            With hands-on training, Our Top Mentors ensure theory + Practical training in online, offline mode.
                            The main aim of our <strong>Software Training</strong>  Sessions is to deepen the knowledge by acquiring the complete skill set."</p>
                    </div>
                    <div className="row pb-3">
                        <div className="col-lg-4 mb-4">
                            <div className="blog-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid online-images" src="assets/img/zero.jpeg" alt="zerobugAcademy" loading="lazy"/>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="blog-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid online-images" src="assets/img/online (3).webp" alt="zerobugAcademy" loading="lazy"/>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="blog-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid online-images" src="assets/img/class.png" alt="zerobugAcademy" loading="lazy"/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Blog End --> */}

            <div className="container-fluid " >
                <div className="container pt-5 pb-3 ">
                    <div className="text-center mb-5">
                        <h2 className="" >Our Training Google Reviews</h2>
                        < hr className='bottom-border' />

                        <p className="padding">We stand out from the crowd by making sure that our students step out with jobs.Find out more about our training courses!</p>
                    </div>
                    <TestimonialSlider />
                    <br />
                </div>
            </div>


            <Footer />

        </Fragment>
    )
}

export default Home;