import React, { useState, useEffect, Fragment } from 'react';
import Menu from './menu';
import BottomMenu from './BottomMenu';
import DropList from './DropList';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import PythonList from './PythonList';
import SubNav from './SubNav';
import PopupForm from './PopupForm';
import WhatsappButton from './WhatsappButton';
import TawkTo from './TawkTo';
import emailjs from 'emailjs-com';
import Countdown from './Countdown';

const PDF_FILE_URL = "assets/syllabus/Python-Course-Content.pdf";
const Python = () => {
    const downloadFileAtURL = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);
    };
    useEffect(() => {
        document.title = 'Best Python Course in chennai | zerobug Academy';
        return () => {
            document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy';
        };
    }, []);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const closePopup = () => {
        setPopupVisible(false);
    };
    const openPopup = () => {
        setPopupVisible(true);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }

        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name.');
            return;
        }

        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }

        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
        // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };

    return (
        <Fragment>
            {/* <!-- Navbar Start --> */}
            <Menu />
            <BottomMenu />

            {/*     <!-- Header Start --> */}
            <div className="container-fluid banner-background ">
                <div className="container ">
                    <div className="row align-items-center margin-30 ">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <h1 className="text-white text-start text-uppercase" >
                                python Training in
                                chennai
                            </h1>

                            <div className="rating-wrap">
                                <span className="ratingVal">49</span>
                                <img src="../assets/img/R.png" alt="image" />
                                <span className="learnerTxt">15K+ Satisfied Learners</span>
                            </div>
                            <p className='text text-white'>Our expert instructors specialize in Python and offer a comprehensive course covering Python fundamentals, advanced topics such as data analysis with Pandas, web development with Flask, and automation with Selenium, along with hands-on projects. With a focus on practical learning and industry-relevant skills, Zerobug Academy is the Best Python Training Institute in Chennai. Join us to master Python programming, data manipulation, web development, and automation, and enhance your career prospects in the IT industry.</p>


                        </div>

                        <div className="col-lg-6">
                            <div className="image-card  border-0">
                                <img src="./assets/banners/new-python-banner.png" alt="Website Development" />
                                <figcaption className='visually-hidden'>
                                    <a href="https://www.freepik.com/free-photo/positive-dark-skinned-african-american-male-giggles-as-hears-something-funny_12085925.htm">image by freepik</a>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Header End --> */}
            {/* courseNav */}
            <div className="courseNav  d-sm-block" >
                <div className='container' >
                    <div class=" courseNav-wrap">
                        <SubNav />
                    </div>
                </div>
            </div>
            {/* courseNav end*/}
            {/* <!-- Detail Start --> */}
            <div className='container' >
                <div className="container-fluid  body-align-text ">
                    <div className="" id='content'>
                        <div className="row">

                            <div class="col-lg-12 instruc-wrap1" id="OurInstructors">
                                <h2>Why Python?</h2>
                                <div class=" cnt" >

                                    <div class="tick">
                                        <p className='justify-text'> Python known for its readability and versatility, has emerged as a programming
                                            language of choice for developers worldwide. Whether you're a beginner or an experienced coder,
                                            Python offers a seamless learning curve and
                                            a vast ecosystem that spans<strong> web development, data science, artificial intelligence,</strong> and more.</p>
                                    </div>
                                </div>
                            </div>

                            <WhatsappButton />
                            <PopupForm />
                            <TawkTo />

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Detail Start --> */}
            {/* <!-- upcomming training Start --> */}
            <div class="container-fluid features-UpcomingBatch py-5" id='up-coming-batch'>
                <div class="features-courseSchedule">
                    <div class="row wrap">
                        <div class=" container FAQ">
                            <h3 class='float-left h1 paddinng-left-20'>Upcoming Python Training Chennai </h3>
                        </div>
                        <div className="batch-table-wrap col-sm-12 col-lg-921">
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span  >jan 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span  >Feb 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span  >Mar 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span  >Apr 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>


                        </div>
                        <div class=''>
                            <div className='talk2Us-Promo new-Promo-Class '>
                                <div className='heading-promo'>
                                    <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                    talk to us
                                </div>
                                <div className='promo-content'>
                                    <h7 className="title"> Online and Classroom Training</h7>
                                </div>
                                <div className='mrg-top5'>
                                    <i className='fa fa-mobile-phone'></i>+91-9750061584
                                </div>
                                <div className='mrg-top5'>
                                    <i className='fa fa-mobile-phone'></i>+91-8946004709
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- upcomming training end--> */}
            {/* reaqust call */}

            <div className="container-fluid courseReqBatch">
                <div className='features-ReqBatch'>
                    <div className=' col-lg-12 col-md-12 reqBatch-wrap'>
                        <img src='../assets/img/date.png' alt="Date Icon" />
                        <div className='reqBatch-content'>
                            <p>Can’t find a batch you were looking for?</p>
                            <button className="reqBatch-btn " onClick={openPopup}> REQUEST A BATCH</button>
                            {isPopupVisible && (
                                <div className="popup-form">
                                    <div className="popup-content">
                                        <span className="close" onClick={closePopup}>&times;</span>
                                        <h2>Enquiry Now</h2>
                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn btn-primary" type="submit">
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* reaqust call end*/}
            {/* <!-- Detail End --> */}
            {/*  <!-- Detail Start2 --> */}
            <div className="container">
                <div className="container-fluid margin-30" id='syllabus'>
                    <div className="container ">
                        <div className="row">

                            <div className="col-lg-8 FAQ">
                                <h3 className="text-center heading-left text-left" >Python Training Curriculum</h3>

                                <p className='text-align-syllabus'>Experience our comprehensive Python training in Chennai, covering everything from basics to advanced topics. Delve into the latest Python packages and frameworks, gaining valuable exposure and hands-on expertise.</p>
                                <button onClick={() => { downloadFileAtURL(PDF_FILE_URL) }} className='download-buttom text-left'>Download Curriculum</button>
                                <div className="mb-5">

                                    <DropList />
                                </div>
                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center  text-white p-4" >
                                        <h5 className="m-0">Requst Free Demo</h5>
                                    </div>
                                    <div className="card-body rounded-bottom whitesmoke " >
                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form text-white" type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                                {/* <!-- Category List --> */}
                                <div>
                                    <Countdown />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Detail End --> */}

            </div>
            <div className='container-fluid key-module-bg' id='course-content'>

                <div class=" features-courseProject container">
                    <div class="row" >
                        <div class="col-lg-12">
                            <h2 class="mar-bot text-white" >
                                Key Modules of Our Python Course</h2>

                        </div>

                        <div class="key-modules-container ">
                            <div class="key-module">
                                <img src="../assets/img/icons8-python-48.png" alt="Python Basics" class="key-image" />
                                <h3>Python Basics</h3>
                                <p>Lay a strong foundation by grasping the fundamentals of Python programming.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/img/icons8-web-development-48.png" alt="Web Development with Flask" class="key-image" />
                                <h3>Web Development with Flask</h3>
                                <p>Dive into web development and learn how to create dynamic web applications with Flask.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/img/icons8-data-analysis-48.png" alt="Data Analysis and Visualization" class="key-image" />
                                <h3>Data Analysis and Visualization</h3>
                                <p>Uncover the power of Python for data analysis and visualization using popular libraries like Pandas and Matplotlib.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/img/icons8-machine-learning-48.png" alt="Machine Learning with Python" class="key-image" />
                                <h3>Machine Learning with Python</h3>
                                <p>Explore the fascinating realm of machine learning and implement algorithms using libraries like Scikit-Learn.</p>
                            </div>
                        </div>

                    </div>
                    <div class="row margin-60"  id='project1'>
                        <div class="col-lg-12">
                            <h2 class="mar-bot text-white" >
                                Benefits of Learning Python at Zerobug</h2>
                        </div>
                        <div class="benefits-container">
                            <div class="benefit-item">
                                <h3>Industry-Driven Curriculum</h3>
                                <p>Our course is curated based on industry requirements, ensuring you're equipped with skills that employers seek.</p>

                            </div>
                            <div class="benefit-item">
                                <h3>Hands-On Projects</h3>
                                <p>Gain practical experience by working on real-world projects, honing your problem-solving abilities.</p>

                            </div>
                            <div class="benefit-item">
                                <h3>Expert Instructors</h3>
                                <p>Learn from seasoned professionals dedicated to guiding you through your learning journey.</p>

                            </div>
                            <div class="benefit-item">
                                <h3>Career Support</h3>
                                <p>Benefit from our career guidance and placement assistance to step confidently into the professional realm.</p>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {/* 

            {/*  modes f training */}
            <div className='container-fluid training-modes-wrap' >
                <div className=' container-fluid courseReqBatch training-modes-wrap'>
                    <div className="col-lg-12 col-md-12  mrg-top20">

                        <div class="row ">
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob7.jpg" alt="python training" width="100%" height="100%"></img>
                                    </div>
                                    <h3>Classroom Training</h3>
                                    <div className="training-mode-cnt">
                                        <span >Instructor Led Classroom Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 5.jpg" alt="online course" width="100%" height="100%"></img>
                                    </div>
                                    <h3 >Online Training</h3>
                                    <div className="training-mode-cnt">
                                        <span>Instructor Led Online Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 4.jpg" alt="best trining" width="100%" height="100%"></img>
                                    </div>
                                    <h3> Corporate Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>Instructor Led Corporate Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/bobl36.jpg" alt="image" width="100%" height="100%"></img>
                                    </div>
                                    <h3> One to One Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>best training based on requirement</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  modes f training end */}
            {/*  /*course feature */}
            <div className='container-fluid  home-need-bg'>
                <div className="container ">

                    <div className=' CourseFeatures home-need-bg'>
                        <div className='features-Coursefeatures'>
                            <div className="row text-center" >
                                <div className="col-lg-12 mar-bot-top " >
                                    <h2>Why Choose Zerobug Academy for Python Courses?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj'>
                                        <img decoding="async" src="../assets/img/blob.jpg" alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <div className='cnt'>
                                            <h4> Expert Faculty</h4>
                                            <p className='text-center'> Learn from industry experts and certified professionals with extensive experience in <strong>Python </strong> development. Our faculty brings a wealth of knowledge
                                                and real-world insights to the classroom, ensuring you receive the best education.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-green'>
                                        <img decoding="async" src="../assets/img/cloud-computing.png" style={{ color: 'orangered' }} alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <div className='cnt'>
                                            <h4>Comprehensive Curriculum</h4>
                                            <p className='text-center'> Our Python course is designed to cover the entire spectrum of Python programming, from fundamentals to advanced concepts. Master the language that
                                                powers <strong>web development, data science, artificial intelligence,</strong> and more.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-voilet'>
                                        <img src="../assets/img/blob1.jpg" alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <div className='cnt'>
                                            <h4>Hands-On Training</h4>
                                            <p className=" text-center">At Zerobug Academy, we believe in a hands-on, practical approach to learning. Our Python course includes extensive practical sessions, projects, and
                                                case studies to ensure you gain real-time exposure and expertise.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-blue'>
                                        <img decoding="async" src="../assets/img/OIP (3).jpg" alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <div className='cnt'>
                                            <h4 >Latest Tools and Techniques</h4>
                                            <p className='text-center'>Stay ahead in the rapidly evolving tech industry by learning the latest tools and techniques in Python programming.
                                                Our courses are regularly updated to incorporate the latest industry trends and best practices.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top">
                                    <div className='keyFeatures-wrap proj-yellow'>
                                        <img decoding="async" src="../assets/img/blob2.jpg" alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <div className='cnt'>
                                            <h4>Placement Assistance</h4>
                                            <p className='text-center'> As the <strong>Best Institute in Pallikaranai,Velachey</strong>, we understand the importance of kickstarting your career. Zerobug Academy provides
                                                placement assistance, connecting you with leading companies and job opportunities.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-red'>
                                        <img decoding="async" src="../assets/img/blob3.jpg" alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <div className='cnt'>
                                            <h4>Flexible Learning Formats</h4>
                                            <p className="text-center "> We offer flexible learning formats to suit your preferences. Choose from classroom training, online sessions
                                                , or a blended approach – whatever works best for you</p>

                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="container-fluid " id='Career'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 FAQ">
                                <h2 className=" text-center heading-left text-left" >
                                    Career Opportunities</h2>
                            </div>
                            <div className="col-lg-8">
                                <div className="mb-5">
                                    <p>" The demand for <strong>Python developers</strong> is soaring across industries. By mastering <strong>Python at Zerobug Academy</strong>, you position yourself for an array of career opportunities "</p>
                                </div>
                                <div class="col-lg-12 instruc-wrap" id="OurInstructors">

                                    <div class="proj-portal cnt">

                                        <ul class="tick line-height" >
                                            <li><span>PythonDeveloper:</span> Create robust applications and software solutions.</li>
                                            <li><span>DataScientist:</span> Dive into data analytics, machine learning, and artificial intelligence.</li>
                                            <li><span>WebDeveloper:</span> Craft dynamic websites and applications using Python frameworks.</li>
                                            <li><span>AutomationEngineer:</span> Streamline processes through automation scripts.</li>
                                        </ul>
                                    </div>
                                </div>


                            </div>

                            <div class="col-lg-4   mt-lg-0">

                                <div class="d-flex flex-column text-center rounded mb-5  px-4">
                                    <div  >
                                        <div className='talk2Us-Promo'>
                                            <div className='heading-promo'>
                                                <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                                talk to us
                                            </div>
                                            <div className='promo-content'>
                                                <h7 className="title  text-center"> Online and Classroom Training</h7>
                                            </div>
                                            <div className='mrg-top5 black-color text-center' >
                                                <i className='fa fa-mobile-phone'></i>+91-9750061584
                                            </div>
                                            <div className='mrg-top5 black-color' >
                                                <i className='fa fa-mobile-phone  text-center'></i>+91-8946004709
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5" style={{ background: "linear-gradient(225deg,darkorange 30%,  goldenrod 75%)" }}>
                <div className="container "  >
                    <div className="row d-flex flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7 col-12 ">
                            <h1 className="h3 light-300 text-white">Discover what our satisfied Student have to say about our Teaching</h1>
                        </div>
                        <div className="col-lg-5 col-12 ">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Google.svg" alt="Google" className="img-fluid" />
                                    <div><Link to='https://www.google.com/search?q=zerobug+academy&sca_esv=6b1c5e95a3d0b06b&sca_upv=1&source=hp&ei=s4ohZrSjMPiQseMPrKy_yAc&gs_ssp=eJzj4tVP1zc0TDNKLyozM88zYLRSNagwTjQ1Mk2xTE5OSzNISzJMsTKoSLQwTDJKSTRJMzEzSUlJNvHir0otyk8qTVdITE5MSc2tBADrPRYM&oq=zerobug&gs_lp=EhFtb2JpbGUtZ3dzLXdpei1ocCIHemVyb2J1ZyoCCAQyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAuGIAEGMcBGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAEMgUQABiABEi4TFDhCVjKNHAAeACQAQCYAfgOoAHnPqoBBTctMi4zuAEByAEA-AEBmAIFoAKaQKgCAMICCBAAGIAEGLEDwgIREC4YgAQYsQMY0QMYgwEYxwHCAg4QLhiABBixAxjRAxjHAcICCBAuGIAEGLEDwgIOEC4YgAQYxwEYjgUYrwGYAwCSBwM3LTWgB_Ez&sclient=mobile-gws-wiz-hp#ip=1&wptab=si:AKbGX_oXOTjHK3vNPxrwAU4tsC2W_rsdJDrrSHpqUAOdbOh1q9uNYK9vtbmmnS7KXd3yboYf2bOhwMeMmdWxIVwYVpfw0ijnlRKr1nOHCwOPFAW8qUORl1WdTqK3rX_iT9y05M3y7DbX' target='_blank' className='text-white'>Google Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Youtube.svg" style={{ width: '50px' }} alt="YouTube" className="img-fluid" />
                                    <div><Link to='#' className='text-white'>Youtube Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Facebook.svg" alt="Facebook" className="img-fluid" />
                                    <div><Link to='https://www.facebook.com/zerobugacademytraininginstitute/' target='_blank' className='text-white'>Facebook Reviews</Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*  /*course feature end */}
            {/* Chennai and Elevate Your Career */}
            <div className="container">
                <div className='container-fluid'  >
                    <div class="col-lg-12 instruc-wrap" id="OurInstructors">
                        <h2 className='text-center heading-left text-left' >Join Zerobug Academy for the Best Python Course in Chennai and Elevate Your Career</h2>
                        <div class="proj-portal cnt">
                            <p>Whether you are a beginner looking to explore the world of programming or a seasoned developer aiming to enhance your skills, Zerobug Academy is your trusted partner for success.
                                Enroll in the <strong>Best Python Course in Chennai</strong> and unlock a world of possibilities in the tech industry.</p>
                            <ul class="tick">
                                <li>Don't miss out on this opportunity to learn from the best and shape your future in Python programming. </li>
                                <li>Contact <strong>Zerobug Academy</strong> today and take the first step toward becoming a Python expert! Your journey to excellence starts here.</li>
                                <li>Enroll Now and Embrace the Future of Coding with <strong>Zerobug Academy in  Pallikaranai, Velachery Chennai!</strong> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid margin-30" id='FAQ'>
                    
                    <div className="row">
                        <div className="col-lg-7 FAQ">
                            <h2 className="mar-bot-top text-center heading-left text-left">
                                Frequently Asked Questions
                            </h2>
                            <div className="mb-5">
                                <PythonList/>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="alumni-work mar-bot-top">
                                <h3>Our Alumni Work in Top MNC’S</h3>
                                <p>"Zerobug Academy has achieved remarkable success by placing thousands of students in leading roles across top multinational organizations."</p>
                                <div className="row">
                                    <div className="alumni-work-container">
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/DHL.png" alt="Alumni Work 1"  decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/tvs-credit.png" alt="Alumni Work 2"decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/zoho-1-logo-png-transparent.png" alt="Alumni Work 3" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/freshworks.webp" alt="Alumni Work 4"decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/larsen-and-toubro.jpg" alt="Alumni Work 5" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/HCLTECH.NS.png" alt="Alumni Work 6"decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/centelon.png" alt="Alumni Work 7" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/INFY_BIG.png" alt="Alumni Work 8" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive h-width" src="./assets/banners/Hexaware.jpg" alt="Alumni Work 9" decoding='async' loading='lazy'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                <p>Join the success community to build your future </p><button onClick={openPopup} className='entrol-button'> Enroll Now</button>
                                </div>
                              
                            </div>
                        </div>

                 
                </div>
            </div>
            </div>

            <Footer />
        </Fragment>
    )
}

export default Python; 