import React, { useState } from 'react';
import Dropdown from '../DropDown';

const  DjangoSyllabus= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: 'Introduction to Django',
      options: ['Introduction to Django','What is Django?','Django and Python','MVT','Pypi download and install Django'],
    },
    
    {
      title: 'Front-End Introduction & HTML levels',
      options: ['HTML Level One-(Introduction, Basics , Basic Tagging, Lists, Divs and Spans , Attributes)','HTML Level One Assessment -Overview','HTML Level Two –(Advanced, Level Two Introductio, Tables, Forms, Labels , selections )','HTML Level Two –Assessmen'],
    },
    
     {
      title: 'CSS ,BOOTSTRAP AND JAVASCRIPT',
      options: [ 'CSS basics-(Fonts, Box models, Spectrum project overview)','Bootstrap overview-(Introduction, Buttons, Forms, Navbars, Bootstrap Assessmen)','Javascript Overview-(Introduction, Basics , Connecting javascript , Operators, Control flow, Loops concepts, Functions, Array, Object , Javascript Assessment)'],   
      },
    {
      title: 'Document object model, jquery and Back end intoduction  ',
      options: ['Document object model','Introduction', 'DOM Interaction','Content Interaction', 'Events','Game Project overview and solutions','Jquery-(Basics , Event , Project overview and solution)','Backend introduction--(Introduction , Command line and Terminal overview)']
      
    },
    

    {  
      title: 'Python',
      options: ['Introduction','Installation and setup','Numbers','Strings','Lists','Dictionaries','Tuples, sets and Booleans',
      'Control flow','Function','Oops concept','Regular expressions','Modules and Package','Decorators','Name and main','Lab Session 5'],
   },
    {  
        title: 'DJANGO',
        options: ['Django setup','Challenge Task and solutions','URL Mappings','Templates','Static files','Creating models','Population Scripts','Models-Templates-Views Paradigm','Django Forms',
        'Forms basics code along','Form validation','Model forms','Relative URLs with Templates','URL Template Inheritance',
        ' Template Filters and Custom Filters','Django password','User Models','Coding User Models and Forms','Registration','Logins','Lab session 6']
      },

      {  
        title: 'Django Deployment',
        options: ['Introduction', 'Setting up GitHub','Full Deployment Walkthrough on Python Anywhere'],
      },
      
      {  
        title: 'Advanced Topics - CBVs',
        options: ['Introduction','Hello World with CBVs','Template Views with CBV','Detail View and List View','CRUD Views',
        'Introduction to Debug Toolbar','Debug Toolbar Overview','Lab Session 8'],
      },
      {  
        title: 'Advanced Topics - Customizing the Django Admin',
        options: ['Introduction to Admin','Admin Templates','Ordering Fields','Adding Search','Adding Filters','Adding Fields','Editable List View','Lab Session 9',],
      },
      {  
        title: ' Django REST framework',
        options: [' What is REST?','Why REST','What and Why DJango REST Framework','DRF setup','RESTful Structure',
        'DRF Quick start-(Model Serializer, Update views, Update URLs, Test)','Refactor for REST-(GET, Datetime format, Post, Author Format, delete)'],
      },
      {  
        title: ' REST In Action',
        options: ['Creating the Project','Create a view','Configure the URL and Test','Create APP Level URLs','Create a model Class','Configure the database and run migrations','Using the models, and show in views and test','Model questions']
        
     
      },
      
      {  
        title: 'Function based views and Serializers',
        options: ['DRF Components',' Function Based views','Serializers','Create the project',' Create the model','Create the Serializer','GET single student','Create Student',
        'Implement Non primary key based Operations','@API_VIEW','Configure the URLs','Test and Test using Postman',' Function based views and serializers','Lap Pratice 3']
      },
     
      {  
        title: ' Class based views and Mixins & Generic views and viewsets',
        options: ['Introduction ','Creating the Project',' Implementing primary and nonnprimay key based operations','Configure the URLs and Test','Mixins in primary and non primary Key based operations','Lab CHAPTER 4',
        'Introduction','Genric in action','Create voewsets','Configure URLs and TEST','Lab CHAPTER 5']
      },
      
      {  
        title: 'Nested Serializers & Pagination and Security',
        options: ['Create the project','Create the model ','Create the serializers','Create the REST endpoints ','Congigure the URLs',
        'Pagination in action',' Pagination at class level','Using LimitOFFsetPagination','Authentication in action','Authorization in Action','Global Security']
      },

  
  ];
  const [displayedDropdowns, setDisplayedDropdowns] = useState(dropdowns.slice(0, 6)); // Display 6 dropdowns initially
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setDisplayedDropdowns(showAll ? dropdowns.slice(0, 6) : dropdowns); // Toggle between showing 6 or all dropdowns
  };
  return (
    <div>
      {displayedDropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
      <button className='view-button'  onClick={toggleShowAll}>{showAll ? 'Show Less' : 'Show More'}</button>
    </div>
  );
};
export default DjangoSyllabus;
