import React, { useEffect, Fragment, useState } from 'react';
import emailjs from 'emailjs-com';
import Menu from './menu';
import DropList from './DropList';
import { Link } from 'react-router-dom';
/* import { Link } from 'react-scroll'; */
/* import PythonCarrer from './PythonCarrer'; */
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const CloudBlog = () => {
    useEffect(() => {
        document.title = 'Best Cloud Computing Training Institute in Chennai';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);

    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                            {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                           </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                     </ul>
                                </li>
                                <li><Link to="/blog" style={{color:'red'}} >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid   banner-background1"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>

          <WhatsappButton/>
            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h1" style={{ textAlign: "center", marginBottom: "40px" }}>
                                Cloud Computing Trends To Be Prepared In The Year 2024 </h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>As we are about to enter into the year 2024 , IT executives are looking towards the attention of the use of technology to attain their business goals. <strong> Cloud Computing Training in Chennai </strong>has proved to be the best trending technology of all time.
                                             Cloud has proved significant changes to companies in 2017. Here in this blog, we have mentioned <strong>Cloud Computing</strong>  Trends to be prepared in the year 2017. Cloud has provided a way to retrieve and store an amount of information.</p>

                                        
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Expand The Storage Of Cloud Storage</h4>
                                        <p>Technologies have opened a flexible and financial door for customers and business for early cloud computing 
                                            services. The growth and infrastructure as a service have enlarged numerous cloud services both private and public sectors. In 2024 , organizations are expecting to take advantage of high-performance to cloud guarantees. The overall survey used worldwide is to accomplish goals to be deployed. Cisco predicted that SaaS will count 60% and has increased 12% in the year 2017. 
                                            We can also expect a rapid growth in cloud services solutions.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Cloud Services Solutions For Exponential Growth</h4>
                                        <p>Innovators like Stephen Hawking and Elon Musk played an important role in stellar role in the IT industry.
                                             The industry experts will see their growth, innovations in real-time cloud computing and data analytics 
                                             are set to push IoE (Internet of Everything). <strong>Cloud Computing Chennai</strong> plays an important role as IoE 
                                             develops are aimed at all interactions. The data can be used in multiple ways, for customer experience 
                                             through automation for the use of smart robots. IoE plays an important role in business. 
                                            It gives more insight to customers to relate their services and products to one another.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Center Stage Of IoE</h4>
                                        <p>Businesses have recognized the necessity of investing tools like event management and security information and detection systems for cybersecurity. <strong>Cloud Computing Training at zerobug Academy in Pallikaranai Chennai</strong>  plays a major role in managed security services offering full measures.</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Cloud Security Challenges</h4>
                                        <p>The amount of data stored across the world is to be grown tremendously in 2024 . Customers expect more and faster from network providers. 
                                            This network will gain increased expectations for fast-growing apps and services. <strong> Cloud Computing Training in Pallikaranai, Velacher Chennai </strong></p>
                                                                              </div>                                
                                </div>
                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                    <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>                                                  
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>
                                          
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default CloudBlog;