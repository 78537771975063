import React, { useState, useEffect, Fragment } from 'react';
import Menu from '../menu';
import BottomMenu from '../BottomMenu';
import Footer from '../Footer';
import SubNav from '../SubNav';
import { Link } from 'react-router-dom';
import AngularList from './AngularList';
import TawkTo from '../TawkTo';
import './Angular.css';
import PopupForm from '../PopupForm';
import AngularSyllabus from './AngularSyllabus';
import WhatsappButton from '../WhatsappButton';
import emailjs from 'emailjs-com';
const PDF_FILE_URL = "assets/syllabus/Angular-js-Course-Content.pdf";
const AngularCourse = () => {

    const downloadFileAtURL = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);
    };
    useEffect(() => {
        document.title = 'Best Angular Js course In Velachery, Chennai | Zerobug Academy';
        return () => {
            document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy';
        };
    }, []);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });
    const openPopup = () => {
        setPopupVisible(true);
    };
    const closePopup = () => {
        setPopupVisible(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }

        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }

        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }

        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
        // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };

    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <BottomMenu />
            {/* <!-- Navbar End --> */}
            <div className="container-fluid banner-background ">
                <div className="container ">
                    <div className="row align-items-center margin-30 ">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <h1 className="text-white text-start text-uppercase">
                                angularjs Training in
                                chennai
                            </h1>
                            <div className="rating-wrap">
                                <span className="ratingVal">49</span>
                                <img src="../assets/img/R.png" alt="image" loading='lazy' />
                                <span className="learnerTxt">15K+ Satisfied Learners</span>
                            </div>
                            <p className='text text-white'>Master Angular Course in Chennai at Zerobug Academy, your premier destination for top-notch Angular training. Our expert instructors specialize in Angular and offer a comprehensive course covering Angular fundamentals, advanced topics such as RxJS, Angular CLI, and Angular Material, and hands-on projects. With a focus on practical learning and industry-relevant skills, Zerobug Academy is the Best Angular Training Institute in Chennai. Join us to master Angular components, services, routing, forms, and enhance your web development expertise.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="image-card python-new-banner border-0">
                                <img src="./assets/banners/Angular-banner-image.png" decoding='async' alt="best angular training in chennai" loading='lazy' />
                                <figcaption className='visually-hidden '><a href="https://www.freepik.com/free-photo/portrait-shocked-young-african-man_7380265.htm#from_view=detail_alsolike">image by freepik</a></figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* courseNav */}
            <div className="courseNav  d-sm-block" >
                <div className='container' >
                    <div className=" courseNav-wrap">

                        <SubNav />
                    </div>
                </div>
            </div>
            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">
                    <div className="container " id='content'>
                        <div className="row">

                            <div class="col-lg-12 instruc-wrap1" id="OurInstructors">
                                <h2 className='heading-left  text-angular' >Why AngularJS? </h2>
                                <div class=" cnt">
                                    <div class="tick" >
                                        <p className='justify-text'>In the dynamic landscape of web development, mastering AngularJS is a game-changer.
                                            Welcome to Zerobug Academy in Chennai, where we unlock the potential of AngularJS,
                                            empowering you to become a proficient web developer. Our state-of-the-art institute,
                                            located in <strong>Pallikaranai and Velachery,</strong> is committed to providing
                                            the best <strong> AngularJS training in Chennai,</strong> setting you on the path to a successful and fulfilling career.</p>
                                    </div>
                                </div>
                            </div>
                            <WhatsappButton />
                            <TawkTo />
                            <PopupForm />

                        </div>
                    </div>
                </div>
            </div>
            {/*  course-batch */}
            <div class="container-fluid features-UpcomingBatch py-5" id='up-coming-batch'>
                <div class="features-courseSchedule">
                    <div class="row wrap ">
                        <div class="container FAQ">
                            <h3 class='float-left h1 paddinng-left-20'>Upcoming Angular Training Chennai </h3>
                        </div>
                        <div className="batch-table-wrap col-sm-12 col-lg-921">
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>jan 05</span>
                                </div>
                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Feb 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Mar 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Apr 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>


                        </div>
                        <div>
                            <div className='talk2Us-Promo new-Promo-Class '>
                                <div className='heading-promo'>
                                    <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                    talk to us
                                </div>
                                <div className='promo-content'>
                                    <h7 className="title"> Online and Classroom Training</h7>
                                </div>
                                <div className='mrg-top5'>
                                    <i className='fa fa-mobile-phone'></i>+91-9750061584
                                </div>
                                <div className='mrg-top5'>
                                    <i className='fa fa-mobile-phone'></i>+91-8946004709
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* reaqust call */}
            <div className="container-fluid courseReqBatch">
                <div className='features-ReqBatch'>
                    <div className=' col-lg-12 col-md-12 reqBatch-wrap'>
                        <img src='../assets/img/date.png' alt="Date Icon" />
                        <div className='reqBatch-content'>
                            <p>Can’t find a batch you were looking for?</p>
                            <button className="reqBatch-btn " onClick={openPopup}> REQUEST A BATCH</button>
                            {isPopupVisible && (
                                <div className="popup-form">
                                    <div className="popup-content">
                                        <span className="close" onClick={closePopup}>&times;</span>
                                        <h2>Enquiry Now</h2>
                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">angular</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn btn-primary" type="submit">
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Detail Start2 --> */}
            <div className="container">
                <div className="container-fluid margin-30" id='syllabus'>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-8 FAQ">
                                <h3 className="text-center heading-left text-left" >
                                    Angular Course Curriculum </h3>
                                <p className='text-align-syllabus'>Discover the power of AngularJS with our comprehensive course.  Dive into the fundamentals with modules covering AngularJS basics, directives, controllers, and services. Learn to navigate single-page applications seamlessly with routing and form validation techniques. Delve into advanced topics such as HTTP communication, best practices, and internationalization. </p>
                                <button onClick={() => { downloadFileAtURL(PDF_FILE_URL) }} className='download-buttom'>Download-Curriculum</button>

                                <div className="mb-5">
                                    <AngularSyllabus />

                                </div>
                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4 text-white"  >
                                        <h5 className="m-0">Requst Free Demo</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  whitesmoke">
                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">angular</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form text-white" type="submit">Submit</button>
                                            </div>
                                        </form>

                                    </div>

                                </div>

                                {/* <!-- Category List --> */}
                                <div class="mb-5">
                                    <div class="promo-cnt whiteBG courseHglhts-wrap">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color" > 60+</div>
                                                    <span> Hours of Practical Training </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color" > 60+</div>
                                                    <span>Case Studies and Projects </span>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color" >24/7</div>
                                                    <span>Expert Support</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color" >100%</div><span> Placement Assurence </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Detail End --> */}

            </div>
            <div className='container-fluid key-module-bg' id='project1'>

                <div className=" features-courseProject container">
                    <div className="row " >
                        <div className=" col-lg-12 ">
                            <h2 className=" mar-bot text-white" >
                                How Does Studying Angular JS Benefits Your Career?</h2>
                            <p className='text-white'>AngularJS, developed by Google, is a powerful front-end web application framework known for its efficiency and flexibility. Acquiring skills in AngularJS opens doors to a plethora of career opportunities. Here's how studying AngularJS at Zerobug Academy benefits your career:</p>
                        </div>

                        <div className="key-modules-container ">
                            <div className="key-module">
                                <img src="../assets/img/Angular.icon.png" alt="best AngularJS course" class="key-image" loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a href="https://www.freepik.com" >Icon by Freepik</a>
                                </figcaption >
                                <h3>High Demand in the Industry</h3>
                                <p>AngularJS is widely used by leading companies, and proficiency in this framework is a sought-after skill in the job market.</p>
                            </div>
                            <div className="key-module">
                                <img src="../assets/img/icons8-web-development-48.png" alt="best AngularJS course" class="key-image" loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a href="https://www.freepik.com" >Icon by Freepik</a>
                                </figcaption >
                                <h3>Versatility in Web Development.</h3>
                                <p>AngularJS allows you to build dynamic and robust web applications, making you a valuable asset in the realm of web development.</p>
                            </div>
                            <div className="key-module">
                                <img src="../assets/banners/increase-employe.png" alt="best AngularJS course" class="key-image" loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a href="https://www.freepik.com" >Icon by Freepik</a>
                                </figcaption >
                                <h3>Increased Employability</h3>
                                <p>Employers often seek candidates with expertise in AngularJS, making your resume stand out and enhancing your employability.</p>
                            </div>
                            <div className="key-module">
                                <img src="../assets/img/cloud-computing.png" alt=" best AngularJS course" class="key-image" loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a href="https://www.freepik.com" >Icon by Freepik</a>
                                </figcaption >
                                <h3>Competitive Edge</h3>
                                <p>Stay ahead of the competition by mastering the latest technologies in <strong>web development</strong>, positioning yourself as a skilled and forward-thinking developer.</p>
                            </div>
                        </div>

                    </div>
                    <div className="row margin-60" id='project1'>
                        <div className="col-lg-12">
                            <h2 className="mar-bot text-white" >
                                Our Course Modules in Detail</h2>
                            <p className="text-white ">At Zerobug Academy, our AngularJS course is designed to provide a comprehensive understanding of the framework, ensuring that you are well-prepared for real-world applications. Here's an overview of our course modules:</p>

                        </div>
                        <div className="benefits-container">
                            <div className="benefit-item">
                                <h3>Introduction to AngularJS</h3>
                                <p>Get acquainted with the fundamentals of AngularJS, understanding its architecture and core concepts.</p>

                            </div>
                            <div className="benefit-item">
                                <h3>Directives and Filters</h3>
                                <p>Explore the power of directives and filters in AngularJS, enhancing your ability to manipulate the DOM and filter data effectively.</p>

                            </div>
                            <div className="benefit-item">
                                <h3>Routing in AngularJS</h3>
                                <p>Master the art of navigation by delving into AngularJS routing, creating single-page applications with seamless transitions</p>

                            </div>
                            <div className="benefit-item">
                                <h3>Forms and Validations</h3>
                                <p>Learn how to handle user input with finesse, implementing forms and validations in AngularJS for a smooth user experience.</p>

                            </div>

                        </div>
                    </div>

                    <div className="row marginTop-20 ">

                        <div className="benefits-container">

                            <div className="benefit-item">
                                <h3>Controllers and Expressions</h3>
                                <p>Dive into the world of controllers and expressions, learning how to manage and display data in your web applications.</p>

                            </div>
                            <div className="benefit-item">
                                <h3>Services and Dependency Injection</h3>
                                <p>Understand the significance of services and dependency injection in building modular and maintainable AngularJS applications</p>

                            </div>
                            <div className="benefit-item">
                                <h3>Testing AngularJS Applications</h3>
                                <p>Benefit from our career guidance and placement assistance to step confidently into the professional realm.</p>

                            </div>
                            <div className="benefit-item">
                                <h3>Building Real-World Projects</h3>
                                <p>Apply your knowledge to practical scenarios by working on real-world projects, solidifying your skills and enhancing your portfolio.</p>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {/* 
python overview */}

            {/*  modes f training */}
            <div className='container-fluid training-modes-wrap' >
                <div className=' container-fluid courseReqBatch training-modes-wrap'>
                    <div className="col-lg-12 col-md-12  mrg-top20">

                        <div class="row ">
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob7.jpg" alt="AngularJS training" loading='lazy' width="100%" height="100%"></img>
                                    </div>
                                    <h3>Classroom Training</h3>
                                    <div className="training-mode-cnt">
                                        <span >Instructor Led Classroom Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 5.jpg" alt="online course" width="100%" height="100%" loading='lazy'></img>
                                    </div>
                                    <h3 >Online Training</h3>
                                    <div className="training-mode-cnt">
                                        <span>Instructor Led Online Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 4.jpg" alt="best trining" width="100%" height="100%" loading='lazy'></img>
                                    </div>
                                    <h3> Corporate Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>Instructor Led Corporate Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/bobl36.jpg" alt="image" width="100%" height="100%" loading='lazy'></img>
                                    </div>
                                    <h3> One to One Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>best training based on requirement</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  modes f training end */}
            <div className='container-fluid  home-need-bg'>
                <div className="container ">

                    <div className=' CourseFeatures home-need-bg'>
                        <div className='features-Coursefeatures'>
                            <div className="row text-center" >
                                <div className="col-lg-12 mar-bot-top " >
                                    <h2 >How Zerobug Academy Can Provide the Best Course in Chennai?</h2>
                                    <p>At Zerobug Academy, we take pride in our commitment to delivering the best AngularJS training in Chennai. Here's why we stand out:</p>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj'>
                                        <img decoding="async" src="../assets/img/blob.jpg" alt="Expert Faculty" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4> Expert Faculty</h4>
                                            <p className='text-center'>Learn from industry experts and seasoned instructors with <strong>hands-on experience in AngularJS</strong>development.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-green'>
                                        <img decoding="async" src="../assets/banners/community.png" style={{ color: 'orangered' }} alt="Practical Approach" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com/search">Icon by Mayor Icons</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4>Practical Approach</h4>
                                            <p className='text-center'>Our curriculum emphasizes hands-on learning through<strong>real-world projects</strong> , ensuring that you can apply your skills effectively</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-voilet'>
                                        <img src="../assets/img/blob1.jpg" alt="Interactive Learning" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4>Interactive Learning</h4>
                                            <p className=" text-center">Engage in dynamic sessions, discussions, and collaborative environments that promote a deeper understanding of AngularJS concepts</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-blue'>
                                        <img decoding="async" src="../assets/banners/latesttool.png" alt="industry-Relevant Curriculum" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com/icon/earth_3408509#fromView=search&page=1&position=22&uuid=065e20ff-1b8b-4a2a-8df4-1c73c969c78d" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4 >Industry-Relevant Curriculum</h4>
                                            <p className='text-center'>Stay ahead with our up-to-date curriculum, designed to cover the latest advancements andbest <strong> practices in AngularJS </strong> development.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top">
                                    <div className='keyFeatures-wrap proj-yellow'>
                                        <img decoding="async" src="../assets/img/blob2.jpg" alt="Placement Assistance" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4>Placement Assistance</h4>
                                            <p className='text-center'>Benefit from comprehensive placement assistance, including resume building, interview preparation, and industry connections, to kickstart your career in <strong>AngularJS </strong> development.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-red'>
                                        <img decoding="async" src="../assets/img/blob3.jpg" alt="Flexible Timing" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4>Flexible Timing </h4>
                                            <p className="text-center "> Choose from flexible schedules to accommodate your busy lifestyle, ensuring a comprehensive learning experience</p>

                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <p>As the digital landscape evolves, AngularJS remains a cornerstone in modern web development. <strong>Enroll in Zerobug Academy's AngularJS course in Chennai</strong>  and unlock a world of possibilities. Master the intricacies of AngularJS, gain practical experience, and position yourself as a sought-after web developer in the competitive job market.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  /*course feature */}
            <div className="container py-5">

                <div className="container-fluid margin-30" id='Career'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 FAQ">
                                <h2 className="text-center heading-left text-left " >
                                    Career Opportunities</h2>
                            </div>
                            <div className="col-lg-8">
                                <div className="mb-5">
                                    <p>" The demand for Angular developers is soaring across industries. By <strong>mastering Angular at Zerobug Academy</strong> , you position yourself for an array of career opportunities "</p>
                                </div>
                                <div class="col-lg-12 instruc-wrap" id="OurInstructors">

                                    <div class="proj-portal cnt">

                                        <ul class="tick line-height" >
                                            <li> <span>Angular Developer:</span> Create robust applications and software solutions.</li>

                                            <li><span>Web Developer:</span> Craft dynamic websites and applications using Front-End frameworks.</li>

                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">

                                <div class="d-flex flex-column text-center rounded mb-5  px-4">
                                    <div className=''>
                                        <div className='talk2Us-Promo'>
                                            <div className='heading-promo text-center' >
                                                <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                                talk to us
                                            </div>
                                            <div className='promo-content'>
                                                <h7 className="title"> Online and Classroom Training</h7>
                                            </div>
                                            <div className='mrg-top5 black-color' >
                                                <i className='fa fa-mobile-phone'></i>+91-9750061584
                                            </div>
                                            <div className='mrg-top5' black-color>
                                                <i className='fa fa-mobile-phone'></i>+91-8946004709
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5" style={{ background: "linear-gradient(225deg,darkorange 30%,  goldenrod 75%)" }}>
                <div className="container "  >
                    <div className="row d-flex flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7 col-12 ">
                            <h1 className="h3 light-300 text-white">Discover what our satisfied Student have to say about our Teaching</h1>
                        </div>
                        <div className="col-lg-5 col-12 ">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Google.svg" alt="Google" className="img-fluid" />
                                    <div><Link to='https://www.google.com/search?q=zerobug+academy&sca_esv=6b1c5e95a3d0b06b&sca_upv=1&source=hp&ei=s4ohZrSjMPiQseMPrKy_yAc&gs_ssp=eJzj4tVP1zc0TDNKLyozM88zYLRSNagwTjQ1Mk2xTE5OSzNISzJMsTKoSLQwTDJKSTRJMzEzSUlJNvHir0otyk8qTVdITE5MSc2tBADrPRYM&oq=zerobug&gs_lp=EhFtb2JpbGUtZ3dzLXdpei1ocCIHemVyb2J1ZyoCCAQyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAuGIAEGMcBGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAEMgUQABiABEi4TFDhCVjKNHAAeACQAQCYAfgOoAHnPqoBBTctMi4zuAEByAEA-AEBmAIFoAKaQKgCAMICCBAAGIAEGLEDwgIREC4YgAQYsQMY0QMYgwEYxwHCAg4QLhiABBixAxjRAxjHAcICCBAuGIAEGLEDwgIOEC4YgAQYxwEYjgUYrwGYAwCSBwM3LTWgB_Ez&sclient=mobile-gws-wiz-hp#ip=1&wptab=si:AKbGX_oXOTjHK3vNPxrwAU4tsC2W_rsdJDrrSHpqUAOdbOh1q9uNYK9vtbmmnS7KXd3yboYf2bOhwMeMmdWxIVwYVpfw0ijnlRKr1nOHCwOPFAW8qUORl1WdTqK3rX_iT9y05M3y7DbX' target='_blank' className='text-white'>Google Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Youtube.svg" style={{ width: '50px' }} alt="YouTube" className="img-fluid" />
                                    <div><Link to='#' className='text-white'>Youtube Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Facebook.svg" alt="Facebook" className="img-fluid" />
                                    <div><Link to='https://www.facebook.com/zerobugacademytraininginstitute/' target='_blank' className='text-white'>Facebook Reviews</Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* Chennai and Elevate Your Career */}
                <div className='container-fluid'  >
                    <div class="col-lg-12 instruc-wrap" id="OurInstructors">
                        <h3 > Ready to elevate your web development skills? </h3>
                        <div class="proj-portal cnt">
                            <p>Enroll in <strong>Zerobug Academy's AngularJS </strong> course today! Take the first step toward a successful career by mastering <strong>AngularJS</strong> with the  <strong> best training institute in  Pallikaranai, Velachery Chennai.</strong> Contact us now and embark on a transformative learning journey</p>

                        </div>
                    </div>
                </div>

                <div className="container-fluid margin-30" id='FAQ'>
                    <div className="row">
                        <div className="col-lg-7 FAQ">
                            <h2 className="mar-bot-top text-center heading-left text-left">
                                Frequently Asked Questions
                            </h2>
                            <div className="mb-5">
                                <AngularList />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="alumni-work mar-bot-top">
                                <h3>Our Alumni Work in Top MNC’S</h3>
                                <p>"Zerobug Academy has achieved remarkable success by placing thousands of students in leading roles across top multinational organizations."</p>
                                <div className="row">
                                    <div className="alumni-work-container">
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/DHL.png" decoding='async' loading='lazy' alt="Alumni Work 1" />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/tvs-credit.png" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/zoho-1-logo-png-transparent.png" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/freshworks.webp" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/larsen-and-toubro.jpg" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/HCLTECH.NS.png" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/centelon.png" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/INFY_BIG.png" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive h-width" src="./assets/banners/Hexaware.jpg" alt="Our Alumni Work in top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                <p>Join the success community to build your future </p><button onClick={openPopup} className='entrol-button'> Enroll Now</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default AngularCourse;