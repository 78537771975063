import React, { useState } from 'react';
import Dropdown from '../DropDown';

const ReactSyllabus= () => {
  // Define dropdown titles and options
  const dropdowns = [
   
    {
      title: '  JavaScript',
      options: ['Introduction', 'What is JavaScript?', 'JavaScript Features','JavaScript Libraries','Understanding HTML,CSS, JavcScript',
      'JavaScript VersionES5 ','ES6/ES2015,ES7/ES2016,ES8/ES2017','Data Types',' Functions','control statement','Ternary operator','variables'],
    },
    
    {
      title: ' React Introduction',
      options: ['What is React?', 'How This Course Works', 'Preparing for React'],
    },
    
    {
      title: 'What is React JS?',
      options: ['React JS Introduction', 'Advantages of React JS', 'Work flow of React JS','Scope of React JS','Introduction of Virtual DOM','Difference between JS and JSX',
      'React Components overview ','Containers and components','What is Child Components?','What is Namespace components?'],
      
    },
    
     {
      title: 'React JS Environment Setups',
      options: [ 'Node setup',' How to use NPM?','How to create package.json and purpose of it?','ES6 Introduction and features','Webpack Overview','Best IDE for React JS and How to write optimized code','React JS browser plugins overview'],   
      },
    {
      title: ' A Real-Time Application by using React JS',
      options: ['Create a React component with JSX template', 'How to create Nested Components?', 'What is React JS render?','React Props overview','Introduction of Props validation with data types.','Flow of States, Initialize state']
      
    },
    {
      title: 'React JS forms and UI',
      options: ['Lists of Form components', 'Setup Controlled and Uncontrolled form components', 'How to write Styles?','Animations overview',
      'Control Input elements.','How to set default values on all formats of Input elements','React Forms validation', 'Applying form components','Submit and Rest the form'],
    },
    {  
      title: 'React JS Component Life Cycles Overview ',
      options: ['Initial Render', 'CProps Change', ' Stage Change','  Component willMount ','Component didMount','Component Unmount'],
      
    },
    {
      title: 'Routing in React JS and Other JS concepts',
      options: ['Single Page Application Overview.', 'How to configure React Router?', ' History of Router','How to Handle Conditional statement in JSX?','IIFE in JSX for complex logic overview'],
        },
 
    {
      title: 'Event Handling in JSX & write Styles in React JS?',
      options: ['onBlur, onKeyUp, onChange and other useful primary events in React JS.','How to Sharing events between the components?','CSS and inline styles in ReactJS overview.','Introduction to styled components' ],
    },
  
    {
      title: 'React Router with Navigation',
      options: ['How to Load the router library?','Configure the React Router?','How to Pass and receive Parameters?','Integration of React-cookie overview.' ],
    },
    {
      title: 'Flux, Redux overview',
      options: ['What is Flux Architecture?','What are the Flux Components available?','How Flux works?','Flux and React works together','Introduction to One Store','Provider Component','Actions.','Reducers.','sagas','Dispatchers','View Controllers','Selectors' ],
    },
    {
      title: 'Unit Testing Overview',
      options: ['What are the necessary Tools required for Unit Testing?','React Unit Testing overview ','Introduction to JEST','How to Test React Component?','How to Test React Router?' ],
    },
    {
      title: 'Hooks',
      options: ['Understanding Hooks','The useState hook','Side effects using the useEffect hook','The useContext hook','The useReducer hook','Writing your own hook' ],
    },
  
  ];

  const [displayedDropdowns, setDisplayedDropdowns] = useState(dropdowns.slice(0, 6)); // Display 5 dropdowns initially
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setDisplayedDropdowns(showAll ? dropdowns.slice(0, 6) : dropdowns); // Toggle between showing 5 or all dropdowns
  };
  return (
    <div>
      {displayedDropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
      <button className='view-button' onClick={toggleShowAll}>{showAll ? 'Show Less' : 'Show More'}</button>
    </div>
  );
};
export default ReactSyllabus;
