import { useEffect } from 'react';

const TawkTo = () => {
  useEffect(() => {
    // Create a script element for the tawk.to widget
    const tawkToScript = document.createElement('script');
    tawkToScript.async = true;
    tawkToScript.src = 'https://embed.tawk.to/66065d551ec1082f04dca856/1hq4dp66b';
    tawkToScript.charset = 'UTF-8';
    tawkToScript.setAttribute('crossorigin', '*');

  
    document.head.appendChild(tawkToScript);

    
    return () => {
      document.head.removeChild(tawkToScript);
    };
  }, []); 

  
  return null;
};

export default TawkTo;
