import React, { useState } from 'react';
import Dropdown from './DropDown';

const  DropList= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: 'Introduction to Python',
      options: ['What’s python?', 'Why do people use python? ', 'Some quotable quotes','A python history lesson',' Advocacy news','What’s python good for?','What’s python not good for?','The compulsory features list','Python portability','On apples and oranges','Summary: why python?'],
    },
    
    {
      title: ' Using the Interpreter & Text Editor',
      options: ['Introduction to Sublime & Pycharm', 'Config Pycharm for Python Project', 'How Python Runs Programs','How You Run Program ','Configuration Details','Module Files: A First Look ','The Idle Interface','Other Python Ides','Time To Start Coding','Lab Session 1'],
    },
    
     {
      title: ' Types and Operators',
      options: [ 'A First Pass', ' The ‘Big Picture’','Numbers','Dynamic Typing Interlude','Strings','List','Dictionaries','Tuples','General Object Properties','Mutable vs Immutable','Summary: Python’s Type Hierarchies','Built-In Type Gotchas','Lab Session 2'],   
      },
    {
      title: 'Basic Statements',
      options: ['Introduction to Reserved Keywords', 'General Syntax Concepts', ' Expressions','  Print','If Selections',' Python Syntax Rules','Documentation Sources Interlude','Truth Tests','While Loops','Break, Continue, Pass, And The Loop Else','For Loops.']
      
    },
    {
      title: 'Functions',
      options: ['Function Basics', 'Module Files Are A Namespace', 'Name Qualification ','Import Variants','Reloading Modules','Package Imports',' Odds And Ends',' Module Design Concepts','Modules Are Objects:Metaprograms','function Gotchas',' Optional Case Study: set function','Lab Session 4'],
    },
    {  
      title: 'Modules ',
      options: ['Module Basics', 'Module Files Are A Namespace', 'Name Qualification',' Import Variants ','Reloading Modules','Package Imports','Odds And Ends','Module Design Concepts','Modules Are Objects:Metaprograms','module Gotchas',' Optional Case Study:A shared Stack Module','Lab Session 5'],
    },
    {
      title: 'Classes',
      options: [' Oop: The Big Picture', 'Class Basics', 'A More Realistic Example',' Using The Class Statement','Using Class Methods','Customization Via Inheritance','Specializing Inherited Methods','Operator Overloading In Classes','Namespace Rules: The Whole Story','Oop Examples: Inheritance And Composition',
      'Classes And Methods Are Objects','Odds And Ends','New Style Classes','Class Gotchas','Optional Case Study: A Set Class','Summary: Oop In Python','Lab Session 6'],
        },
    {
      title: 'Exceptions',
      options: [' Exception Basics', 'First Examples ', 'Exception Idioms ','Exception Catching Modes','Class Exceptions' ,'Exception Gotchas '],
    },
    {
      title: 'Built-in Tools Overview',
      options: ['The Secret Handshake','Debugging Options','Inspecting Name-Spaces','Dynamic Coding Tool','Timing And Profiling Python Programs','File Types And Packaging Options','Development Tools For Larger Projects','Summary: Python Tool-Set Layers','Lab Session 7 Continued'],
    },
    {
      title: 'System Interfaces & GUI Programmin',
      options: ['System Modules Overview', 'Running Shell Commands', 'Arguments, Streams,Shell Variables', 'File Tools ', 'Directory Tools ', ' Forking Processes  ', ' Thread Modules And Queues', ' The Subprocess And Multiprocessing Modules', 'Ipc Tools: Pipes, Sockets,Signals', 'Fork Versis Spawnv', 'Larger Examples', 'Lab Session 8', 
      'Python Gui Options','Introduction of Tkinter','The Tkinter ‘Hello World’Program',
      'Adding Buttons, Frames, AndCallbacks','Getting Input From A User','Assorted Tkinter Details','Building Guis By Subclassing Frames',' Reusing Guis By Subclassing And Attaching','Advanced Widgets: Images,Grids, And More','Designing UI using QT4Designer','Introduction to PySide2','Converting UI into Application Using PySide'],
    },
    {
      title: 'Text Processing & Internet Scripting',
      options: ['String Objects: Review ', 'Regular Expressions', 'Splitting And Joining Strings','Parsing Languages',
      'Lab Session 10','Using Sockets In Python','The Ftp Module','Email Processing','Other Client-Side Tools','Building Web Sites With Python','Writing Server-Side Cgi Scripts','Jython: Python For Java Systems','Active Scripting And Com','Other Internet-Related Tools'],
    },
     {
      title: 'Databases and Persistence',
      options: [' Databases and Persistence', 'Object Persistence: Shelves', 'Storing Class Instances','Pickling Objects Without','Using Simple Dbm Files','Shelve Gotchas','Zodb Object-Oriented Database',' Python Sql Database Api','Persistence Odds And Ends ','Lab Session 9']
    },
    {
      title: 'Advanced Topics',
      options: ['Unicode Text And Binary Data ', 'Managed Attributes ', 'Decorators','Metaclasses','Context Managers','Python 3.X Changes','Lab Session 12'],
    }, 
  
  
  ];
  const [displayedDropdowns, setDisplayedDropdowns] = useState(dropdowns.slice(0, 6)); // Display 5 dropdowns initially
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setDisplayedDropdowns(showAll ? dropdowns.slice(0, 6) : dropdowns); // Toggle between showing 5 or all dropdowns
  };
  return (
    <div>
    {displayedDropdowns.map((dropdown, index) => (
      <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
    ))}
    {dropdowns.length > 6 && (
      <button className='view-button' onClick={toggleShowAll}>{showAll ? 'View Less' : 'View More'}</button>
    )}
  </div>
      
  );
};

export default DropList;
