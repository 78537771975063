import React from 'react';
import Dropdown from './DropDown';

const HomeList= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: '1.Full Stack Developers:',
      options: ['Master the art of Full Stack Development and unlock a realm of possibilities. Build robust applications from front to back-end, making you an invaluable asset to any tech team.'],
    },
    
    {
      title: '2. Data Science Professionals:',
      options: ['Dive deep into the world of data with our Data Science Mastery course. Acquire the skills to uncover insights, analyze trends, and drive data-driven decisions in diverse industries.'],
    },
    
     {
      title: '3. Digital Marketing Experts:',
      options: [ '- Navigate the online landscape with finesse! Our Digital Marketing Pro course equips you with the tools to excel in the dynamic world of online marketing, opening doors to exciting career paths.'],   
      },
    {
      title: '4. Cloud Computing Specialists:',
      options: ['   Elevate your tech game with our Cloud Computing Excellence course. Learn to harness the power of cloud technologies, a skill highly sought after in todays digital era.']
      
    },
    {
        title: '5. Cybersecurity Professionals:',
        options: ['Guard the digital frontier with our Cybersecurity Essentials course. Acquire the skills to protect digital assets, making you an integral part of the cybersecurity landscape.']
        
      },
    
   
  
  ];

  return (
    <div>
      {dropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
    </div>
  );
};

export default HomeList;
