import React from 'react';
import Dropdown from '../DropDown';

const  AngularList= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: '1.What is AngularJS, and why should I learn it?',
      options: ['AngularJS is a front-end web application framework developed by Google, designed to simplify the process of building dynamic and robust web applications. Learning AngularJS is beneficial for developers as it enhances their ability to create interactive and efficient user interfaces.'],
    },
    
    {
      title: ' 2.Is prior programming experience necessary for the AngularJS course?',
      options: [' While prior programming experience is helpful, our AngularJS course caters to both beginners and experienced developers. We provide comprehensive learning materials suitable for individuals with varying levels of coding expertise.'],
    },
    
     {
      title: '3.How long is the duration of the AngularJS course, and can I choose a flexible schedule?',
      options: [ 'The duration of the AngularJS course at Zerobug Academy is [insert duration]. Yes, we offer flexible schedules to accommodate diverse needs, ensuring that you can pursue the course without compromising your other commitments.'],   
      },
    {
      title: '4.How are practical skills emphasized in the AngularJS course?',
      options: ['Practical skills are a core focus of our AngularJS course. We emphasize hands-on learning through real-world projects, allowing students to apply theoretical knowledge in practical scenarios. This approach ensures a holistic understanding of AngularJS concepts.']
      
    },
    
     
    {  
      title: '5.What sets Zerobug Academy apart as the best AngularJS training institute in Chennai?',
      options: ['Zerobug Academy stands out due to its expert faculty, practical approach to learning, interactive sessions, industry-relevant curriculum, placement assistance, and flexible timings. These factors collectively contribute to making us the best AngularJS training institute in Chennai'],
    },
  
  
  ];

  return (
    <div>
      {dropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
    </div>
  );
};

export default AngularList;
