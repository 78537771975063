import React, { useEffect, Fragment, useState } from 'react';
import Menu from './menu';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const Python = () => {
    useEffect(() => {
        document.title = 'Highest Paying Job in 2024';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse" style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" style={{ color: 'red' }} >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid    page-blog" >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsappButton />

            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h1" style={{ textAlign: "center", marginBottom: "30px" }}>
                                    Top 10 Highest Paying Jobs in 2024</h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>As the industries are evolving dynamically, Professionals in various roles play vital parts in driving innovation, growth, efficiency, and security. These professionals gain the highest payrolls that offer rewarding career paths. It is important to acquire specialized skills for lucrative job opportunities.
                                            Let’s explore the top 10 highest paying jobs that are shaping the industry.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Data Scientist</h4>
                                        <p>In this data driven world, the demand of data scientists increases to make smarter data decisions. Organization uses data science to experience financial growth in various domains. Data Scientists have the ability to understand data and make effective decisions. They analyze complex datasets to extract valuable data insights.
                                            The Average salary for a Data Scientist job role is 14.6 Lakhs per year in India.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Cloud Architect</h4>
                                        <p>As companies prefer the cloud computing platforms like AWS, Azure, and Google Cloud, the job role of cloud architects becomes essential. These professionals are responsible for managing the cloud computing system. They are responsible for designing, implementing and managing the cloud architecture.
                                            According to a Glassdoor report, the average salary for Cloud Architect is ₹21,59,316 per year in India.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>As the industries are evolving dynamically, Professionals in various roles play vital parts in driving innovation, growth, efficiency, and security. These professionals gain the highest payrolls that offer rewarding career paths. It is important to acquire specialized skills for lucrative job opportunities.
                                            Let’s explore the top 10 highest paying jobs that are shaping the industry.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Full Stack Developer</h4>
                                        <p>To complete the application independently, Full Stack Developers are emerging experts who have a diverse skill set. They have proficiency in both front-end back-end development and database as well. Full Stack Developers have the ability to create complete implementation of solutions that makes them valuable assets.
                                            The Average salary for a Full Stack Developer in India is 9.4 Lakhs per year.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Solutions Architect</h4>
                                        <p>Solutions architects are highly valuable with increased demand in the IT field. They have the ability to design effective technical solutions. Solutions architects understand the business goals to achieve the success of the organization.
                                            The average salary for a Solution Architect job role is ₹26 lakhs per annum in India.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Project Manager(PMP)</h4>
                                        <p>Project Manager is one among the list of highest paying jobs in India. With PMP Certification, They gain global recognition that offers a fulfilling career path. Project Manager guides projects from planning, execution and management.
                                            They are responsible for successful project outcomes. The average salary for Project Manager is ₹13.5 Lakhs per year.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Salesforce Developer</h4>
                                        <p>In this Cloud computing era, Salesforce developers are in demand with the power of Salesforce - leading customer relationship management (CRM) platform. These professionals design, develop, and implement solutions that empower organizations. They have in-built knowledge of Salesforce who optimize business processes and enhance user experience.
                                            According to Glassdoor report, The average salary for a Salesforce Developer is ₹6,00000 per annum in India.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Business Intelligence Analyst(Power BI)</h4>
                                        <p>A Business Intelligence Analyst job role with Power BI skills is crucial for an organization to make informed decisions. They possess the ability to transform large datasets into meaningful insights that aid decision making. BI analysts generate interactive dashboards, comprehensive reports and trend analysis. empowers businesses to navigate complexities, seize opportunities, and stay ahead in today's competitive landscape.
                                            The Salary of Business Intelligence Analyst ranges between ₹ 3.1 Lakhs to ₹ 17.0 Lakhs in India.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Planning Engineer(Primavera)</h4>
                                        <p>In project management, Planning Engineer Job Role equipped with Primavera skills holds a critical position that orchestrates project success. Primavera P6 skills contribute to overall efficiency using effective planning, execution and monitoring of projects. They build project schedules, monitor project progress and manage critical activities.
                                            The Average salary for a Planning Engineer job role in India is 6.3 Lakhs per year.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>React JS Developer</h4>
                                        <p>In the development of modern web applications, React.js developers play an important role in building user interfaces. They have the ability to build efficient user interfaces using react components. React JS developers translate the design needs into responsive user interfaces with optimized performance. The average salary for a ReactJS Developer job is ₹5,29,398 per year in India.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Selenium Automation Tester</h4>
                                        <p>As the demand of Automation testing increases, Selenium Automation Tester is the key role who automates the testing of web applications. They ensure quality, reliability, functionality and performance of web applications. Selenium framework offers a suite of tools for writing test scripts, executing automated tests, organizing test cases and generating test reports.
                                            The Salary of Selenium Automation Tester job in India ranges between ₹ 3.4 to ₹ 14.0 Lakhs.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Conclusion</h4>
                                        <p>As trending technology continues to shape our world, the demand for professionals with specialized skills increases. These professionals gains the highest paid jobs in various emerging fields. To acquire the best paid jobs, Gain the essential skills and knowledge.
                                            Lead the Successful Career path with higher compensation packages.</p>


                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default Python;