import React from 'react';
import Dropdown from '../DropDown';

const  DjangoList= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: '1.Is this course suitable for beginners?',
      options: ['Absolutely! Our course is designed to cater to both beginners and experienced developers, ensuring a magical learning experience for all.'],
    },
    
    {
      title: ' 2.What makes Zerobug Academy stand out?',
      options: ['Our academy stands out for its experienced trainers, hands-on projects, and the enchanting infrastructure that fuels your magical journey.'],
    },
    
     {
      title: '3.Are there placement opportunities postcourse completion?',
      options: [ 'Indeed, Zerobug Academy offers placement assistance to guide you to the magical realms of your dream career'],   
      },
    {
      title: '4.Can I attend classes remotely?',
      options: ['Yes, whether youre in Pallikaranai, Velachery, or nearby in Chennai, you can choose between in-person and online classes, allowing for flexible learning.']
      
    },
    
     
    {  
      title: '5.Is financial aid available?',
      options: [' Zerobug Academy offers various payment plans and financial aid options to make your magical learning journey accessible to all.'],
    },
  
  
  ];

  return (
    <div>
      {dropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
    </div>
  );
};

export default DjangoList;
