import React, { useEffect , useRef } from 'react';
import { Link } from 'react-router-dom';




const CarouselComponent = () => {
    const carouselRef = useRef(null);

    useEffect(() => {
        const carousel = carouselRef.current;

        const intervalId = setInterval(() => {
            window.$(carousel).carousel('next'); 
        }, 5000); 

     
        return () => clearInterval(intervalId);
    }, []);
   

    return (
        <div className="container-fluid p-0 pb-5 ">
            <div id="header-carousel" className="carousel slide carousel-fade" data-ride="carousel" ref={carouselRef}>
                <ol className="carousel-indicators">
                    <li data-target="#header-carousel" data-slide-to="0" className="active"></li>
                    <li data-target="#header-carousel" data-slide-to="1"></li>
                    <li data-target="#header-carousel" data-slide-to="2"></li>
                   
                </ol>
                <div className="carousel-inner">
                <div className="carousel-item active" style={{minHeight: "300px"}}>
                    <img className="position-relative w-100" src="assets/img/carousel-1.jpg" alt='Best Online Courses'  style={{minHeight: "300px",maxHeight:"600px", objectFit: "cover"}}/>
                    <div className="carousel-caption d-flex align-items-center justify-content-center">
                        <div className="p-5" style={{width: "100%", maxWidth: "900px"}}>
                            <h5 className="text-white text-uppercase mb-md-3">Best Online Courses</h5>
                            <h1 className="display-3 text-white mb-md-4">Best Education From Your Home</h1>
                            <Link to="/contact"  className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">Learn More</Link>
                           
                        </div>
                    </div>
                </div>
                <div className="carousel-item" style={{minHeight: "300px"}}>
                    <img className="position-relative w-100" src="assets/img/carousel-2.jpg" alt='Best Online Courses' style={{minHeight: "300px",maxHeight:"600px", objectFit: "cover"}}/>
                    <div className="carousel-caption d-flex align-items-center justify-content-center">
                        <div className="p-5" style={{width: "100%", maxWidth: "900px"}}>
                            <h5 className="text-white text-uppercase mb-md-3">Best Online Courses</h5>
                            <h1 className="display-3 text-white mb-md-4">Best Online Learning Platform</h1>
                            <Link to="/contact" className="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">Learn More</Link>
                        
                        </div>
                    </div>
                </div>
                <div className="carousel-item" style={{minHeight: "300px"}}>
                    <img className="position-relative w-100" src="assets/img/carousel-3.jpg" alt='Best Online Courses' style={{minHeight: "300px",maxHeight:"600px", objectFit: "cover"}}/>
                    <div className="carousel-caption d-flex align-items-center justify-content-center">
                        <div className="p-5" style={{width: "100%", maxWidth: "900px"}}>
                            <h5 className="text-white text-uppercase mb-md-3">Best Online Courses</h5>
                            <h1 className="display-3 text-white mb-md-4">New Way To Learn From Home</h1>
                            <Link to="/contact" class="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2">Learn More</Link>
                         
                        </div>
                    </div>
                </div>
                </div>
               
                
            </div>
        </div>
    );
};

export default CarouselComponent;
