import React, { useState, useEffect } from 'react';
import './EnquiryForm.css';
import emailjs from 'emailjs-com';

const PopupForm = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    course: ''
  });
  const [errors, setErrors] = useState({});

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    // Validate name (only letters allowed)
    if (name === 'name' && !/^[a-zA-Z]+$/.test(value)) {
      newErrors[name] = 'Name should only contain letters';
    } else {
      newErrors[name] = '';
    }

    // Validate phone number (must be 10 characters)
    if (name === 'phone' && value.length !== 10) {
      newErrors[name] = 'Phone number must be exactly 10 characters';
    }

    setFormData({ ...formData, [name]: value });
    setErrors(newErrors);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate name (only letters allowed)
    if (!formData.name.trim() || !/^[a-zA-Z]+$/.test(formData.name)) {
      newErrors.name = 'Name should only contain letters';
      valid = false;
    }

    // Validate phone number (must be 10 characters)
    if (formData.phone.trim().length !== 10) {
      newErrors.phone = 'Phone number must be exactly 10 characters';
      valid = false;
    }

    // Validate email
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    // Validate course
    if (!formData.course.trim()) {
      newErrors.course = 'Course selection is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
        .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
          closePopup();
        })
        .catch((error) => {
          console.error('Error sending message:', error);
          alert('Failed to send message.');
        });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPopupVisible(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {isPopupVisible && (
        <div className="popup-form">
          <div className="popup-content">
            <span className="close" onClick={closePopup}>&times;</span>
            <h4>Request more Details</h4>
            <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control border-0 p-4 ${errors.name && 'is-invalid'}`}
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
               
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className={`form-control border-0 p-4 ${errors.phone && 'is-invalid'}`}
                  placeholder="Phone No"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
               
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className={`form-control border-0 p-4 ${errors.email && 'is-invalid'}`}
                  placeholder="Your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
             
              </div>
              <div className="form-group">
                <select
                  className={`custom-select border-0 px-4 ${errors.course && 'is-invalid'}`}
                  style={{ height: "47px" }}
                  name="course"
                  value={formData.course}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select a course</option>
                  <option value="Python">Python</option>
                  <option value="Angular">Angular</option>
                  <option value="Django">Django</option>
                  <option value="React">React</option>
                  <option value="Selenium">Selenium</option>
                </select>
                
              </div>
              <div>
                <button className="btn btn-primary" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupForm;
