import React from 'react';
import Dropdown from '../DropDown';

const  ReactList= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: '1.Is prior programming experience necessary for enrolling in the React JS course at Zerobug Academy?',
      options: [' While prior programming experience is beneficial, it is not mandatory. The React JS course at Zerobug Academy is designed to cater to both beginners and experienced developers. Basic knowledge of HTML, CSS, and JavaScript, however, is recommended for a smoother learning experience.'],
    },
    
    {
      title: ' 2.What is the duration of the React JS course, and can I choose a flexible schedule?',
      options: [' The duration of our Python course varies based on the program you choose. We understand the importance of flexibility, and hence, we provide options for weekday and weekend batches. Our flexible schedules cater to both working professionals and students.'],
    },
    
     {
      title: '3.How are practical skills emphasized in the React JS course?',
      options: [ '  Practical skills are a core focus of our React JS course. Throughout the program, students engage in hands-on projects, allowing them to apply theoretical knowledge to real-world scenarios.'],   
      },
    {
      title: '4.Does Zerobug Academy provide placement assistance after completing the React JS course?',
      options: ['Yes, Zerobug Academy is committed to supporting students in their career journeys. We offer comprehensive placement assistance, including resume building, interview preparation, and connecting students with potential employers. Our goal is to help you kickstart your career in React JS development.']
      
    },
    
     
    {  
      title: '5.Can I attend a demo session before enrolling in the React JS course?',
      options: ['Absolutely! We encourage prospective students to attend demo sessions to get a firsthand experience of our teaching methodology, course content, and the learning environment.  '],
    },
  
  
  ];

  return (
    <div>
      {dropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
    </div>
  );
};

export default ReactList;
