import React, { useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Menu from '../menu';
import PopupForm from '../PopupForm'
import WhatsAppButton from '../WhatsappButton';
import Footer from '../Footer';


const Seo = () => {
   
        useEffect(() => {
            document.title = 'Best Seo Marketing Training in chennai|Zerobug Academy';
            return () => {
              document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy'; 
            };
          }, []);
    return (
        <Fragment>
            <Menu />
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse" href="#navbar-vertical" style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>

                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                        <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>
                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#" style={{ color: "darkorange" }}>Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                        <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* /*  <!-- Carousel Start -->*/}
            <div className="container-fluid page-digitals " >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "300px"}}>
                        <h3 className="display-4 text-white text-uppercase"></h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" ></a></p>

                            <p className="m-0 text-uppercase"></p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Carousel End -->  */}
            <div className="container-fluid ">
                <div className="container pt-5 pb-3">
                    <h1 className='text-primary h1'>Why should you prefer Zerobug Academy ?</h1>
                    <div className="row">

                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className=" position-relative ">
                                <img src="../assets/img/Project-Delivered.png" style={{ width: '250px' }} alt="seo" />

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className=" position-relative ">
                                <img src="../assets/img/Industry-Covered.png" style={{ width: '250px' }} alt="seo" />

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 ">
                            <div className=" position-relative ">
                                <img src="../assets/img/Country-Client-Base.png" style={{ width: '250px' }} alt="seo" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="position-relative ">
                                <img src="../assets/img/sucess-zerobug.png" style={{ width: '250px' }} alt="seo" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <WhatsAppButton />
            <PopupForm />
            {/*  <!-- Category Start --> */}
            {/* <!-- Registration Start --> */}
            <div className="container-fluid  ">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-lg-7 mb-5 mb-lg-0">
                            <div className="mb-4">

                                <h1 className="text-primary h1">Digital Marketing Services</h1>
                            </div>
                            <p className='text-justify'>As a leading Digital Marketing Company, Reontek is your friend in your business. With its services like <strong>SEO, SMO, SMM, PPC Advertising and Content Marketing, Reontek's Digital Marketing</strong>  India helps you to create a niche in your industry and grow your business with huge web traffic and customer responses.</p>
                            <p className='text-justify'>Our offered <strong> Digital Marketing Services</strong> in Delhi, India are tailored as per your need and cost-effective.</p>

                        </div>
                        <div className="col-lg-5">
                            <div className="card border-0">
                                <img src="../assets/img/digital-marketing.png" alt="Digital Marketing" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="card border-0">
                                <img src="../assets/img/seo-zerobug.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7 mb-5 mb-lg-0">
                            <div className="mb-4">

                                <h3 className="text-primary">Search Engine Optimization (SEO) Services</h3>
                            </div>
                            <p className='text-justify'>You know it is not enough to get a website and show your business to the world. As a business owner, you need to make your business website search friendly so that it can appear on search engines when a person searches for a service or product you deal in.
                                You can do this applying the right <strong>SEO</strong>, Search Engine Optimization, techniques.</p>

                        </div>

                    </div>
                </div>
            </div>
            {/*  <!-- Registration End --> */}

            <div className="container-fluid " >
                <div className="container ">
                    <h3 className='text-primary'>How Do We Deliver?</h3>
                    <div className="d-flex flex-column justify-content-center banner-size" >
                        <img src="../assets/img/zerobug_how_we_work.png" alt="Digital Marketing" />
                    </div>
                </div>
            </div>
            <div className="container-fluid ">
                <div className="container pt-5 pb-3">
                    <h1 className='text-primary h1'>Why should you prefer Zerobug Academy ?</h1>
                    <p>We Analysis and researching your requirements and using testing methodologies to ensure consistent project delivery which are part of the actual project work effort.</p>
                    <div className="row">

                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className=" position-relative ">
                                <img src="../assets/img/cm.png" style={{ width: '250px' }} alt="Digital Marketing" />

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className=" position-relative ">
                                <img src="../assets/img/smo1.png" style={{ width: '250px' }} alt="Digital Marketing" />

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 ">
                            <div className=" position-relative ">
                                <img src="../assets/img/Responsive-Website-Design.png" style={{ width: '250px' }} alt="Digital Marketing" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="position-relative ">
                                <img src="../assets/img/ppclick-zerobug.png" style={{ width: '250px' }} alt="Digital Marketing" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer />

        </Fragment>
    )
}

export default Seo;