import React, { Fragment, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';


const BottomMenu = () => {
    const navRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current) {
        if (window.scrollY > navRef.current.offsetTop) {
          navRef.current.classList.add('sticky'); // Add 'sticky' class when scrolled
        } else {
          navRef.current.classList.remove('sticky'); // Remove 'sticky' class when not scrolled
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
     {/* <!-- Navbar Start --> */}
     <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} ref={navRef}>
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/">Home</Link></li>

                                <li>

                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle" >Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#" style={{ color: "darkorange" }}>Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}


    </Fragment>
  )
}

export default BottomMenu;