import React, { useState, useEffect, Fragment } from 'react';
import Menu from "../menu";
import { Link } from 'react-router-dom';
import SubNav from '../SubNav';
import Footer from '../Footer';
import BottomMenu from '../BottomMenu';
import ReactSyllabus from './ReactSyllabus';
import ReactList from './ReactList';
import './React.css';
import TawkTo from '../TawkTo';
import WhatsappButton from '../WhatsappButton';
import PopupForm from '../PopupForm';
import emailjs from 'emailjs-com';
const PDF_FILE_URL = "assets/syllabus/React-js-Course-Content.pdf";
/* import DropList from '../DropList'; */
const ReactCourse = () => {
  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  };
  useEffect(() => {
    document.title = 'React JS Training at Zerobug Academy, Velachery Chennai';
    return () => {
      document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy';
    };
  }, []);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    course: ''
  });
  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation
    if (!formData.name || !formData.phone || !formData.email || !formData.course) {
      alert('Please fill in all fields.');
      return;
    }

    // Name validation
    const namePattern = /^[A-Za-z]+$/;
    if (!namePattern.test(formData.name)) {
      alert('Please enter a valid name .');
      return;
    }

    // Phone number validation
    const phonePattern = /^\d{10}$/;
    if (!phonePattern.test(formData.phone)) {
      alert(' invalid phone number.');
      return;
    }

    // Email validation (optional)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      alert('invalid email address.');
      return;
    }
    // Submit the form if validation passes
    emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
        setFormData({
          name: '',
          phone: '',
          email: '',
          course: ''
        });
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        alert('Failed to send message.');
      });
  };

  return (
    <Fragment>
      <Menu />
      {/* <!-- Navbar Start --> */}
      <BottomMenu />
      {/* <!-- Navbar End --> */}
      {/*     <!-- Header Start --> */}
      <div className="container-fluid banner-background ">
        <div className="container">
          <div className="row align-items-center margin-30">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <h1 className="text-white text-start text-uppercase">
                REACTJS training in chennai
              </h1>
              <div className="rating-wrap">
                <span className="ratingVal">49</span>
                <img src="../assets/img/R.png" alt="image" loading='lazy'/>
                <span className="learnerTxt">6K+ Satisfied Learners</span>
              </div>
              <p className='text text-white'>Master React JS at Zerobug Academy – Your Premier Destination for Excellence in Frontend Development! Enhance your skills with top-notch React JS training in Velachery, Chennai. Our expert-led courses cover MVC, SPA, JavaScript libraries, React Native, and more. Get hands-on experience and placement support. Enroll now for the best React JS training online!.</p>


            </div>

            <div className="col-lg-6">
              <div className="image-card  border-0">
                <img src="./assets/banners/react-iamge-banner.png" alt="Best reactjs training in chennai" decoding='async' loading='lazy'/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <!-- Header End --> */}
      <div className="courseNav  d-sm-block" >
        <div className='container' >
          <div className=" courseNav-wrap">

            <SubNav />
          </div>
        </div>
      </div>

      <div className='container'>
        {/* <!-- Detail Start --> */}
        <div className="container-fluid  body-align-text">
          <div className="container " id='content'>
            <div className="row">

              <div class="col-lg-12 instruc-wrap1" id="OurInstructors">

                <h2 >Why React JS?</h2>
                <div class=" cnt">

                  <div class="tick">
                    <p className='justify-text'>
                      The <strong>React JS course at Zerobug Academy</strong> is your key to mastering one of the most sought-after technologies in web development.
                      <strong> React JS, developed by Facebook,</strong> is renowned for its efficiency and flexibility in building dynamic user interfaces.
                      With our course, you'll gain hands-on experience in creating responsive applications using React's component-based
                      architecture and virtual DOM.
                    </p>


                  </div>
                </div>
              </div>
              <TawkTo />
              <WhatsappButton />
              <PopupForm />


            </div>
          </div>
        </div>
      </div>
      {/* <!-- Detail End --> */}

      {/*  course-batch */}
      <div class="container-fluid features-UpcomingBatch py-5" id='up-coming-batch'>
        <div class="features-courseSchedule">
          <div class="row wrap">
            <div class="col-lg-9 container">
              <h3 class='float-left h1 text-center'>Upcoming React Training Chennai </h3>
            </div>
            <div className="batch-table-wrap col-sm-12 col-lg-921">
              <div className='schdule-wrap'>
                <div className='dateVal'>
                  <span className=''>jan 05</span>
                </div>

                <div className='batchType'>
                  <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                  <span class="batchLocation">
                    <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                  </span>
                </div>
                <div className='availbility-cnt'>
                  <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                </div>
              </div>
              <div className='schdule-wrap'>
                <div className='dateVal'>
                  <span className=''>Feb 05</span>
                </div>

                <div className='batchType'>
                  <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                  <span class="batchLocation">
                    <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                  </span>
                </div>
                <div className='availbility-cnt'>
                  <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                </div>
              </div>
              <div className='schdule-wrap'>
                <div className='dateVal'>
                  <span className=''>Mar 05</span>
                </div>

                <div className='batchType'>
                  <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                  <span class="batchLocation">
                    <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                  </span>
                </div>
                <div className='availbility-cnt'>
                  <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                </div>
              </div>
              <div className='schdule-wrap'>
                <div className='dateVal'>
                  <span className=''>Apr 05</span>
                </div>

                <div className='batchType'>
                  <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                  <span class="batchLocation">
                    <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                  </span>
                </div>
                <div className='availbility-cnt'>
                  <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                </div>
              </div>


            </div>
            <div class=''>
              <div className='talk2Us-Promo new-Promo-Class '>
                <div className='heading-promo'>
                  <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                  talk to us
                </div>
                <div className='promo-content'>
                  <h7 className="title"> Online and Classroom Training</h7>
                </div>
                <div className='mrg-top5'>
                  <i className='fa fa-mobile-phone'></i>+91-9750061584
                </div>
                <div className='mrg-top5'>
                  <i className='fa fa-mobile-phone'></i>+91-8946004709
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* reaqust call */}
      <div className="container-fluid courseReqBatch">
        <div className='features-ReqBatch'>
          <div className=' col-lg-12 col-md-12 reqBatch-wrap'>
            <img src='../assets/img/date.png' alt="Date Icon" />
            <div className='reqBatch-content'>
              <p>Can’t find a batch you were looking for?</p>
              <button className="reqBatch-btn " onClick={openPopup}> REQUEST A BATCH</button>
              {isPopupVisible && (
                <div className="popup-form">
                  <div className="popup-content">
                    <span className="close" onClick={closePopup}>&times;</span>
                    <h2>Enquiry Now</h2>
                    <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-0 p-4"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-0 p-4"
                          placeholder="Phone No"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control border-0 p-4"
                          placeholder="Your email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="custom-select border-0 px-4 height"

                          name="course"
                          value={formData.course}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select a course</option>
                          <option value="python">Python</option>
                          <option value="django">Django </option>
                          <option value="reactjs">ReactJS</option>
                          <option value="angular">AngularJS</option>
                          <option value="selenium">Selenium </option>
                          {/* Add other options here */}
                        </select>
                      </div>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          Send Message
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
      {/*  <!-- Detail Start2 --> */}
      <div className="container">
        <div className="container-fluid margin-30" >
          <div className="container ">
            <div className="row">

              <div className="col-lg-8 FAQ">
                <h3 class="text-center heading-left text-left " >
                  ReactJs Course Curriculum  </h3>
                <p className='text-align-syllabus'>"Embark on a journey of React mastery with our comprehensive course. Learn to build interactive user interfaces efficiently using React's component-based architecture. Dive into state management, routing, and advanced techniques, empowering you to create modern web applications with ease."</p>
                <button onClick={() => { downloadFileAtURL(PDF_FILE_URL) }} className='download-buttom'>Download-Curriculum</button>
                <div className="mb-5">
                  <ReactSyllabus />

                </div>
              </div>

              <div class="col-lg-4 mt-5 mt-lg-0">
                {/*  <!-- Author Bio --> */}
                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                  <div className="card-header demo-form text-center p-4 " >
                    <h5 className="m-0">Requst Free Demo</h5>
                  </div>
                  <div className="card-body rounded-bottom whitesmoke ">
                    <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-0 p-4"
                          placeholder="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control border-0 p-4"
                          placeholder="Phone No"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control border-0 p-4"
                          placeholder="Your email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="custom-select border-0 px-4 height"

                          name="course"
                          value={formData.course}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select a course</option>
                          <option value="python">Python</option>
                          <option value="django">Django </option>
                          <option value="reactjs">ReactJS</option>
                          <option value="angular">AngularJS</option>
                          <option value="selenium">Selenium </option>
                          {/* Add other options here */}
                        </select>
                      </div>
                      <div>
                        <button className="btn  btn-block border-0 py-3 demo-form text-white" type="submit">Submit</button>
                      </div>
                    </form>
                  </div>

                </div>

                <div class="mb-5">
                  <div class="promo-cnt whiteBG courseHglhts-wrap">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="courseHglhts pdg-btm20">
                          <div class="circleStyle black-color"> 60+</div>
                          <span> Hours of Practical Training </span>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="courseHglhts pdg-btm20">
                          <div class="circleStyle black-color" > 60+</div>
                          <span>Case Studies and Projects </span>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="courseHglhts pdg-btm20">
                          <div class="circleStyle black-color">24/7</div>
                          <span>Expert Support</span>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="courseHglhts pdg-btm20">
                          <div class="circleStyle black-color" >100%</div><span> Placement Assurence </span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid key-module-bg' id='project1'>

        <div class=" features-courseProject container">
          <div class="row" >
            <div class="col-lg-12">
              <h2 class=" mar-bot text-white" >
                Benefits of Learning React JS</h2>
            </div>

            <div class="key-modules-container  margin-mainus-30">
              <div class="key-module ">
            
                <h3>Versatility and Innovation</h3>
                <p>React JS stands out for its versatility in building user interfaces. By adopting a component-based architecture, React enables developers to create reusable and modular components.</p>
              </div>
              <div class="key-module">
             
                <h3>Efficiency with Virtual DOM</h3>
                <p>React's Virtual DOM(Document Object Model) is a game-changer in terms of performance. By minimizing the need to directly manipulate the actual DOM, React enhances rendering speed and overall application efficiency.</p>
              </div>
              <div class="key-module">
             
                <h3>Rapid Development with JSX</h3>
                <p>The integration of JSX (JavaScript XML) in React simplifies the process of writing components. JSX allows developers to write UI components using a syntax that closely resembles HTML, making the code more readable and intuitive.</p>
              </div>

            </div>
            <div class="key-modules-container ">
              <div class="key-module">
              
                <h3>Responsive User Interfaces</h3>
                <p>React facilitates the creation of highly responsive and interactive user interfaces. The ability to update and render components efficiently ensures a seamless user experience, even in applications with dynamic content.</p>
              </div>
              <div class="key-module">
              
                <h3>Scalability and Performance</h3>
                <p>   React is highly scalable, making it ideal for building large-scale applications. It efficiently handles complex UIs with its virtual DOM, which minimizes direct DOM manipulation and improves performance.</p>
              </div>
              <div class="key-module">
             
                <h3>Community Support</h3>
                <p>React JS boasts a vibrant and active community of developers worldwide. Leveraging the power of this community provides an invaluable resource for learning, problem-solving, and staying updated on the latest trends and best practices</p>
              </div>

            </div>
          </div>

        </div>
      </div>
   

      <div className='container-fluid training-modes-wrap' >
                <div className=' container-fluid courseReqBatch training-modes-wrap'>
                    <div className="col-lg-12 col-md-12  mrg-top20">

                        <div class="row ">
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob7.jpg" alt="classroom training" width="100%" height="100%" loading='lazy'></img>
                                    </div>
                                    <h3>Classroom Training</h3>
                                    <div className="training-mode-cnt">
                                        <span >Instructor Led Classroom Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 5.jpg" alt="online course" width="100%" height="100%" loading='lazy'></img>
                                    </div>
                                    <h3 >Online Training</h3>
                                    <div className="training-mode-cnt">
                                        <span>Instructor Led Online Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 4.jpg" alt="best trining" width="100%" height="100%" loading='lazy'></img>
                                    </div>
                                    <h3> Corporate Training</h3>
                                    <div className="training-mode-cnt">
                                        <span >Instructor Led Corporate Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/bobl36.jpg" alt="image" width="100%" height="100%" loading='lazy'></img>
                                    </div>
                                    <h3> One to One Training</h3>
                                    <div className="training-mode-cnt">
                                        <span >best training based on requirement</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <div className='container-fluid  home-need-bg'>
                <div className="container ">

                    <div className=' CourseFeatures home-need-bg'>
                        <div className='features-Coursefeatures'>
                            <div className="row text-center" >
                                <div className="col-lg-12 mar-bot-top " >
                                    <h2>Why Choose Zerobug Academy for ReactJs Courses?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj'>
                                        <img decoding="async" src="../assets/img/blob.jpg" alt="reactjs Real Time projects" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4> Expert Faculty</h4>
                                            <p className='text-center'>At Zerobug Academy, our React JS course is guided by a team of industry experts and experienced instructors dedicated to your success. Benefit from their practical insights, real-world experience, and commitment to nurturing your skills in React JS development.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-green'>
                                        <img decoding="async" loading='lazy' src="../assets/img/cloud-computing.png" style={{ color: 'orangered' }} alt="reactjs Real Time projects" width="20%" height="20%"></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4>Practical Approach</h4>
                                            <p className='text-center'>We believe in learning by doing. Our curriculum emphasizes a practical approach, allowing you to apply theoretical knowledge through hands-on projects and real-world examples. This practical exposure ensures a deeper understanding and proficiency in ReactJS development</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-voilet'>
                                        <img src="../assets/img/blob1.jpg" alt="reactjs Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4>Interactive Learning</h4>
                                            <p className=" text-center">Engage in interactive sessions, discussions, and collaborative learning environments. Zerobug Academy fosters a dynamic atmosphere where students actively participate in the learning process. This interactive approach promotes a deeper understanding of ReactJS concepts and encourages knowledge sharing among peers.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-blue'>
                                        <img decoding="async" src="../assets/img/OIP (3).jpg" alt="reactjs Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4 >Industry-Relevant Curriculum</h4>
                                            <p className='text-center'>Stay ahead of industry trends with our carefully crafted and constantly updated curriculum. We prioritize teaching the latest advancements and best practices in React JS development, ensuring that you graduate with skills that are relevant and in demand in the ever-evolving tech landscape</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top">
                                    <div className='keyFeatures-wrap proj-yellow'>
                                        <img decoding="async" src="../assets/img/blob2.jpg" alt="reactjs Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4>Placement Assistance</h4>
                                            <p className='text-center'> As the <strong>Best Institute in Pallikaranai,Velachey</strong>, we understand the importance of kickstarting your career. Zerobug Academy provides
                                                placement assistance, connecting you with leading companies and job opportunities.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-red'>
                                        <img decoding="async" src="../assets/img/blob3.jpg" alt="reactjs Real Time projects" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4>Flexible Learning Formats</h4>
                                            <p className="text-center "> Recognizing the diverse schedules of our students, we offer flexible timings for our React JS courses. Choose a schedule that suits your convenience, ensuring that your learning journey seamlessly integrates with your other commitments</p>

                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="container-fluid " id='Career'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 FAQ">
                                <h2 className=" text-center heading-left text-left" >
                                    Career Opportunities</h2>
                            </div>
                            <div className="col-lg-8">
                                <div className="mb-5">
                                    <p>"Upon completion of our React JS course, you will be well-equipped for various roles in the industry, including."</p>
                                </div>
                                <div class="col-lg-12 instruc-wrap" id="OurInstructors">

                                    <div class="proj-portal cnt">

                                        <ul class="tick line-height" >
                                            <li><span>React Developer</span></li>
                                            <li><span>Frontend Developer</span></li>
                                            <li><span>Web Developer</span></li>
                                            <li><span>UI/UX Developer</span> </li>
                                            <li><span>JavaScript Developer</span> </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>

                            <div class="col-lg-4   mt-lg-0">

                                <div class="d-flex flex-column text-center rounded mb-5  px-4">
                                    <div className=''>
                                        <div className='talk2Us-Promo'>
                                            <div className='heading-promo'>
                                                <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                                talk to us
                                            </div>
                                            <div className='promo-content'>
                                                <h7 className="title  text-center"> Online and Classroom Training</h7>
                                            </div>
                                            <div className='mrg-top5 black-color text-center' >
                                                <i className='fa fa-mobile-phone'></i>+91-9750061584
                                            </div>
                                            <div className='mrg-top5 black-color' >
                                                <i className='fa fa-mobile-phone  text-center'></i>+91-8946004709
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5" style={{ background: "linear-gradient(225deg,darkorange 30%,  goldenrod 75%)" }}>
                <div className="container "  >
                    <div className="row d-flex flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7 col-12 ">
                            <h1 className="h3 light-300 text-white">Discover what our satisfied Student have to say about our Teaching</h1>
                        </div>
                        <div className="col-lg-5 col-12 ">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Google.svg" alt="Google" className="img-fluid" />
                                    <div><Link to='https://www.google.com/search?q=zerobug+academy&sca_esv=6b1c5e95a3d0b06b&sca_upv=1&source=hp&ei=s4ohZrSjMPiQseMPrKy_yAc&gs_ssp=eJzj4tVP1zc0TDNKLyozM88zYLRSNagwTjQ1Mk2xTE5OSzNISzJMsTKoSLQwTDJKSTRJMzEzSUlJNvHir0otyk8qTVdITE5MSc2tBADrPRYM&oq=zerobug&gs_lp=EhFtb2JpbGUtZ3dzLXdpei1ocCIHemVyb2J1ZyoCCAQyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAuGIAEGMcBGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAEMgUQABiABEi4TFDhCVjKNHAAeACQAQCYAfgOoAHnPqoBBTctMi4zuAEByAEA-AEBmAIFoAKaQKgCAMICCBAAGIAEGLEDwgIREC4YgAQYsQMY0QMYgwEYxwHCAg4QLhiABBixAxjRAxjHAcICCBAuGIAEGLEDwgIOEC4YgAQYxwEYjgUYrwGYAwCSBwM3LTWgB_Ez&sclient=mobile-gws-wiz-hp#ip=1&wptab=si:AKbGX_oXOTjHK3vNPxrwAU4tsC2W_rsdJDrrSHpqUAOdbOh1q9uNYK9vtbmmnS7KXd3yboYf2bOhwMeMmdWxIVwYVpfw0ijnlRKr1nOHCwOPFAW8qUORl1WdTqK3rX_iT9y05M3y7DbX' target='_blank' className='text-white'>Google Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Youtube.svg" style={{ width: '50px' }} alt="YouTube" className="img-fluid" />
                                    <div><Link to='#' className='text-white'>Youtube Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Facebook.svg" alt="Facebook" className="img-fluid" />
                                    <div><Link to='https://www.facebook.com/zerobugacademytraininginstitute/' target='_blank' className='text-white'>Facebook Reviews</Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*  /*course feature end */}
            {/* Chennai and Elevate Your Career */}
            <div className="container">
                <div className='container-fluid'  >
                    <div class="col-lg-12 instruc-wrap" id="OurInstructors">
                        <h2 className='text-center heading-left text-left' >Level Up Your Coding Skills with Zerobug Academy's React JS Course in Chennai!</h2>
                        <div class="proj-portal cnt">
                            <p>Are you ready to dive into the dynamic world of React JS and elevate your front-end development expertise? Join the leading institute in Chennai, Zerobug Academy, for an immersive and industry focused React JS course. Whether you're a beginner or an experienced developer, our course is designed to empower you with the skills needed for success in the ever-evolving field of web development</p>
                            <ul class="tick">
                                <li>Don't miss out on this opportunity to learn from the best and shape your future in reactjs programming. </li>
                                <li>Contact <strong>Zerobug Academy</strong> today and take the first step toward becoming a reactjs expert! Your journey to excellence starts here.</li>
                                <li>Enroll Now and Shape Your Future with ReactJS at Zerobug Academy in Pallikaranai, Velachery Chennai!</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid margin-30" id='FAQ'>
                        <div className="row">
                            <div className="col-lg-7 FAQ">
                                <h2 className="mar-bot-top text-center heading-left text-left">
                                    Frequently Asked Questions
                                </h2>
                                <div className="mb-5">
                                    <ReactList />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="alumni-work mar-bot-top">
                                    <h3>Our Alumni Work in Top MNC’S</h3>
                                    <p>"Zerobug Academy has achieved remarkable success by placing thousands of students in leading roles across top multinational organizations."</p>
                                    <div className="row">
                                        <div className="alumni-work-container">
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/DHL.png" decoding='async' loading='lazy' alt="Our Alumni Work in Top MNC’S" />
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/tvs-credit.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/zoho-1-logo-png-transparent.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/freshworks.webp" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy'/>
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/larsen-and-toubro.jpg" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/HCLTECH.NS.png" alt="Our Alumni Work in Top MNC’S"decoding='async' loading='lazy' />
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/centelon.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy'/>
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive" src="./assets/banners/INFY_BIG.png" alt="Our Alumni Work in Top MNC’S"  decoding='async' loading='lazy'/>
                                            </div>
                                            <div className='alumini-box'>
                                                <img className="img-responsive h-width" src="./assets/banners/Hexaware.jpg" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                <p>Join the success community to build your future </p><button onClick={openPopup} className='entrol-button'> Enroll Now</button>
                                </div>
                                </div>
                            </div>

                        </div> 
                </div>
            </div>

      <Footer />

    </Fragment>
  )
}

export default ReactCourse;
