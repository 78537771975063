import React, { useEffect, Fragment, useState } from 'react';

import Menu from './menu';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const GoogleAd = () => {
    useEffect(() => {
        document.title = 'N Number of Significant Ways To Optimize Your Google Adwords Account';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png'alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" style={{ color: 'red' }} >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid    page-blog"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>

            <WhatsappButton />
            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h111" style={{ textAlign: "center", marginBottom: "40px" }}>
                                    N Number of Significant Ways To Optimize Your Google Adwords Account</h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>Online Marketing is all about generating traffic to your website. You may create an amazing website and run many A/B testing experiments all day long, but still,
                                            your site is no use without visitors/traffic. One of the successful ways to drive visitors to your site can be done through Google Adwords. You can even drive traffic
                                            to your site by doing SEO or SMO, but the SEO results take time and gets competitive day by day offering a lower ROI and social media’s organic result is diminishing every day.</p>

                                        <p>If you are brand new to Google Adwords Management and need to enhance the performance of an account, then the below Points on Optimizing your Adwords account is going to be the absolute bible for you. When you’ve done setting up your Adwords account,
                                            you probably require using few of the intelligent strategies in order to optimize it for the best results in the business. In the AdWords optimization points presented below, we give you 50 Significant Ways to Optimize your Google Adwords Account and eventually get more traffic with lesser cost.</p>
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Eliminate Low Performing Keywords</h4>
                                        <p>The foremost thing you must do is to analyze your keywords well. You need to focus your budget mainly on those keywords which are performing well, instead of wasting your quality time on those keywords which are not.
                                            So delete those low performing keywords and use the main ones on your site.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Add Negative Keywords Persistently</h4>
                                        <p>You can easily get ideas for the negative keywords by using the reports of the search terms. Add those negative keywords to
                                            filter the important keywords which you want.
                                            You should do it continuously.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Explore New Keywords To Use</h4>
                                        <p>Explore and find the essential new keywords with the use of search terms report and the keyword planner.
                                            These potential keywords can be used to increase traffic.</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Check Whether All Your Keywords Are Eligible</h4>
                                        <p>You must try to adjust bids and change the match types in order to make sure that all your keywords are eligible.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Evaluate Your Maximum CPC</h4>
                                        <p>You should review and analyze your maximum Cost Per Click. Your max CPC increases if your keywords have the number of
                                            conversion rates and Click Through Rates and decrease if your keywords have low conversion rates and Click Through Rates.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Try Out Using Your Branded Keywords</h4>
                                        <p>You try using your brand power to drive more leads and improve the rates of CTR. So the usages of branded keywords attract
                                            leads as the users might search using your brand.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Evaluate Adgroup Structure And Campaign</h4>
                                        <p>You must strengthen up your campaign structure. First, you should classify campaigns up that is based on daily budgets,
                                            targeting location, language settings, initial and final dates,
                                            and distribution preferences. Therefore a well-organized account usually lets you aim your ads more powerful.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Persistently Test New Ads</h4>
                                        <p>Believe there is consistently space for the improvement. So you must continuously focus in order to improve the CTR of your ads.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Try Inserting Effective Keyword</h4>
                                        <p>Try to meet the accurate search term of customers. Thus inserting the effective keyword provides you a finer message match.
                                            It improves CTR and eventually must increase the quality score.</p>


                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default GoogleAd;