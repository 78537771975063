import React, { useEffect, useState, Fragment } from 'react';
import Menu from './menu';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const WebDesignBlog = () => {
    useEffect(() => {
        document.title = 'Best web Design Training in Chennai';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>

                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid    page-blog"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsappButton />

            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h1" style={{ textAlign: "center", marginBottom: "40px" }}>
                                    Reasons Why Web Designing Is Not A Dying Profession</h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left  h411' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>Web Design has a tremendous enhancement in the past two years. Designers continue to work to be relevant in this world.
                                            Nowadays you would’ve seen ads running on paid website builders. With all these web design is considered to be a brink of extinction.
                                            <strong>Web Designing Course in Pallikaranai, velachery, Chennai </strong> is a professional and an art in the industry.
                                            Here are some of the Reasons why web designing is not a dying professional in Technology.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h411' style={{ textAlign: 'left' }}>Online Branding</h4>
                                        <p>According to the research conducted, web experts have made a submission to the role of a web designer
                                            facilitating the content quality and messages to website visitors. Coming across through the recent articles,
                                            a web designers or graphic designer has the ability to promote the brand of the company is vital. This leads to the
                                            integration of web design and marketing and the wall between them has long broken down,
                                            in which designers expand their skills by learning branding, marketing and content.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h411' style={{ textAlign: 'left' }}>Web Maintenance And Security</h4>
                                        <p>A company’s secret should be safeguarded with essential secure websites. This is same as to website structure,
                                            design and maintenance is critical to SEO. Hackers trying to use the advantage of vulnerable websites to dated
                                            security details may infect computer website visitors or leak sensitive information of clients. The main requirement
                                            to maintain the website is to keep the information up-to-date.
                                            Get technically shrewd to patch the security and maintenance of <strong>Website</strong> of a professional web designer.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h411' style={{ textAlign: 'left' }}>Marketing Expertise And Online Branding</h4>
                                        <p>The online marketing activities and scheduling of content distribution facilitate an efficient budget.
                                            The skill and knowledge of an active help are the best marketing activities.</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h411' style={{ textAlign: 'left' }}>Websites Vs Quality</h4>
                                        <p>Web design is a fascinating profession for people who are willing to attain new ideas to implement in their designs.
                                            Developers develop websites to feel good and user-friendly for people. Fonts, backgrounds,
                                            info graphics, videos and layouts are the sectors to attract web visitors in <strong>Web Designing Training in chennai</strong> . It plays a vital career role.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h411' style={{ textAlign: 'left' }}>Web Designing Is An Art In The Technology</h4>
                                        <p>The fastest way of technology development on daily basis in linked to the internet. This means the web developer is responsible for skill and knowledge to be evolved. Developers should diversify their knowledge in skills like android coding, customized app etc.This proves that web designing is not a dying professional but an evolving career growth technology.
                                            <strong> best Web Designing Training at zerobug Academy in Pallikaranai, Velachery Chennai </strong> will teach you about the knowledge and skill to create unique websites.</p>


                                    </div>


                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                    <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default WebDesignBlog;