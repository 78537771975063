import React, { useEffect, Fragment, useState } from 'react';
import emailjs from 'emailjs-com';
import Menu from './menu';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const FrameWorkBlog = () => {
    useEffect(() => {
        document.title = ' Latest 7 Code And Framework To Be Followed In 2024';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                            {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                           </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                     </ul>
                                </li>
                                <li><Link to="/blog" style={{color:'red'}} >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid    page-blog" >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>
          <WhatsappButton/>

            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h111" style={{ textAlign: "center", marginBottom: "40px" }}>
                                    Latest 7 Code And Framework To Be Followed In 2024 </h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>2024 is going to be the biggest year for developers for creating the landmark in many programming ecosystems. The entry of AngularJS 2 moved Node.js towards neutrality. Many developers felt JavaScript as fatigue, but others considered fatigued complaints as fatigue.
                                            Due to modularity controversy, Java 9 version was delayed. But Go 1.8 has entered into limelight which is going to hit the industry along with the new languages Elm and Kotlin. Here is the latest 6 Code and framework to be followed in 2024 is as follows: latest framework training in chennai</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Golong (Go) Will Be Among The Peak Languages</h4>
                                        <p>Google’s invention Go is the programming language which is to get noticed by developers in 2024 .
                                            Richard Eng of Smalltalk Renaissance has been researching this for several years. Go will continue to be an amazing ascendancy towards the top programming languages. Startups choose Ruby and Node.jsb as their languages. In the upcoming years, the trend will change in reverse order, Go is displacing Node.js.
                                            According to the survey, Go will play a crucial role.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>The Next Biggest For Android Is Kotlin</h4>
                                        <p>Kotlin is the programming language created by JetBrains. It is a growing popularity among Android Developers. <strong>Java Training in Chennai</strong>  teaches you the reason behind Steroids contains Kotlin. The main reason for using this is, a lesser code can be compactable.
                                            This makes developers even lazier, who is under tight deadlines. This makes their work very easy.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Node.Js Will Be More Enterprise Users</h4>
                                        <p>It contains Node.js which is more stable. The ecosystem builds up trust in Node.js modules. The “left pad”
                                            rattles many enterprises when it broke essential JavaScript
                                            tools. Today NodeSource is creating certified modules which are reliable and secure. This doesn’t allow
                                            the administration to move suddenly which is used by thousands of users. Additional development which is
                                            to be decided in 2024 is going to incorporate ES6 modules. Node.js has decided to release their latest
                                            versions in the upcoming years.</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left' style={{ textAlign: 'left' }}>Progressive Web Apps</h4>
                                        <p>2017 was a major new paradigm for mobile web development. The new process of installation in Windows and Android will the beginning in app stores. It is an alternative method of publishing. The arrival of one paradigm is the downfall of another. In this case, Safari will start its dual role of supporting Web View-based applications like PhoneGap and Apache Cordova.</p>
                                        <p>Get to know more about code and framework in our best Java Course in Chennai. These are the code and key trends in 2024 . Missed technologies will be discussed <strong>J2EE Training in Chennai by Zerobug Academy!</strong> </p>

                                    </div>

                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>
                                    <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>                                                  
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>
                                          
                                        </form>
                                    </div>

                                </div>
 </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default FrameWorkBlog;