import React, { useState } from 'react';
import Dropdown from '../DropDown';

const AngularSyllabus= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: 'HTML 5, CSS, BOOTSTRAP',
      options: ['HTML 5','CSS','BOOTSTRAP'],
    },

    {
      title: 'JavaScript - Program Curriculum',
      options: ['JavaScript Introduction','JS Events','Functions in JavaScript','Inbuilt methods in JS','Arrays in JavaScript','Objects in JavaScript','Conditional and loops in JavaScript','HTML DOM Manipulation','Timer functions in JavaScript','Local Storage & Session Storage','JSON','AJAX']

    },
    {
      title: 'TypeScript - Program Curriculum',
      options: [ 'Module Introduction','Node Introduction','TypeScript Environment Setups','Variables in TS','Datatypes in TS','Different Types of Parameters in TS','Features in TS.','OOPS in TypeScript'],   
      },
      
    {
      title: 'ANGULAR 13- Program Curriculum',
      options: ['Introduction','Angular Environment setups','Main Building Blocks of Angular','Angular Modules','Components','Data Binding, Property Binding, Event Binding & 2-way Data Binding','Services','Directives & Custom Directives','Pipes in Angular'],
     
    },
    
     
    {  
      title: 'Components Communication',
      options: ['Overview of Components Interaction.','Share data between components by using Service.','Component Interaction from Parent to Child.','Component Interaction from Child to Parent.',
      ' @ViewChild decorator overview','@Input & @Output decorator overview','Custom Event Binding','Components Interaction by using Event Emitter & Subject and observable']
    },
  
    {  
        title: 'Event Emitter and other special Directives',
        options: ['What is Event Emitter?','When to use Event Emitter?','How to transfer data with Event Emitter?','How to create Custom Events and Trigger?',
        'View Encapsulation Overview.','What is ng-content and its purpose?','ng-container Directive overview'],
      },
    
      {  
        title: 'Template Driven Form in Angular',
        options: ['What is Template Driven Form?',' Purpose of Form in Angular','How to setup a form in Angular?','What is form control and how to set it?','What is ngForm?',
        'How to Access form values?','What are the form states available in Angular?','TD form Validations.','HTML5 Validation vs Angular form Validation.',
        'How to set prepopulate values in form fields?','Setup One-way data binding in Angular'],
      },
     
      {  
        title: 'Reactive Form in Angular',
        options: [' What is Reactive Form?','Difference between Template Drive & Reactive Form','What is form group & form control?',
        'How to sync view & Reactive form TS?','What is Patch Values & SetValues?','How to attach Dynamic Validations?','How to get Reactive fromValues?','Create Dynamic Form by using Reactive Form'],
      },

    
      {  
        title: 'Service and Dependency Injection in Angular',
        options: ['What is Service in Angular?','Create & configure Service in Angular.','How to do Dependency Injection in Angular?','What is Singleton Object?','Multiple DI in single Class.']
      },
    
      {  
        title: ' Reactive Form in Angular',
        options: ['What is Routing','How Routing makes our App into SPA?','How to configure Routing in an Application?',
        'Load our components dynamically based on url path.','How to create Child Route?','Navigating to other links programmatically.',
        'Passing Parameter to the Routes','Static Route vs Dynamic Route','Fetching Route Params','Router Directives overview','Client-side authorization using Route Guard'],
      },
    
      {  
        title: 'Making HTTP Requests in Angular ',
        options: ['HTTP Client in Angular','REST API Overview','How to establish HTTP request to Server side','How HTTP Mechanism works?','What are the methods a able in HTTP?','What is Observable & Observer?',
        'Catching HTTP errors','Using the async pipe with HTTP Requests','What are the call back methods available in Observable?','What is next(), error() and complete() ?',
        'How to send Query Params & Custom Headers?','How to connect any backend & APIs?','What is CORS?','How to resolve CORS errors?'],
      },
    
      {  
        title: 'Reactive Form in Angular',
        options: ['Client side Authorization vs Server side Authorization','Server side Authorization by using JWT Token','Set JWT Key Expiry time.','HTTP interceptors - Overview','How to configure HTTP interceptors?'],
      },
      {  
        title: 'File Upload in Angular ',
        options: ['FormData() in Angular',' Angular form setups for File Upload.','Server side setups for File Upload.','Attach the Multer middleware in server-side routing'],
      },
                
  ];
  const [displayedDropdowns, setDisplayedDropdowns] = useState(dropdowns.slice(0, 6)); // Display 5 dropdowns initially
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setDisplayedDropdowns(showAll ? dropdowns.slice(0, 6) : dropdowns); // Toggle between showing 5 or all dropdowns
  };
  return (
    <div>
      {displayedDropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
      <button className='view-button' onClick={toggleShowAll}>{showAll ? 'Show Less' : 'Show More'}</button>
    </div>
  );
};

export default AngularSyllabus;
