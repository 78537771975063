import React, { useState, useEffect, Fragment } from 'react';
import Menu from '../menu';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import TawkTo from '../TawkTo';
import SubNav from '../SubNav';
import SeleniumTestingList from './SeleniumTestingList';
import SeleniumSyllabus from './SeleniumSyllabus';
import WhatsappButton from '../WhatsappButton';
import PopupFrom from '../PopupForm';
import emailjs from 'emailjs-com';
import BottomMenu from '../BottomMenu';
const PDF_FILE_URL = "assets/syllabus/Testing-Course-Content.pdf";

const SeleniumTestingCourse = () => {
    const downloadFileAtURL = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);
    };
    useEffect(() => {
        document.title = 'Best Selenium Training at Zerobug Academy, Velachery Chennai';
        return () => {
            document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy';
        };
    }, []);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });
    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }

        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }

        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }

        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
        // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };


    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <BottomMenu />
            {/* <!-- Navbar End --> */}
            <div className="container-fluid banner-background ">
                <div className="container">
                    <div className="row align-items-center margin-30">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <h1 className="text-white text-start text-uppercase">
                            selenium training in chennai
                            </h1>
                            <div className="rating-wrap">
                                <span className="ratingVal">49</span>
                                <img src="../assets/img/R.png" alt="image" loading='lazy' />
                                <span className="learnerTxt">6K+ Satisfied Learners</span>
                            </div>
                            <p className='text text-white'>Learn Selenium Course in Chennai at Zerobug, your premier destination for top-quality Selenium training. Our expert trainers specialize in Selenium and offer unique, practical-oriented course content covering WebDriver, IDE, Grid, and TestNG. With hands-on training and stellar reviews, Zerobug is the Best Selenium Training Institute in Chennai. Elevate your automation testing skills with us and excel in your career.</p>
                   

                        </div>
                        
                        <div className="col-lg-6">
                            <div className="image-card python-new-banner border-0">
                                <img src="./assets/banners/Selenium.png" alt="LEARN SELENIUM AUTOMATION" decoding='async' loading='lazy'/>
                                <figcaption className='visually-hidden '>
                                <a href="https://www.freepik.com/free-photo/portrait-shocked-young-african-man_7380265.htm#from_view=detail_alsolike">Image by drobotdean on Freepik</a>
                                </figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Header End --> */}
            {/* courseNav */}
            <div className="courseNav  d-sm-block" >
                <div className='container' >
                    <div className=" courseNav-wrap">

                        <SubNav />
                    </div>
                </div>
            </div>

            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">
                    <div className="container " id='content'>
                        <div className="row">

                            <div class="col-lg-12 instruc-wrap1" id="OurInstructors">

                                <h2>Why Selenium?</h2>
                                <div class=" cnt">

                                    <div class="tick">
                                        <p className='justify-text'> <strong>Selenium Testing</strong> , known for its readability and versatility, has emerged as a programming
                                            language of choice for Testers worldwide. Whether you're a beginner or an experienced coder,
                                            SeleniumTesting offers a seamless learning curve and
                                            a vast ecosystem that <strong>selenium Testing, Automation Testing,</strong> and more.</p>
                                    </div>
                                </div>
                            </div>

                            <WhatsappButton />
                            <PopupFrom />
                            <TawkTo />

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Detail End --> */}

            {/*  course-batch */}
            <div class="container-fluid features-UpcomingBatch py-5" id='up-coming-batch'>
                <div class="features-courseSchedule">
                    <div class="row wrap">
                        <div class="col-lg-9 container">
                            <h3 class='float-left h1 text-center'>Upcoming Selenium Training Chennai </h3>
                        </div>
                        <div className="batch-table-wrap col-sm-12 col-lg-921">
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>jan 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Feb 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Mar 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Apr 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>


                        </div>
                        <div class=''>
                        <div className='talk2Us-Promo new-Promo-Class '>
                                            <div className='heading-promo'>
                                                <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                                talk to us
                                            </div>
                                            <div className='promo-content'>
                                                <h7 className="title"> Online and Classroom Training</h7>
                                            </div>
                                            <div className='mrg-top5'>
                                                <i className='fa fa-mobile-phone'></i>+91-9750061584
                                            </div>
                                            <div className='mrg-top5'>
                                                <i className='fa fa-mobile-phone'></i>+91-8946004709
                                            </div>
                                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* reaqust call */}
            <div className="container-fluid courseReqBatch">
                <div className='features-ReqBatch'>
                    <div className=' col-lg-12 col-md-12 reqBatch-wrap'>
                        <img src='../assets/img/date.png' alt="Date Icon" />
                        <div className='reqBatch-content'>
                            <p>Can’t find a batch you were looking for?</p>
                            <button className="reqBatch-btn " onClick={openPopup}> REQUEST A BATCH</button>
                            {isPopupVisible && (
                                <div className="popup-form">
                                    <div className="popup-content">
                                        <span className="close" onClick={closePopup}>&times;</span>
                                        <h2>Enquiry Now</h2>
                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn btn-primary" type="submit">
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Detail Start2 --> */}
            <div className="container">
                <div className="container-fluid margin-30" id='syllabus'>
                    <div className="container ">
                        <div className="row">

                            <div className="col-lg-8 FAQ">
                                <h3 class="text-center heading-left text-left" >
                                    Selenium Course Curriculum </h3>

                                <p className='text-align-syllabus'>"Become proficient in test automation with our Selenium course. Learn to write robust and scalable automated tests for web applications using Selenium WebDriver and various testing frameworks. Develop expertise in browser automation, test scripting, and test suite management, ensuring software quality and reliability."</p>
                                <button  onClick={() => { downloadFileAtURL(PDF_FILE_URL) }} className='download-buttom '>Download-Curriculum</button>
                                <div className="mb-5">

                                    <SeleniumSyllabus />
                                </div>
                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4 text-white">
                                        <h5 className="m-0">Requst Free Demo</h5>
                                    </div>
                                    <div className="card-body rounded-bottom whitesmoke " >
                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form text-white" type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>



                                {/* <!-- Category List --> */}
                                <div class="mb-5">
                                    <div class="promo-cnt whiteBG courseHglhts-wrap">
                                        <div class="row">


                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color">24/7</div>
                                                    <span>Expert Support</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color" >100%</div><span> Placement Assurence </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Detail End --> */}

            </div>
            <div className='container-fluid key-module-bg' id='course-content'>

                <div class=" features-courseProject container">
                    <div class="row" >
                        <div class="col-lg-12">
                            <h2 class=" text-white   mar-bot" >
                                Key Modules of Our SeleniumTesting Course</h2>
                        </div>

                        <div class="key-modules-container ">
                            <div class="key-module">
                                <img src="../assets/banners/selenium.jpg" alt="Selenium Basics" class="key-image" loading='lazy'/>
                                <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                <h3>Selenium Basics</h3>
                                <p>Dive into Selenium basics with hands-on exercises and real-world examples. Master automation testing essentials efficiently.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/img/icons8-web-development-48.png" alt="Advanced Selenium Techniques" class="key-image" loading='lazy'/>
                                <figcaption className='visually-hidden '>
                                <a target="_blank" href="https://icons8.com/icon/U_6kEGCJg9Nn/advanced-search">Advanced Search</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
                                       </figcaption >
                                <h3>Advanced Selenium Techniques</h3>
                                <p>Explore advanced Selenium techniques like data-driven testing and cross-browser testing for comprehensive automation skills.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/img/icons8-data-analysis-48.png" alt="Selenium WebDriver" class="key-image" loading='lazy'/>
                                <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                <h3>Selenium WebDriver</h3>
                                <p> Learn Selenium WebDriver intricacies for robust web testing. Gain expertise in creating scalable automation scripts.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/img/icons8-machine-learning-48.png" alt="selenium" class="key-image" loading='lazy'/>
                                <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                <h3>Selenium Frameworks</h3>
                                <p>Discover Selenium frameworks such as TestNG and JUnit for organized test management and seamless integration with CI/CD pipelines.</p>
                            </div>
                        </div>

                    </div>
                    <div class="row marginTop-20"  id='project1'>
                        <div class="col-lg-12">
                            <h2 class="  text-white mar-bot" >
                                Benefits of Learning selenium at Zerobug:</h2>
                        </div>
                        <div class="benefits-container">
                            <div class="benefit-item">
                                <h3>Industry-Driven Curriculum</h3>
                                <p>Our course is curated based on industry requirements, ensuring you're equipped with skills that employers seek.</p>

                            </div>
                            <div class="benefit-item">
                                <h3>Hands-On Projects</h3>
                                <p>Gain practical experience by working on real-world projects, honing your problem-solving abilities.</p>

                            </div>
                            <div class="benefit-item">
                                <h3>Expert Instructors</h3>
                                <p>Learn from seasoned professionals dedicated to guiding you through your learning journey.</p>

                            </div>
                            <div class="benefit-item">
                                <h3>Career Support</h3>
                                <p>Benefit from our career guidance and placement assistance to step confidently into the professional realm.</p>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            {/* 
SeleniumTesting overview */}
            {/*  modes f training */}
            <div className='container-fluid training-modes-wrap' >
                <div className=' container-fluid courseReqBatch training-modes-wrap'>
                    <div className="col-lg-12 col-md-12  mrg-top20">

                        <div class="row ">
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob7.jpg" alt="selenium training" width="100%" height="100%"></img>
                                    </div>
                                    <h3>Classroom Training</h3>
                                    <div className="training-mode-cnt">
                                        <span >Instructor Led Classroom Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 5.jpg" alt="online course" width="100%" height="100%"></img>
                                    </div>
                                    <h3 >Online Training</h3>
                                    <div className="training-mode-cnt">
                                        <span>Instructor Led Online Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 4.jpg" alt="best trining" width="100%" height="100%"></img>
                                    </div>
                                    <h3> Corporate Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>Instructor Led Corporate Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/bobl36.jpg" alt="image" width="100%" height="100%"></img>
                                    </div>
                                    <h3> One to One Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>best training based on requirement</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            {/*  /*course feature */}
            <div className='container-fluid  home-need-bg'>
                <div className="container ">

                    <div className=' CourseFeatures home-need-bg'>
                        <div className='features-Coursefeatures'>
                            <div className="row text-center" >
                                <div className="col-lg-12 mar-bot-top " >
                                    <h2>Why Choose Zerobug Academy for SeleniumTesting Courses?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj'>
                                        <img decoding="async" src="../assets/img/blob.jpg" alt="selenium  Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4> Expert Faculty</h4>
                                            <p className='text-center'>Learn from industry experts and certified professionals with extensive experience in SeleniumTesting development. Our faculty brings a wealth of knowledge and real-world insights to the classroom, ensuring you receive the best education.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-green'>
                                        <img decoding="async" src="../assets/img/cloud-computing.png" style={{ color: 'orangered' }} alt="selenium Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4>Comprehensive Curriculum</h4>
                                            <p className='text-center'>Our SeleniumTesting course is designed to cover the entire spectrum of SeleniumTesting programming, from fundamentals to advanced concepts. Master the language that powers Selenium Testing, Automation Testing, and more.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-voilet'>
                                        <img src="../assets/img/blob1.jpg" alt="selenium Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4>Hands-On Training</h4>
                                            <p className=" text-center">At Zerobug Academy, we believe in a hands-on, practical approach to learning. Our selenium course includes extensive practical sessions, projects, and
                                                case studies to ensure you gain real-time exposure and expertise.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-blue'>
                                        <img decoding="async" src="../assets/img/OIP (3).jpg" alt="selenium Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4 >Latest Tools and Techniques</h4>
                                            <p className='text-center'>Stay ahead in the rapidly evolving tech industry by learning the latest tools and techniques in Selenium Testing programming. Our courses are regularly updated to incorporate the latest industry trends and best practices.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top">
                                    <div className='keyFeatures-wrap proj-yellow'>
                                        <img decoding="async" src="../assets/img/blob2.jpg" alt="selenium Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                       <a href="https://www.freepik.com" >Icon by Freepik</a>
                                       </figcaption >
                                        <div className='cnt'>
                                            <h4>Placement Assistance</h4>
                                            <p className='text-center'> As the <strong>Best Institute in Pallikaranai,Velachey</strong>, we understand the importance of kickstarting your career. Zerobug Academy provides
                                                placement assistance, connecting you with leading companies and job opportunities.</p>
                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-red'>
                                        <img decoding="async" src="../assets/img/blob3.jpg" alt="selenium Real Time projects" width="20%" height="20%"loading='lazy'></img>
                                        <div className='cnt'>
                                            <h4>Flexible Learning Formats</h4>
                                            <p className="text-center "> We offer flexible learning formats to suit your preferences. Choose from classroom training, online sessions
                                                , or a blended approach – whatever works best for you</p>

                                                <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5" style={{ background: "linear-gradient(225deg,darkorange 30%,  goldenrod 75%)" }}>
                <div className="container "  >
                    <div className="row d-flex flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7 col-12 ">
                            <h1 className="h3 light-300 text-white">Discover what our satisfied Student have to say about our Teaching</h1>
                        </div>
                        <div className="col-lg-5 col-12 ">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Google.svg" alt="Google" className="img-fluid" />
                                    <div><Link to='https://www.google.com/search?q=zerobug+academy&sca_esv=6b1c5e95a3d0b06b&sca_upv=1&source=hp&ei=s4ohZrSjMPiQseMPrKy_yAc&gs_ssp=eJzj4tVP1zc0TDNKLyozM88zYLRSNagwTjQ1Mk2xTE5OSzNISzJMsTKoSLQwTDJKSTRJMzEzSUlJNvHir0otyk8qTVdITE5MSc2tBADrPRYM&oq=zerobug&gs_lp=EhFtb2JpbGUtZ3dzLXdpei1ocCIHemVyb2J1ZyoCCAQyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAuGIAEGMcBGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAEMgUQABiABEi4TFDhCVjKNHAAeACQAQCYAfgOoAHnPqoBBTctMi4zuAEByAEA-AEBmAIFoAKaQKgCAMICCBAAGIAEGLEDwgIREC4YgAQYsQMY0QMYgwEYxwHCAg4QLhiABBixAxjRAxjHAcICCBAuGIAEGLEDwgIOEC4YgAQYxwEYjgUYrwGYAwCSBwM3LTWgB_Ez&sclient=mobile-gws-wiz-hp#ip=1&wptab=si:AKbGX_oXOTjHK3vNPxrwAU4tsC2W_rsdJDrrSHpqUAOdbOh1q9uNYK9vtbmmnS7KXd3yboYf2bOhwMeMmdWxIVwYVpfw0ijnlRKr1nOHCwOPFAW8qUORl1WdTqK3rX_iT9y05M3y7DbX' target='_blank' className='text-white'>Google Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Youtube.svg" style={{ width: '50px' }} alt="YouTube" className="img-fluid" />
                                    <div><Link to='#' className='text-white'>Youtube Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Facebook.svg" alt="Facebook" className="img-fluid" />
                                    <div><Link to='https://www.facebook.com/zerobugacademytraininginstitute/' target='_blank' className='text-white'>Facebook Reviews</Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className='container-fluid'  >
                <div class="col-lg-12 instruc-wrap" id="OurInstructors">
                        <h2 className='heading-left h1 text-justify' > Join Zerobug Academy for the Best SeleniumTesting Course in Chennai and Elevate Your Career:</h2>
                        <div class="proj-portal cnt">
                            <p>Whether you're a novice venturing into programming or a seasoned developer aiming to sharpen your skills, Zerobug Academy is your trusted partner for success. Enroll in the top-rated Selenium Testing Course in Chennai and unlock a world of opportunities in the tech industry</p>
                            <ul class="tick">
                                <li>Don't let this chance slip away to learn from industry experts and shape your career in Selenium Testing programming. Reach out to Zerobug Academy today and take the first stride towards mastering Selenium Testing! Your journey to excellence begins here.</li>
                                <li>Enroll now and embrace the future of coding with Zerobug Academy in Pallikaranai, Velachery-Chennai!"</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container-fluid margin-30" id='FAQ'>
                    
                    <div className="row">
                        <div className="col-lg-7 FAQ">
                            <h2 className="mar-bot-top text-center heading-left text-left">
                                Frequently Asked Questions
                            </h2>
                            <div className="mb-5">
                                <SeleniumTestingList />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="alumni-work mar-bot-top">
                                <h3>Our Alumni Work in Top MNC’S</h3>
                                <p>"Zerobug Academy has achieved remarkable success by placing thousands of students in leading roles across top multinational organizations."</p>
                                <div className="row">
                                    <div className="alumni-work-container">
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/DHL.png" alt="Alumni Work 1"  decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/tvs-credit.png" alt="Alumni Work 2"decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/zoho-1-logo-png-transparent.png" alt="Alumni Work 3" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/freshworks.webp" alt="Alumni Work 4"decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/larsen-and-toubro.jpg" alt="Alumni Work 5" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/HCLTECH.NS.png" alt="Alumni Work 6"decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/centelon.png" alt="Alumni Work 7" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/INFY_BIG.png" alt="Alumni Work 8" decoding='async' loading='lazy'/>
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive h-width" src="./assets/banners/Hexaware.jpg" alt="Alumni Work 9" decoding='async' loading='lazy'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                <p>Join the success community to build your future </p><button onClick={openPopup} className='entrol-button'> Enroll Now</button>
                                </div>
                            </div>
                        </div>

                 
                </div>
            </div>
            </div>
            

            <Footer />
          

        </Fragment>
    )
}

export default SeleniumTestingCourse;
