import React from 'react';
import Dropdown from '../DropDown';

const SeleniumTestingList= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: '1.Why should I choose Zerobug Academy forSeleniumTest training in Chennai?',
      options: ['Zerobug Academy stands out as the premier institute in Chennai forSeleniumTest training. We offer expert-led courses, hands-on training, a comprehensive curriculum, and placement assistance. Our commitment to quality education and industry relevance makes us the preferred choice forSeleniumTest enthusiasts.'],
    },
    
    {
      title: ' 2.What is the duration of theSeleniumTest course, and is there a flexible schedule?',
      options: ['The duration of ourSeleniumTest course varies based on the program you choose. We understand the importance of flexibility, and hence, we provide options for weekday and weekend batches. Our flexible schedules cater to both working professionals and students.'],
    },
    
     {
      title: '3.Who are the instructors at Zerobug Academy, and what is their experience?',
      options: [ 'Zerobug Academy boasts a team of experienced instructors who are industry experts and certified professionals. Our instructors bring years of practical experience inSeleniumTest development, ensuring that you receive top-notch education with real-world insights'],   
      },
    {
      title: '4.What is the curriculum of theSeleniumTest course, and does it cover advanced topics?',
      options: ['OurSeleniumTest course has a comprehensive curriculum that covers everything from the fundamentals to advanced topics. You will delve into areas such as web development, data science, artificial intelligence, and more. We regularly update our curriculum to align with industry trends']
      
    },
    
     
    {  
      title: '5.Does Zerobug Academy provide placement assistance forSeleniumTest course participants?',
      options: ['   Yes, Zerobug Academy is committed to helping you kickstart your career. We provide placement assistance, connecting you with leading companies and job opportunities in the tech industry. Our goal is to empower you with the skills and support needed for a successful career inSeleniumTest programming.',
    'Have more questions about ourSeleniumTest courses? Feel free to reach out to us, and our team will be happy to provide you with detailed information and guidance on yourSeleniumTest learning journey at the best institute in Chennai, Zerobug Academy.'],
    },
  
  
  ];

  return (
    <div>
      {dropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
    </div>
  );
};

export default SeleniumTestingList;
