import React, { useState } from 'react';
import "./SubNav.css";
import { Link } from 'react-scroll';

const SubNav = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const handleItemClick = (itemName) => {
    setActiveMenuItem(itemName);
  };

  return (
    <div>
      <div className="fltNav">
        <ul id="mainNav">
          <li className={activeMenuItem === 'content' ? 'active' : ''}>
            <Link to='content' smooth={true} duration={700} onClick={() => handleItemClick('content')}>Content</Link>
          </li>
          <li className={activeMenuItem === 'syllabus' ? 'active' : ''}>
            <Link to='syllabus' smooth={true} duration={700} onClick={() => handleItemClick('syllabus')}>Syllabus</Link>
          </li>
          <li className={activeMenuItem === 'Project1' ? 'active' : ''}>
            <Link to='project1' smooth={true} duration={700} onClick={() => handleItemClick('Project1')}>Benifits</Link>
          </li>
          <li className={activeMenuItem === 'Up Coming Batch' ? 'active' : ''}>
            <Link to='up-coming-batch' smooth={true} duration={700} onClick={() => handleItemClick('Up Coming Batch')}>Up Coming Batch</Link>
          </li>
          <li className={activeMenuItem === 'Career' ? 'active' : ''}>
            <Link to='Career' smooth={true} duration={700} onClick={() => handleItemClick('Career')}>Career</Link>
          </li>
          <li className={activeMenuItem === 'FAQ' ? 'active' : ''}>
            <Link to='FAQ' smooth={true} duration={700} onClick={() => handleItemClick('FAQ')}>FAQ's</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SubNav;
