import React, { useState } from 'react';
import Dropdown from '../DropDown';

const  SeleniumSyllabus= () => {
  // Define dropdown titles and options
  const dropdowns = [
    {
      title: 'Introduction to Selenium',
      options: ['What is Automation Testing?', 'Why need for Automation Testing for Applications? ', 
      'Benefit and Features of Automation Testing','Automation Testing Tools in Market','Explaining about Selenium',
      'Selenium Component and Features','Selenium Architecture','Selenium vs QTP','Explaining about IDE, Selenium WebDriver, Selenium','Grid Explaining Supporting Languages in Selenium','Why Choose Python Over Java'],
    },
    
    {
      title: 'Getting Start with Python',
      options: ['How to install Python','Basic Programming on Python','Basic Programming on Python','How to Write and Execute Simple Python','program Variables and Scope','Data Types in Python','Logical Operation',
    'Control Flows in Python','Loop Statement','Exceptional Handling','Function and Procedure in Python','File Operations in Python','Explaining about Python Modules and','Libraries How to use Modules in Script',
    'Standard Libraries and External Libraries','Object Oriented Programming Supports in Python']
    },
    
     {
      title: 'Installation and Configuration',
      options: [ ' Prerequisite for installa',' Selenium Installing Selenium','IDE','Setting PyCharm','Eclipse plugin for PyDev','Chrome Setup','Internet Explorer Setup'],   
      },
     
    {
      title: 'Selenium IDE',
      options: ['Explaining about Selenium IDE','Features Assert Vs Verify','Creating a Test Suite','Working with AJAX Pages','How to Storing Elements']
   
    },
    {
      title: 'Getting Start with Writing Tests',
      options: ['Explaining about unittest library','How to Write Tests','Setup() Method, tearDown()','Methods Assertions','How to run the test','Test suites','How to generate HTML test reports']
      

    },
   
    {
      title: 'Locating Web Elements',
      options: ['Explaining about Elements','Inspecting pages and elements with Chrome and IE','  Locating elements By different options','Locating elements By ID, XPath','Locating elements By CSS Selector','Locating elements By Class Name, Link Test and Name'],
       
    },
    {
      title: 'Selenium API for Elements Interaction / Extracting',
      options: ['Overview of HTML form Elements','Understanding WebDriver Properties and Methods',' WebElements Properties and Methods','Working with forms , checkboxes and radio','button Working with dropdowns and lists','Working with alert and pop-up windows'],
     
    },
    
    {
      title: 'Working with Windows and Frames',
      options: ['iFrames', 'Multiple Windows', 'Windows Size and Location'],
     
    }, 
    {
      title: 'Working with Synchronizing Tests',
      options: [' Explaining about Synchronizing','Testn Using Implicit Wait',' Using Explicit Wait',' Waiting for alerts','custom wait conditions'],
   
    }, 
    {
      title: ' Advanced Selenium WebDriver ',
     
      options: [' Overview of WebDriver',' Keyboard actions','double_click and drag_and_drop',' failures Handling pop-up windows','How to Managing cookies'],
     
    }
  
  ];

  const [displayedDropdowns, setDisplayedDropdowns] = useState(dropdowns.slice(0, 6)); // Display 5 dropdowns initially
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setDisplayedDropdowns(showAll ? dropdowns.slice(0, 6) : dropdowns); // Toggle between showing 5 or all dropdowns
  };
  return (
    <div>
      {displayedDropdowns.map((dropdown, index) => (
        <Dropdown key={index} title={dropdown.title} options={dropdown.options} />
      ))}
      <button className='view-button' onClick={toggleShowAll}>{showAll ? 'Show Less' : 'Show More'}</button>
    </div>
  );
};

export default SeleniumSyllabus;
