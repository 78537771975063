import React, { useState, useEffect, Fragment } from 'react';
import Menu from './menu';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';
import emailjs from 'emailjs-com';
const AwsElevating = () => {
    useEffect(() => {
        document.title = 'AWS Certification Training in 2024';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
      
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                      <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid   banner-background1"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsappButton />

            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h4" style={{ textAlign: "center", marginBottom: "40px" }}>
                                    Elevating Your Career: The Impact of <strong>AWS Certification </strong>Training in 2024</h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>To begin, <strong>AWS</strong> is the most in-demand skill and a leading cloud service provider in the IT industry. It offers a range of certifications for different roles and skill levels. Pursuing AWS Certification enhances your credibility and employability across the world. AWS certification showcases your knowledge, skills and expertise in cloud computing.
                                            It equips you with the latest skills and becomes a valuable asset to organizations.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Certification</h4>
                                        <p>As a proof of your knowledge, AWS Certification is the standardized way to validate your expertise in using <strong>AWS services</strong>. These certifications are widely recognized and make you stand out in the job market.
                                            The list of AWS Certification across different levels are:</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Foundational Level</h4>
                                        <p><strong>AWS Certified </strong>Cloud Practitioner exam.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Associate Level</h4>
                                        <p>AWS Certified SysOps Administrator - Associate exam.</p>
                                        <p>WS Certified SysOps Administrator - Associate exam</p>



                                        <p>AWS Certified Developer - Associate exam</p>
                                        <p>AWS Certified Solutions Architect - Associate exam</p>
                                        <p>AWS Certified <strong>Data Engineer</strong> - Associate</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Professional Level</h4>
                                        <p>AWS Certified Solutions Architect - Professional</p>
                                        <p>AWS Certified DevOps Engineer - Professional</p>
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Speciality Level</h4>
                                        <p>AWS Certified Advanced Networking - Specialty</p>
                                        <p>AWS Certified Database – Specialty</p>
                                        <p>AWS Certified: SAP on AWS - Specialty</p>
                                        <p>AWS Certified Machine Learning - Specialty</p>
                                        <p>AWS Certified Security - Specialty</p>
                                        <p>AWS Certified Data Analytics - Specialty</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Why AWS Certification?</h4>
                                        <p>Learning AWS provides various benefits for aspirants in the cloud computing field. As an Industry-Leading Cloud Platform,
                                            AWS skills provide access to a wide range of services and features. With expertise in cloud computing,
                                            AWS certifications enhance your career level. Many organizations prefer candidates with AWS certifications for
                                            various roles from developers to architects. AWS certifications offer career advancement with new opportunities
                                            and roles. It has global recognition across industries and geographies. AWS certifications require hands-on experience
                                            with the platform in real-world scenarios. AWS certified community provides networking opportunities with other professionals
                                            in the field. Earning AWS certifications contribute to your personal and professional growth. AWS certification provides a
                                            sense of confidence in your abilities in real-world scenarios. It demonstrates specialized expertise in specific areas of
                                            cloud computing. With professional credibility, <strong> AWS certifications</strong> increase the weightage of your profile.
                                            They demonstrate your skills and knowledge to employers that leads to best job roles.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}> <strong>Best AWS Certifications</strong></h4>
                                        <p>The popular AWS certifications available in 2024 that elevates your career path are:</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Certified Cloud Practitioner:</h4>
                                        <p>  Entry point to cloud computing</p>
                                        <p>Fundamental understanding of AWS cloud services</p>
                                        <p>Business and technical aspects of AWS</p>
                                        <p>Professional Credibility and commitment</p>
                                        <p>Global Recognition and Professional Development</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Certified Developer – Associate</h4>
                                        <p>This certification focuses on developing and maintaining applications on the AWS platform.
                                            It showcases the strong understanding of AWS services with key domains - development deployment security and refactoring.</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Benefits:</h4>

                                        <p>Validation of development skills with AWS tools and services</p>
                                        <p>Increased employability for cloud related development roles</p>
                                        <p>Guaranteed Career Advancement</p>
                                        <p>To become competitive in the job market</p>
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Certified Solutions Architect – Associate:</h4>
                                        <p>This certification showcases the understanding of AWS services with the ability to deploy, manage, and operate scalable fault-tolerant systems. It is suitable for individuals in roles such as System Administrators, Operations roles, and more.

                                        </p>
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Certified Solutions Architect – Professional:</h4>
                                        <p>This advanced-level certification focuses on skills and experience in designing distributed systems on the AWS Cloud.
                                            It is suitable for experienced solution architects, solution design engineers who design and deploy complex applications.</p>
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Benefits</h4>
                                        <p>Validation and proficiency of operational skills</p>
                                        <p>Operational excellence to manage and optimize AWS infrastructure</p>
                                        <p>Practical knowledge of managing and operating systems on AWS</p>
                                        <p>To gain problem solving and troubleshooting skills for AWS systems</p>

                                    </div>

                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Conclusion:</h4>
                                        <p>To start your cloud journey or advance your career, AWS Certification is the globally recognized path in the cloud computing field. If you are looking for professional AWS training, Join<strong> Zerobug Academy AWS training in Pallikaranai, Velacher Chennai</strong> . Expert level AWS course in Chennai ensures practical oriented AWS courses using experienced trainers. Equips yourself with the appropriate AWS skills and upgrade your career path.</p>

                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default AwsElevating;