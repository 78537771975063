import React, { useState, useEffect } from 'react';

const Countdown= () => {
    const [hoursCount, setHoursCount] = useState(0);
    const [caseStudiesCount, setCaseStudiesCount] = useState(0);
    const [placementCount, setPlacementCount] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (hoursCount < 60) {
          setHoursCount(hoursCount + 1);
        }
        if (caseStudiesCount < 60) {
          setCaseStudiesCount(caseStudiesCount + 1);
        }
        if (placementCount < 100) {
          setPlacementCount(placementCount + 1);
        }
      }, 50); // Increase counts every second
  
      return () => clearInterval(interval);
    }, [hoursCount, caseStudiesCount, placementCount]); // Dependency array includes state variables
  
    return (
      <div className="mb-5">
        <div className="promo-cnt whiteBG courseHglhts-wrap ">
          <div className="row">
            <div className="col-sm-6">
              <div className="courseHglhts pdg-btm20">
                <div className="circleStyle black-color">{hoursCount}+</div>
                <span> Hours of Practical Training </span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="courseHglhts pdg-btm20">
                <div className="circleStyle black-color">{caseStudiesCount}+</div>
                <span>Case Studies and Projects </span>
              </div>
            </div>
  
            <div className="col-sm-6">
              <div className="courseHglhts pdg-btm20">
                <div className="circleStyle black-color">24/7</div>
                <span>Expert Support</span>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="courseHglhts pdg-btm20">
                <div className="circleStyle black-color">{placementCount}%</div>
                <span> Placement Assurance </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  


export default Countdown;
