import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
/* import { Link } from 'react-router-dom' */

const Menu = () => {
  return (
    <Fragment>
        {/*  <!-- Topbar Start --> */}
    <div className="container-fluid d-none d-lg-block">
        <div className="row align-items-center pyz-4 px-xl-5 menus-topper" style={{background: "linear-gradient(225deg,  darkorange 75%,goldenrod 35%)" }}>
            
            <div className="col-lg-3 ">
                <div className="d-inline-flex align-items-center">
                    <i className="fa  fa-map-marker-alt  mr-3"></i>
                    <div className="text-left" style={{color:"black" ,fontWeight:"bold"}}>
                   {/*  <h6 className="font-weight-semi-bold mb-1">Our Office</h6> */}
                        <small>Velachery, Chennai.</small>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 text-right">
                <div className="d-inline-flex align-items-center">
                    <i className="fa  fa-envelope  mr-3"></i>
                    <div className="text-left" style={{color:"black"}}>
                        {/* <h6 className="font-weight-semi-bold mb-1">Email Us</h6> */}
                        <small>zerobugacademy.info@gmail.com</small>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 text-right">
                <div className="d-inline-flex align-items-center">
                    <i className="fa fa-phone  mr-3"></i>
                    <div className="text-left" style={{color:"black"}}>
                      {/*   <h6 className="font-weight-semi-bold mb-1">Call Us</h6> */}
                        <small>+91-8946004709 ,9750061584</small>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 ">
                <div className="d-inline-flex align-items-center">
                    
                        <div className="text-left">
                            <Link className="btn btn-outline-light1 text-primary btn-square1 mr-2" to="https://twitter.com/Zerobug_Academy"><i className="fab fa-twitter"></i></Link>
                            <Link className="btn btn-outline-light1 text-primary  btn-square1 mr-2" to="https://www.facebook.com/zerobugacademytraininginstitute/"><i className="fab fa-facebook-f"></i></Link>
                            <Link className="btn btn-outline-light1 text-primary  btn-square1 mr-2" to="https://www.linkedin.com/company/zerobug-academy/about/"><i className="fab fa-linkedin-in"></i></Link>
                            <Link className="btn btn-outline-light1 text-primary  btn-square1" to="https://www.instagram.com/zerobug_academy?igsh=Y2dpcXZ2Zm8zMm83"><i className="fab fa-instagram"></i></Link>
                        </div>
                </div>
            </div>
            <div className="d-flex justify-content-start ">
                           
                        </div>
        </div>
    </div>
    {/* <!-- Topbar End --> */}


    </Fragment>
  )
}

export default Menu;