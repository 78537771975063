import React, { useEffect, Fragment, useState } from 'react';
import emailjs from 'emailjs-com';
import Menu from './menu';
import DropList from './DropList';
import { Link } from 'react-router-dom';
/* import { Link } from 'react-scroll'; */
/* import PythonCarrer from './PythonCarrer'; */
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const PhpBlog = () => {
    useEffect(() => {
        document.title = 'Best PHP Training in Chennai';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (

        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse" style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" style={{ color: 'red' }} >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid    page-blog"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>

            <WhatsappButton />
            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left" style={{ textAlign: "center", marginBottom: "40px" }}>
                                    PHP 7.0 Updates</h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>The current version of PHP is PHP 7.0. PHP was created in 1994. PHP 7.0 was released in 2015.
                                            PHP is used to script the server-side. PHP stands for Hypertext Preprocessor.
                                            PHP is used to create <strong>dynamic websites</strong>.
                                            Before you learn PHP you should have basic knowledge of <strong>HTML, CSS, and JavaScript</strong> . The extension of PHP is “.php”. PHP is OSS. PHP scripts are runs on the server. PHP creates static and dynamic pages. PHP has a several platforms like Windows, Linux, Unix, Mac OS X. Apache and IIS servers are used. PHP sites are very effective and dynamic.
                                            PHP supports MySQL database. PHP is an OOPS. PHP supports more frameworks. Mostly used frameworks are Laravel, Symfony, CodeIgniter, Yii2, Zend Framework, Phalcon. PHP is used to create a webpage API’s. The syntax of PHP is. PHP file has support HTML, CSS, JavaScript, and AJAX. The more you learn through <strong> PHP Training in Chennai </strong>,the more knowledge you can explore.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Introduction To HTML</h4>
                                        <p>HTML is used to create static websites. HTML calls HyperText Markup Language.
                                            HTML is used to create a tables, list, links, and symbols.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Introduction To CSS</h4>
                                        <p>CSS stands for Cascading Style Sheets. CSS new version is CSS 3. CSS is used to written a style sheet in an HTML. CSS is a style sheet markup language.
                                            It has made <strong> best PHP Course in velachery,Chennai</strong>  a course of high demand.
                                            There is more value among retailers for PHP.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>JavaScript</h4>
                                        <p>JavaScript is an open source and it is a cross-platform language. JavaScript validates the client and server side.
                                            The advanced version of <strong>JavaScript is JQuery</strong> . JQuery was simplified JavaScript language.</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Introduction To AJAX</h4>
                                        <p>AJAX is used to without reloading the webpage. It is a server-side call. The brief explanation of AJAX is Asynchronous JavaScript and XML. AJAX is used to transfer the plain text or JSON text.
                                            AJAX Send the request to the server. <strong>PHP Training</strong> has wider knowledge imparting system.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>PHP 7.0 Installation</h4>
                                        <p>Web host with PHP and MySQLi</p>
                                        <p>Web servers XAMPP, WAMP, SAMP, and LAMP.</p>

                                    </div>

                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Current Versions Of PHP</h4>
                                        <p>Current versions of PHP is 7.0.x and 7.1.x. PHP 7.0 Updates shown below:</p>
                                        <p>Scalar type</p>
                                        <p>Return type declarations</p>
                                        <p>Anonymous classes</p>
                                        <p>Generator delegation</p>
                                        <p>Generator return expressions</p>
                                        <p>Code Editors for PHP</p>
                                        <p>Sublime Text</p>
                                        <p>Notepad++</p>
                                        <p>Dreamweaver</p>
                                        <p>jEdit</p>
                                        <p>The sublime text editor is the powerful editor to develop PHP. PHP doesn’t have the specific IDE’S.
                                            It has made <strong>PHP Course in Chennai a course </strong>of high demand. There is more value among retailers for PHP.
                                            PHP 7.0 has the many updated. Also, it has the many features. Know more about PHP 7.0 Updates through <strong>PHP Training Institute in Chennai</strong></p>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default PhpBlog;