import React, { useState, useEffect, Fragment } from 'react';
import emailjs from 'emailjs-com';
import TawkTo from '../TawkTo';
import Menu from '../menu';
import BottomMenu from '../BottomMenu';
import DjangoList from './DjangoList';
import './Django.css';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import SubNav from '../SubNav';
import DjangoSyllabus from './DjangoSyllabus';
import WhatsAppButton from '../WhatsappButton';
import PopupForm from '../PopupForm'
const PDF_FILE_URL = "assets/syllabus/zerobug-Python Course-syllabus.pdf";
const DjangoCourse = () => {
    const downloadFileAtURL = (url) => {
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);
    };
    useEffect(() => {
        document.title = 'Best Django course In Velachery, Chennai | Zerobug Academy';
        return () => {
            document.title = 'Best IT Training Institute in Velachery & Pallikaranai | Zerobug Academy in chennai';
        };
    }, []);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });
    const openPopup = () => {
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }

        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name.');
            return;
        }

        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }

        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
        // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <BottomMenu />
            {/* <!-- Navbar End --> */}
            <div className="container-fluid banner-background ">
                <div className="container ">
                    <div className="row align-items-center margin-30">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <h1 className="text-white text-start text-uppercase" >
                                django Training in
                                chennai
                            </h1>

                            <div className="rating-wrap">
                                <span className="ratingVal">49</span>
                                <img src="../assets/img/R.png" alt="image" />
                                <span className="learnerTxt">15K+ Satisfied Learners</span>
                            </div>
                            <p className='text text-white'>Enroll at Zerobug Academy in Chennai for top-notch Python training and Django courses, designed to elevate your skills. Python stands out as one of the most sought-after and future-proof programming languages in 2023. Our comprehensive Python course covers the full spectrum, from fundamental concepts to advanced techniques, catering to beginners and seasoned developers alike. Join us to enhance your proficiency and excel in Python programming. We are the Best Institute in Chennai, Pallikaranai, Velachery, renowned for delivering top-notch Python courses that cater to the evolving needs of the tech industry.</p>


                        </div>

                        <div className="col-lg-6">
                            <div className="image-card python-new-banner  border-0">
                                <img src="./assets/banners/Django-Banner.png" alt="MASTER DJANGO WEB DEVELOPMENT" decoding='async' loading='lazy' />
                                <figcaption className='visually-hidden '><a href="https://www.freepik.com/free-photo/portrait-shocked-young-african-man_7380265.htm#from_view=detail_alsolike">image by freepik</a></figcaption>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  <!-- Header End --> */}
            {/* courseNav */}

            <div className="courseNav  d-sm-block" >
                <div className='container' >
                    <div className=" courseNav-wrap">

                        <SubNav />
                    </div>
                </div>
            </div>
            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">
                    <div className="container " id='content'>
                        <div className="row">

                            <div class="col-lg-12 instruc-wrap1" id="OurInstructors">
                                <h2> Why Django?</h2>
                                <div class=" cnt">

                                    <div class="tick">
                                        <p className='justify-text'>In the realm of web development, Python Django stands as the enchanting wizard, weaving spells of simplicity and power. If you're seeking to embark on a magical journey into the world of web development, <strong>Zerobug Academy</strong> is your portal to mastering <strong>Python Django in Chennai</strong>.
                                            Nestled in the vibrant locales of <strong>Pallikaranai, Velachery, and Chennai,</strong>
                                            our academy offers not just a course but an odyssey into the heart of one of the most sought-after web frameworks.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <WhatsAppButton />
                            <PopupForm />
                            <TawkTo />
                        </div>
                        {/*  course-batch */}

                    </div>
                </div>
            </div>
            {/* <!-- Detail End --> */}
            <div class="container-fluid features-UpcomingBatch py-5" id='up-coming-batch'>
                <div class="features-courseSchedule">
                    <div class="row wrap">
                        <div class="container FAQ">
                            <h3 class='float-left h1 paddinng-left-20'>Upcoming Django Training Chennai </h3>
                        </div>
                        <div className="batch-table-wrap col-sm-12 col-lg-921">
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>jan 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Feb 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Mar 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>
                            <div className='schdule-wrap'>
                                <div className='dateVal'>
                                    <span className=''>Apr 05</span>
                                </div>

                                <div className='batchType'>
                                    <span id="b1day">WEEKDAY BATCH - MON - FRI</span>
                                    <span class="batchLocation">
                                        <i class="fa fa-wifi"></i> Online & <i class="fa fa-map-marker"></i> Classroom
                                    </span>
                                </div>
                                <div className='availbility-cnt'>
                                    <p onClick={openPopup}>courses Fee & Availability <i className='fa fa-arrow-right'></i></p>
                                </div>
                            </div>


                        </div>
                        <div class=''>
                            <div className='talk2Us-Promo new-Promo-Class '>
                                <div className='heading-promo'>
                                    <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                    talk to us
                                </div>
                                <div className='promo-content'>
                                    <h7 className="title"> Online and Classroom Training</h7>
                                </div>
                                <div className='mrg-top5'>
                                    <i className='fa fa-mobile-phone'></i>+91-9750061584
                                </div>
                                <div className='mrg-top5'>
                                    <i className='fa fa-mobile-phone'></i>+91-8946004709
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* reaqust call */}
            <div className="container-fluid courseReqBatch">
                <div className='features-ReqBatch'>
                    <div className=' col-lg-12 col-md-12 reqBatch-wrap'>
                        <img src='../assets/img/date.png' alt='image' />
                        <div className='reqBatch-content'>
                            <p>Can’t find a batch you were looking for?</p>
                            <button className="reqBatch-btn " onClick={openPopup}> REQUEST A BATCH</button>
                            {isPopupVisible && (
                                <div className="popup-form">
                                    <div className="popup-content">
                                        <span className="close" onClick={closePopup}>&times;</span>
                                        <h2>Enquiry Now</h2>
                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn btn-primary" type="submit">
                                                    Send Message
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Detail Start2 --> */}
            <div className="container">
                <div className="container-fluid margin-30" id='syllabus'>
                    <div className="container ">
                        <div className="row">

                            <div className="col-lg-8 FAQ">
                                <h3 class=" text-center heading-left text-left">
                                    Django Course Curriculum</h3>
                                <p className='text-align-syllabus'>"Delve into Django and unleash your full potential as a web developer. Our course covers Django's MVC architecture, ORM, forms, authentication, and deployment strategies. Gain hands-on experience in building secure, scalable, and high-performance web applications using Python and Django."</p>
                                <button onClick={() => { downloadFileAtURL(PDF_FILE_URL) }} className='download-buttom'>Download-Curriculum</button>
                                <div className="mb-5">
                                    <DjangoSyllabus />

                                </div>
                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4 text-white" >
                                        <h5 className="m-0">Requst Free Demo</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  whitesmoke " >

                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4 height"

                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                    {/* Add other options here */}
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {/* <!-- Category List --> */}
                                <div class="mb-5">
                                    <div class="promo-cnt whiteBG courseHglhts-wrap">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color " > 60+</div>
                                                    <span> Hours of Practical Training </span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color "> 60+</div>
                                                    <span>Case Studies and Projects </span>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color ">24/7</div>
                                                    <span>Expert Support</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="courseHglhts pdg-btm20">
                                                    <div class="circleStyle black-color ">100%</div><span> Placement Assurence </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Detail End --> */}
            <div className='container-fluid key-module-bg' id='course-content'>

                <div class=" features-courseProject container">
                    <div class="row" >
                        <div class="col-lg-12">
                            <h2 class=" mar-bot text-white" >
                                Embarking on the Adventure: Key Modules</h2>
                        </div>

                        <div class="key-modules-container ">
                            <div class="key-module">
                                <img src="../assets/banners/Dj.png" alt="Python Basics" class="key-image" decoding='async' loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a target="_blank" href="https://icons8.com/icon/LPmcJ9e0FU7K/django-a-high-level-python-web-framework-that-encourages-rapid-development">Django a high-level Python Web framework that encourages rapid development</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
                                </figcaption >
                                <h3>introduction to Python and Django</h3>
                                <p>Begin your journey with the essence of <strong>Python Django programming.</strong> Immerse yourself in the fundamental magic of the Django framework.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/banners/database.png" alt="Web Development with Flask" class="key-image" decoding='async' loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a target="_blank" href="https://icons8.com/icon/USueM3lEuSIB/operational-databases">Operational Databases</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
                                </figcaption >
                                <h3>Django Models and Databases</h3>
                                <p>Venture into the mystical realms of database building with Django models. Harness the power of Object Relational Mapping (ORM) concepts.</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/banners/template.png" alt="Django Templates" class="key-image" decoding='async' loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a target="_blank" href="https://icons8.com/icon/Q5yIwktzJa8j/templates">Templates</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
                                </figcaption >

                                <h3>Django Middleware and User Authentication</h3>
                                <p>Imbue your applications with the magic of user authentication and authorization</p>
                            </div>
                            <div class="key-module">
                                <img src="../assets/banners/url.png" alt="URL Patterns" class="key-image" decoding='async' loading='lazy' />
                                <figcaption className='visually-hidden '>
                                    <a target="_blank" href="https://icons8.com/icon/92033/website">Website</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
                                </figcaption >


                                <h3>Deployment and Scaling</h3>
                                <p>Ascend to new heights by learning the strategies of deploying Django applications</p>
                            </div>
                            <div class="key-module">

                                <h3>Forms and Form Handling</h3>
                                <p>Shape your interactive dreams with the creation of enchanting forms.</p>
                            </div>
                            <div class="key-module">
                                <h3>Django Views and URL Patterns</h3>
                                <p>Navigate the winding paths of URL patterns and views.</p>
                            </div>
                            <div class="key-module">
                                <h3>Django Templates</h3>
                                <p>Craft dynamic and visually stunning web pages with Django templates.</p>

                            </div>
                            <div class="key-module">

                                <h3>Testing and Debugging in Django</h3>
                                <p>Learn the art of debugging and optimizing Django code for flawless execution</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className='container-fluid training-modes-wrap' >
                <div className=' container-fluid courseReqBatch training-modes-wrap'>
                    <div className="col-lg-12 col-md-12  mrg-top20">

                        <div class="row ">
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob7.jpg" alt="python training" width="100%" height="100%" decoding='async' loading='lazy'></img>
                                    </div>
                                    <h3>Classroom Training</h3>
                                    <div className="training-mode-cnt">
                                        <span >Instructor Led Classroom Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 5.jpg" alt="online course" width="100%" height="100%" decoding='async' loading='lazy'></img>
                                    </div>
                                    <h3 >Online Training</h3>
                                    <div className="training-mode-cnt">
                                        <span>Instructor Led Online Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/blob 4.jpg" alt="best trining" width="100%" height="100%" decoding='async' loading='lazy'></img>
                                    </div>
                                    <h3> Corporate Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>Instructor Led Corporate Training</span>
                                    </div>
                                </div>

                            </div>
                            <div className="training-mode-block classroom col-sm-3">
                                <div className="training-mode">
                                    <div className="training-mode-ic">
                                        <img src="../assets/img/bobl36.jpg" alt="image" width="100%" height="100%" decoding='async' loading='lazy'></img>
                                    </div>
                                    <h3> One to One Training</h3>
                                    <div className="training-mode-cnt">
                                        <span className='font-14'>best training based on requirement</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* 
Django overview */}
            <div className='container-fluid  home-need-bg'>
                <div className="container ">

                    <div className=' CourseFeatures home-need-bg'>
                        <div className='features-Coursefeatures'>
                            <div className="row text-center" >
                                <div className="col-lg-12 mar-bot-top " >
                                    <h2>Why Choose Zerobug Academy for Django Courses?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj'>
                                        <img decoding="async" src="../assets/img/blob.jpg" alt="Django Real Time projects" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4> Masters of the Craft</h4>
                                            <p className='text-center'> Our trainers are not just instructors; they are seasoned wizards, each possessing a wealth of experience in the mystical arts of Python Django development. Their mastery over the craft ensures that you are guided by the very best in the magical realms of web development.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-green'>
                                        <img decoding="async" src="../assets/banners/latesttool.png" style={{ color: 'orangered' }} alt="Python Real Time projects" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com/icon/earth_3408509#fromView=search&page=1&position=22&uuid=065e20ff-1b8b-4a2a-8df4-1c73c969c78d" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4>RealWorld Alchemy</h4>
                                            <p className='text-center'>Zerobug Academy believes in the power of practical sorcery. Here, you won't just learn theory; you'll wield your magic on realworld projects. These projects are the crucibles where theory meets reality, allowing you to transform your newfound knowledge into potent spells that shape the world of web development</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className='keyFeatures-wrap proj-voilet'>
                                        <img src="../assets/img/blob1.jpg" alt="Python Real Time projects" width="20%" height="20%" loading='lazy'></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com" >Icon by Freepik</a>
                                        </figcaption >
                                        <div className='cnt'>
                                            <h4>Enchanting Learning Spaces</h4>
                                            <p className=" text-center">Step into our academy, strategically situated in the magical location of Chennai . Our learning environments are more than classrooms; they are sanctuaries adorned with stateoftheart facilities. Immerse yourself in an atmosphere where learning becomes an enchanting experience, surrounded by the latest tools and technologies.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-blue'>
                                        <img decoding="async" src="../assets/banners/community.png" loading='lazy' alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com/search">Icon by Mayor Icons</a>
                                        </figcaption>
                                        <div className='cnt'>
                                            <h4 >Community of Conjurers</h4>
                                            <p className='text-center'>Join a community of likeminded conjurers and enthusiasts who share your passion forPython Django. Our academy fosters a culture of collaboration and knowledgesharing, ensuring that the magic doesn't end in the classroom. Connect with fellow learners, exchange spells, and grow together in a community that values the collective pursuit of excellence.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top">
                                    <div className='keyFeatures-wrap proj-yellow'>
                                        <img decoding="async" src="../assets/img/blob2.jpg" loading='lazy' alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com/search">Icon by Mayor Icons</a>
                                        </figcaption>
                                        <div className='cnt'>
                                            <h4>Industry Spells and Incantations</h4>
                                            <p className='text-center'>Zerobug Academy is attuned to the latest spells and incantations echoing in the industry. Our curriculum is not frozen in time; it evolves with the everchanging landscape of web development. Learn the most recent and relevant magic that the industry craves, ensuring that you are equipped with the spells in demand.</p>
                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 padding-top" >
                                    <div className='keyFeatures-wrap proj-red'>
                                        <img decoding="async" src="../assets/img/blob3.jpg" loading='lazy' alt="Python Real Time projects" width="20%" height="20%"></img>
                                        <figcaption className='visually-hidden '>
                                            <a href="https://www.freepik.com/search">Icon by Mayor Icons</a>
                                        </figcaption>
                                        <div className='cnt'>
                                            <h4>Magical Support System</h4>
                                            <p className="text-center ">Beyond the classrooms and projects, Zerobug Academy provides a magical support system. Whether you need assistance with a challenging spell or guidance on your career journey, our support team is here to ensure that your magical adventure is smooth and successful.</p>

                                            <button onClick={openPopup} className='moreInfo-button'>More Info</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  modes f training */}
            <div className="container py-5">
                <div className="container-fluid " id='Career'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 FAQ">
                                <h2 className="text-center heading-left text-left">
                                    Career Opportunities</h2>
                            </div>
                            <div className="col-lg-8">
                                <div className="mb-5">
                                    <p className='text-left' >"As you master the art of <strong>Python Django at Zerobug Academy</strong>, you open doors to a plethora of magical career opportunities".</p>
                                </div>
                                <div class="col-lg-12 instruc-wrap" id="OurInstructors">

                                    <div class="proj-portal cnt">

                                        <ul class="tick line-height" >
                                            <li><span>Python-Django-Developer:</span> Craft spells in the form of dynamic web applications.</li>
                                            <li><span>Full-Stack-Developer:</span> Dive into data analytics, machine learning, and artificial intelligence.</li>
                                            <li><span>Web Development:</span> Offer your expert advice on Python Django development, guiding businesses through the magical realm of web development</li>

                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">

                                <div class="d-flex flex-column text-center rounded mb-5  px-4">
                                    <div className=''>
                                        <div className='talk2Us-Promo'>
                                            <div className='heading-promo'>
                                                <i className='fa fa-phone' style={{ marginRight: '20px' }}></i>
                                                talk to us
                                            </div>
                                            <div className='promo-content'>
                                                <h7 className="title"> Online and Classroom Training</h7>
                                            </div>
                                            <div className='mrg-top5 black-color ' >
                                                <i className='fa fa-mobile-phone'></i>+91-9750061584
                                            </div>
                                            <div className='mrg-top5 black-color ' >
                                                <i className='fa fa-mobile-phone'></i>+91-8946004709
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-5" style={{ background: "linear-gradient(225deg,darkorange 30%,  goldenrod 75%)" }}>
                <div className="container "  >
                    <div className="row d-flex flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7 col-12 ">
                            <h1 className="h3 light-300 text-white">Discover what our satisfied Student have to say about our Teaching</h1>
                        </div>
                        <div className="col-lg-5 col-12 ">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Google.svg" alt="Google" className="img-fluid" />
                                    <div><Link to='https://www.google.com/search?q=zerobug+academy&sca_esv=6b1c5e95a3d0b06b&sca_upv=1&source=hp&ei=s4ohZrSjMPiQseMPrKy_yAc&gs_ssp=eJzj4tVP1zc0TDNKLyozM88zYLRSNagwTjQ1Mk2xTE5OSzNISzJMsTKoSLQwTDJKSTRJMzEzSUlJNvHir0otyk8qTVdITE5MSc2tBADrPRYM&oq=zerobug&gs_lp=EhFtb2JpbGUtZ3dzLXdpei1ocCIHemVyb2J1ZyoCCAQyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyCxAuGIAEGMcBGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAEMgUQABiABEi4TFDhCVjKNHAAeACQAQCYAfgOoAHnPqoBBTctMi4zuAEByAEA-AEBmAIFoAKaQKgCAMICCBAAGIAEGLEDwgIREC4YgAQYsQMY0QMYgwEYxwHCAg4QLhiABBixAxjRAxjHAcICCBAuGIAEGLEDwgIOEC4YgAQYxwEYjgUYrwGYAwCSBwM3LTWgB_Ez&sclient=mobile-gws-wiz-hp#ip=1&wptab=si:AKbGX_oXOTjHK3vNPxrwAU4tsC2W_rsdJDrrSHpqUAOdbOh1q9uNYK9vtbmmnS7KXd3yboYf2bOhwMeMmdWxIVwYVpfw0ijnlRKr1nOHCwOPFAW8qUORl1WdTqK3rX_iT9y05M3y7DbX' target='_blank' className='text-white'>Google Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Youtube.svg" style={{ width: '50px' }} alt="YouTube" className="img-fluid" />
                                    <div><Link to='#' className='text-white'>Youtube Reviews</Link></div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-6 mb-3 text-center">
                                    <img src="assets/img/Facebook.svg" alt="Facebook" className="img-fluid" />
                                    <div><Link to='https://www.facebook.com/zerobugacademytraininginstitute/' target='_blank' className='text-white'>Facebook Reviews</Link></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* Chennai and Elevate Your Career */}
                <div className='container-fluid'  >
                    <div class="col-lg-12 instruc-wrap" id="OurInstructors">
                        <h2 className='text-center heading-left text-left' > Your Adventure Begins Now!</h2>
                        <div class="proj-portal cnt">
                            <p className='text-left'>
                                Embark on this mystical adventure of mastering<strong>Python Django at Zerobug Academy</strong> . Unleash the magic within you and become a sorcerer of web development. The world of enchanting web applications awaits – join us and let the magic begin!
                            </p>
                            <ul class="tick">

                                <li>Enroll Now and Embrace the Future of Coding with <strong>Zerobug Academy in Chennai!</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid margin-30" id='FAQ'>

                    <div className="row">
                        <div className="col-lg-7 FAQ">
                            <h2 className="mar-bot-top text-center heading-left text-left">
                                Frequently Asked Questions
                            </h2>
                            <div className="mb-5">
                                <DjangoList />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="alumni-work mar-bot-top">
                                <h3>Our Alumni Work in Top MNC’S</h3>
                                <p>"Zerobug Academy has achieved remarkable success by placing thousands of students in leading roles across top multinational organizations."</p>
                                <div className="row">
                                    <div className="alumni-work-container">
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/DHL.png" decoding='async' loading='lazy' alt="Our Alumni Work in Top MNC’S" />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/tvs-credit.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/zoho-1-logo-png-transparent.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/freshworks.webp" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/larsen-and-toubro.jpg" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/HCLTECH.NS.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/centelon.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive" src="./assets/banners/INFY_BIG.png" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                        <div className='alumini-box'>
                                            <img className="img-responsive h-width" src="./assets/banners/Hexaware.jpg" alt="Our Alumni Work in Top MNC’S" decoding='async' loading='lazy' />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                <p>Join the success community to build your future </p><button onClick={openPopup} className='entrol-button'> Enroll Now</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default DjangoCourse;