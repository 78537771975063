import React, { useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Menu from './menu';
import TawkTo from './TawkTo';
import Footer from './Footer';
import PopupForm from './PopupForm'
import WhatsAppButton from './WhatsappButton';

const About = () => {
    useEffect(() => {
        document.title = 'About US | Zerobug Academy';
        return () => {
          document.title = 'Best IT Training Institute in Velachery & Pallikaranai| zerobug Academy'; 
        };
      }, []);
    return (
        <Fragment>
            <Menu />

            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse" style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>

                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                        <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" style={{ color: "darkorange" }}>About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                        <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid page-about " >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center " style={{ minHeight: "350px" }}>
                        <h3 className="display-4 text-white text-uppercase">About Us</h3>

                    </div>
                </div>
            </div>

            <div class="col-lg-12 instruc-wrap1" id="OurInstructors">
                {/*   <h3> Python Training in Chennai with Placements :</h3> */}

                <h1 className="h1">Best Software Training Institute in Velachery</h1>
                < hr className='bottom-border' style={{ marginBottom: "10px" }} />
                <div className=" cnt" >

                    <ul className="tick" >
                        <p style={{ textAlign: 'justify' }}>Zerobug Academy offers best <strong> Software Training in Velachery, Chennai.</strong>
                            Our real time <strong>training in Chennai</strong>  will help students to gain practical knowledge on software technologies
                            moreover our training sessions are more interactive and well planned. The training sessions are handled
                            by experts from IT industry who teach concepts with corporate exposure. The course path are designed
                            according to current trends which will definitely help you to clear interviews and certification.</p>
                        <p style={{ textAlign: 'justify' }}>We do offer corporate training in Chennai on latest technologies. We motivate students to come up
                            with their doubt without any hesitations after completion of each sessions. Zerobug academy is the
                            only <strong>training institute in Chennai</strong> who offer free demo classes without any registration. After
                            completion of course our team of HR professional will definitely help you for
                            placement across software companies in India so don’t miss the opportunity and get placed soon</p>
                    </ul>
                </div>
            </div>

            <WhatsAppButton />
            <TawkTo />
            <PopupForm />
            {/*next gategory  */}

            {/* <!-- Our Classroom/Online Training--> */}
            <div className="container-fluid ">
                <div className="container pt-5 pb-3">
                    <div className="text-center mb-5">
                        <h2 className="h11" >Our Classroom/Online Training</h2>
                        < hr className='bottom-border' />

                        <p style={{ padding: "10px" }}>"ZeroBug offers the best Trending <strong>Training using classroom and online Training</strong>.
                            With hands-on training, Our Top Mentors ensure theory + Practical training in online, offline mode.
                            The main aim of our Software Training Sessions is to deepen the knowledge by acquiring the complete skill set."</p>
                    </div>
                    <div className="row pb-3">
                        <div className="col-lg-4 mb-4">
                            <div className="blog-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid online-images" src="assets/img/zero.jpeg" alt="zerobugAcademy" />

                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="blog-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid online-images" src="assets/img/online (3).webp" alt="zerobugAcademy" />

                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="blog-item position-relative overflow-hidden rounded mb-2">
                                <img className="img-fluid online-images" src="assets/img/class.png" alt="zerobugAcademy" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  <!-- Blog End --> */}

            <Footer />
        </Fragment>
    )
}

export default About;