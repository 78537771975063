import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import PythonCourse from './pages/python';
import DjangoCourse from './pages/dJango-training/DjangoCourse';
import AngularCourse from './pages/angular-training/AngularCourse';
import ReactCourse from './pages/react-training/ReactCourse';
import About from './pages/about';
import Blog from './pages/blog';
import SeleniumTestingCourse from './pages/testing-training/SeleniumTestingCourse';
import AwsElevating from './pages/AwsEluation';
import AwsUpgrade from './pages/AwsUpgrade';
import JavaBlog from './pages/JavaBlog';
import PhpBlog from './pages/PhpBlog';
import GoogleAd from './pages/GoogleAd';
import WebDesignBlog from './pages/WebDesignBlog';
import FrameWorkBlog from './pages/FrameWorkBlog';
import WebApplicationDevelopment from './pages/web-application/WebApplicationDevelopment';
import CloudBlog from './pages/CloudBlog';
import HighestPaying from './pages/HighestPaying';
import Seo from './pages/web-application/Seo';
import Contact from './pages/ContactUs'; 


function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/best-seo-marketing-training-in-chennai" element={<Seo />} />
          <Route path="/web-development-training-in-chennai" element={<WebApplicationDevelopment />} />
          <Route path="/best-python-training-in-chennai" element={<PythonCourse />} />
          <Route path="/best-angular-training-in-chennai" element={<AngularCourse />} />
          <Route path="/best-selenium-testing-training-in-chennai" element={<SeleniumTestingCourse />} />
          <Route path="/best-django-training-in-chennai" element={<DjangoCourse />} /> 
          
          <Route path="/best-react-training-in-chennai" element={<ReactCourse />} />
          <Route path="/elevate-aws-certification-training-in-chennai" element={<AwsElevating />} />
          <Route path="/aws-certification-training-in-chennai" element={<AwsUpgrade />} />
          <Route path="/java-certification-training-impact" element={<JavaBlog />} />
          <Route path="/php-certification-training-impact" element={<PhpBlog />} />
          <Route path="/google-adwords-certification-training-impact" element={<GoogleAd />} />
          <Route path="/webdesign-certification-training-impact" element={<WebDesignBlog />} />
          <Route path="/cloud-computing-certification-training-impact" element={<CloudBlog />} />
          <Route path="/top-paying-jobs-2024" element={<HighestPaying />} />
          <Route path="/latest-frameworks-certification-training-impact" element={<FrameWorkBlog />} />
         
        </Routes>
      </Router>
    </div>
  );
}

export default App;
