import React, { useEffect, Fragment, useState } from 'react';
import emailjs from 'emailjs-com';
import Menu from './menu';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const  JavaBlog = () => {
    useEffect(() => {
        document.title = 'Best Java Training in Chennai';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }
    
        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }
    
        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }
    
        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
    // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                  setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };
    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse"  style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                            {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                    <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                           </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                     </ul>
                                </li>
                                <li><Link to="/blog" style={{color:'red'}} >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid  page-blog"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>
          <WhatsappButton/>

            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h4" style={{ textAlign: "center", marginBottom: "40px" }}>
                                Java 9 Features</h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p> <strong>JAVA 9 (otherwise known as jdk 1.9)</strong> is a noteworthy arrival of JAVA programming dialect improvement.
                                             Its underlying adaptation was discharged on 21 Sep 2017. The principle objectives of Java 9.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Java 9 REPL (JShell)</h4>
                                        <p>Prophet Corp has presented another instrument called "jshell". It remains for Java Shell and furthermore known as REPL (Read Evaluate Print Loop).
                                             It is utilized to execute and test any Java Constructs like class, interface, enum, question, explanations and so forth effectively Java training in Chennai.On the off chance that you need to find out about REPL instrument, Please experience Java 9 REPL Basics (Part-1) and Java 9 REPL Features (Part-2).</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Factory Methods for Immutable List, Set, Map and Map.Entry</h4>
                                        <p>Prophet Corp has acquainted some advantageous industrial facility strategies with make Immutable List, Set, Map and Map.Entry objects.
                                             These utility strategies are utilized to make purge or non-discharge Collection objects.In Java SE 8 and prior variants,
                                              We can utilize Collections class utility strategies like unmodifiableXXX to make Immutable Collection objects. For example,
                                             on the off chance that we need to make an Immutable List, at that point we can utilize Collections. unmodifiableList method.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Private methods in Interfaces</h4>
                                        <p>In <strong>Java 8 Training in Pallikaranai,Velachery, Chennai</strong>, we can give technique execution in Interfaces utilizing Default and Static strategies.
                                             Anyway we can't make private techniques in Interfaces.To maintain a strategic distance from excess code and more re-ease of use, 
                                             Oracle Corp will present private strategies in Java SE 9 Interfaces. From Java SE 9 on-wards,
                                             we can compose private and private static strategies too in an interface utilizing 'private' catchphrase.</p>
    
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Java 9 Module System</h4>
                                        <p>Before Java SE 9 adaptations, we are utilizing Monolithic Jars to create Java-Based applications. This engineering has parcel of restrictions and disadvantages. To maintain a strategic distance from every one of these deficiencies, Java SE 9 is accompanying Module System.JDK 9 is accompanying 92 modules (may change in definite discharge). 
                                            We can utilize JDK Modules and furthermore we can make our own particular modules as demonstrated as follows:</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Process API Improvements</h4>
                                        <p>Java SE 9 is accompanying a few enhancements in Process API. They have included couple new classes and strategies to facilitate the controlling and overseeing of OS forms <strong> Best Java Training Institute in  Pallikaranai, Velacher Chennai</strong></p>
                                        <p>Two new interfcase in Process API</p>
                                        <p>java.lang.ProcessHandle</p>
                                        <p>java.lang.ProcessHandle.Info</p>
                                       

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Try With Resources Improvement</h4>
                                        <p>We know, Java SE 7 has presented another exemption taking care of build: Try-With-Resources to oversee assets consequently. 
                                            The fundamental objective of this new explanation is "Programmed Better Resource Management".</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>CompletableFuture API Improvements</h4>
                                        <p>In Java SE 9, Oracle Corp will Advanced <strong>Java Training in Pallikaranai, Velachery Chennai</strong> enhance CompletableFuture API to take care of a few issues brought up in Java SE 8. 
                                            They are going add to help some deferrals and timeouts, some utility techniques and better sub-classing.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Reactive Streams</h4>
                                        <p> Presently a-days, Reactive Programming has turned out to be extremely prevalent in creating applications to get some delightful advantages.
                                             Scala, Play, Akka and so on. Systems has officially coordinated Reactive Streams and getting numerous advantages. 
                                            Prophet Corps is additionally presenting new Reactive Streams API in Java SE 9.</p>
                                        <p>Java SE 9 has introduced the following API to develop Reactive Streams in Java-based applications.</p>
                                        <p>java.util.concurrent.Flow</p>
                                        <p>java.util.concurrent.Flow.Publisher</p>
                                        <p>java.util.concurrent.Flow.Subscriber</p>
                                        <p>java.util.concurrent.Flow.Processor</p>

     </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left h111' style={{ textAlign: 'left' }}>Optional Class Improvements</h4>
                                        <p>Java Framework <strong>Best Training in Java Training in Pallikaranai, Velacher Chennai</strong>  in Java SE 9, Oracle Corp has added some valuable new techniques to java.util.Optional class.
                                             Here I will talk about around one of those strategies with some straightforward case: stream technique On the off chance that an esteem show in the given Optional question, this stream() technique restores a consecutive Stream with that esteem. 
                                            Else, it restores an Empty Stream.</p>

                                    </div>
                                  
                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                    <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>                                                  
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>
                                          
                                        </form>
                                    </div>

                                </div>
</div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default  JavaBlog;