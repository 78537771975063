import React, { useState, useEffect, Fragment } from 'react';
import Menu from './menu';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Footer from './Footer';
import WhatsappButton from './WhatsappButton';

const AwsUpgrade = () => {
    useEffect(() => {
        document.title = 'AWS Certification Training in 2024';
        return () => {
          document.title = 'Affordable Training Institute in Velachery & Pallikaranai'; 
        };
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        course: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Form validation
        if (!formData.name || !formData.phone || !formData.email || !formData.course) {
            alert('Please fill in all fields.');
            return;
        }

        // Name validation
        const namePattern = /^[A-Za-z]+$/;
        if (!namePattern.test(formData.name)) {
            alert('Please enter a valid name .');
            return;
        }

        // Phone number validation
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(formData.phone)) {
            alert(' invalid phone number.');
            return;
        }

        // Email validation (optional)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            alert('invalid email address.');
            return;
        }
        // Submit the form if validation passes
        emailjs.sendForm('service_4hdy2tu', 'template_rklqjjk', e.target, 'yjeGkHpPkrtpw9yU9')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    course: ''
                });
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                alert('Failed to send message.');
            });
    };

    return (
        <Fragment>
            <Menu />
            {/* <!-- Navbar Start --> */}
            <div className="container-fluid">
                <div className="row border-top  px-xl-5" style={{ padding: "15px 0" }} >
                    <div className="col-lg-3 d-none d-lg-block">
                        <Link to="/" className="d-flex align-items-center  w-100 text-decoration-none" data-toggle="collapse" style={{ height: "67px", padding: "0 0px" }}>
                            <img src='../assets/img/png log.png' alt='zerobug Academy' style={{ width: "250px", height: "65px" }} />
                        </Link>

                    </div>
                    <div className="col-lg-9 navigation" >

                        <nav>
                            <label for="drop" className="toggle toggle1">
                                <div className="menu-logo">
                                    <Link to="/"> <img src='../assets/img/png log.png' alt='zerobug Academy' className='menu-img' /></Link>

                                </div>
                                <span class="bold-symbol">&#8801;</span>
                            </label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu">
                                <li><Link to="/" >Home</Link></li>

                                <li>
                                    {/* <!-- First Tier Drop Down --> */}
                                    <label for="drop-2" className="toggle">Course <span className='down-arrow'>❯</span></label>
                                    < Link to="#">Course <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-2" />
                                    <ul className='border-color'>
                                        <li><Link to="/best-python-training-in-chennai">Python Training</Link></li>
                                        <li>  <Link to="/best-django-training-in-chennai">Django Training</Link></li>
                                        <li>  <Link to='/best-react-training-in-chennai'>ReactjS Training</Link></li>
                                        <li>  <Link to="/best-angular-training-in-chennai">AngularJs Training</Link></li>
                                        <li><Link to="/best-selenium-testing-training-in-chennai" >Selenium Training </Link></li>

                                    </ul>
                                </li>
                                <li><Link to="/about" >About</Link></li>

                                <li>

                                    <label for="drop-1" class="toggle">Development <span className='down-arrow'>❯</span></label>
                                    <Link to="#">Development <span className='down-arrow'>❯</span></Link>
                                    <input type="checkbox" id="drop-1" />
                                    <ul className='border-color'>
                                    <li><Link to="/web-development-training-in-chennai" >Web Application  </Link></li>
                                        <li><Link to="/best-seo-marketing-training-in-chennai" >SE0</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/blog" >Blog</Link></li>
                                <li><Link to="/contact" >Contact</Link></li>

                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/* <!-- Navbar End --> */}
            <div className="container-fluid   banner-background1"  >
                <div className="container ">
                    <div className="d-flex flex-column justify-content-center" style={{ minHeight: "270px" }}>
                        <h3 className="display-4 text-white text-uppercase ">Blog</h3>
                        <div className="d-inline-flex text-white">
                            <p className="m-0 text-uppercase"><a className="text-white" >Home</a></p>
                            <i className="fa fa-angle-double-right pt-1 px-3"></i>
                            <p className="m-0 text-uppercase">Blog</p>
                        </div>
                    </div>
                </div>
            </div>

            <WhatsappButton />
            <div className='container'>
                {/* <!-- Detail Start --> */}
                <div className="container-fluid  body-align-text">

                </div>
                {/* <!-- Detail End --> */}
                {/*  <!-- Detail Start2 --> */}
                <div className="container-fluid " style={{ marginTop: '30px' }}>
                    <div className="container ">
                        <div className="row">
                            <div class="col-lg-9">
                                <h1 class="float-left h4" style={{ textAlign: "center", marginBottom: "40px" }}>
                                    Career Upgrade 2024: Elevate Your Path with AWS Solutions Architect</h1>
                            </div>
                            <div className="col-lg-8">

                                <div className="mb-5 ">


                                    <div class="tick hight-pay" >
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Introduction</h4>
                                        <p>To start, <strong>Amazon Web Services is the leading cloud computing</strong> platform that provides crucial job roles.
                                            Cloud technology evolves rapidly with the latest AWS services, features.
                                            <strong>AWS Solutions</strong>  Architect is the rewarding job role which leads to an elevated career path.
                                            AWS is the fastest growing public cloud that leads to high paying jobs.
                                            The combination of <strong>AWS Certified Solutions</strong> , practical projects and best practices will help you succeed in
                                            this dynamic evolving field. It provides a solid foundation for building your successful career in the cloud market. AWS is the most valuable, future-proof skill along with global reach.
                                            Let’s explore the career path of AWS Solution Architect in 2024.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Solutions Architect</h4>
                                        <p>If you aim at landing on high paying cloud jobs, AWS helps to position you in various high level job opportunities.
                                            It allows choosing the desired career path like development, cloud architecture, security and more. AWS Solutions Architect is the best choice with high demand. This job role opens up diverse career opportunities across industries within the cloud computing space. AWS Solutions Architect requires a versatile skill set with knowledge of cloud services, architecture, security and more.
                                            This valuable skill set increases the earning potential and commands competitive salary.</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Top Reason to become AWS Solutions Architect</h4>
                                        <p>High demand for cloud professionals</p>
                                        <p>Competitive and attractive salary</p>
                                        <p>Global Recognition across industries</p>
                                        <p>Networking opportunities</p>
                                        <p>Work life balance</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Learning Path of AWS Solutions Architect</h4>
                                        <p>The AWS Solutions Architect job role is in high demand as they can optimize the cloud architecture.
                                            The global exposure of AWS broadens your career opportunities and growth. Organizations are actively
                                            seeking for these professionals who can implement cloud solutions. It is a valuable role in the job
                                            market as they can
                                            shape the technology strategy of an organization. The learning path of AWS Solutions Architect is as follows</p>
                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Solutions Architect - Associate Level</h4>
                                        <p>Achieving <strong>AWS Certified Solutions</strong> Architect - Associate certification (SAA-C03) leads to AWS Certified Solutions Architect - Associate level jobs. These job roles involve designing and implementing scalable, secure, and cost-efficient solutions on the AWS platform.
                                            The AWS Certified Solutions Architect - Associate level certification is suitable for</p>
                                        <p>  Freshers</p>

                                        <p>Entry level Professionals</p>
                                        <p>
                                            Developers</p>
                                        <p>System Administrators</p>
                                        <p>Network Engineer</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>The skills measured in <strong>AWS Certified Solutions</strong>  Architect - Associate certification are</h4>
                                        <p>Knowledge in AWS compute, networking, storage, and database AWS services</p>
                                        <p>Skills in deploying, managing, and operating workloads on AWS</p>
                                        <p>Knowledge in using the AWS networking, AWS Management Console and the AWS Command Line Interface</p>
                                        <p>Ability to identify the appropriate AWS services for the given technical requirement</p>

                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Certified Solutions Architect - Professional certification (SAP-C02)</h4>
                                        <p>The AWS Certified Solutions Architect - Professional exam (SAP-C02) validates the advanced technical skills and knowledge in
                                            designing distributed systems on the AWS platform. It is suitable for experienced cloud professionals that offer guaranteed career advancement. This certification demonstrates a
                                            high level of proficiency in cloud architecture. The AWS Certified Solutions Architect - Professional exam is suitable for</p>


                                    </div>
                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>AWS Certified Solutions Architect certification Training</h4>
                                        <p>Enroll in industrial standard AWS Certified Solutions Architect certification Training at<strong>Zerobug Academy in Pallikaranai, Velachery</strong>.
                                            The Professional trainers ensure gaining the essential skills using real-world projects and scenarios.
                                            Practice Exams assess your readiness for the certification with job assistance.
                                            Work on real-world scenarios that involve designing and implementing solutions using AWS services.
                                            The highlights of <strong>AWS Certified Solutions Architect Training are</strong></p>


                                    </div>

                                    <div class="tick hight-pay">
                                        <h4 className='heading-left  h111' style={{ textAlign: 'left' }}>Conclusion:</h4>
                                        <p>If you are interested in AWS Career, AWS Solutions Architect is the best job role for a successful cloud career.
                                            Learn AWS Solutions Architect Training at <strong>Zerobug Academy in  Pallikaranai, Velachery Chennai</strong> to master the associate and professional level skills. Earn the <strong>AWS certifications with practical training</strong> ,
                                            projects and best practices to elevate your career path.</p>

                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4 mt-5 mt-lg-0">
                                {/*  <!-- Author Bio --> */}
                                <div className="d-flex flex-column text-center   rounded mb-5 px-4">

                                    <div className="card-header demo-form text-center p-4" style={{ color: "white" }}>
                                        <h5 className="m-0">Enquiry Now</h5>
                                    </div>
                                    <div className="card-body rounded-bottom  " style={{ backgroundColor: "whitesmoke" }}>

                                        <form className="form" name="sentMessage" id="contactForm" noValidate onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Phone No"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control border-0 p-4"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <select
                                                    className="custom-select border-0 px-4"
                                                    style={{ height: "47px" }}
                                                    name="course"
                                                    value={formData.course}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select a course</option>
                                                    <option value="python">Python</option>
                                                    <option value="django">Django </option>
                                                    <option value="reactjs">ReactJS</option>
                                                    <option value="angular">AngularJS</option>
                                                    <option value="selenium">Selenium </option>
                                                </select>
                                            </div>
                                            <div>
                                                <button className="btn  btn-block border-0 py-3 demo-form" style={{ Color: "white" }} type="submit">Submit</button>
                                            </div>

                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </Fragment>
    )
}

export default AwsUpgrade;